import { TClient, TSeller } from "@/http";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { IoIosCheckboxOutline } from "react-icons/io";
import { PiUserList } from "react-icons/pi";
import { RiPhoneLine } from "react-icons/ri";
import { GrNote } from "react-icons/gr";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { validateTimezoneDate } from "@/utils/date";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { addressToString } from "@/utils/address";
import { useState } from "react";
import { ClientModalEvent } from "./ClientModalEvent";

type TClientDetail = {
  client: TSeller | TClient;
  onChange: (value: boolean) => void;
};

export const ClientDetail = ({ client, onChange }: TClientDetail) => {
  const [t] = useTranslation("clients-page");
  const [tGlobal] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const onClose = () => {
    setIsOpen(false);
  };

  const openModal = (type: string) => {
    setModalType(type);
    setIsOpen(true);
  };

  return (
    <>
      <div className="contentInline pwmd mt1">
        <div className="flexGap gapSm">
          <div>
            <PiUserList size={24} />
          </div>
          <div>
            {client?.legalName && (
              <div className="bold upperCase">{client?.legalName}</div>
            )}
            {(client?.firstName || client?.lastName) && (
              <div className="bold">
                {client?.firstName + " " + client?.lastName}
              </div>
            )}
          </div>
        </div>
        <span
          className={clsx("flexGap textCenter statusBox", {
            ["purple"]: [
              "CLIENT",
              "PROSPECT",
              "QUALIFIED_PROSPECT",
              "LOST",
            ].includes(client?.stage ?? ""),
            ["orange"]: [
              "CONTACTED",
              "DISQUALIFIED_PROSPECT",
              "PENDING_DECISION",
            ].includes(client?.stage ?? ""),
            ["green"]: ["IN_NEGOTIATION"].includes(client?.stage ?? ""),
          })}
        >
          {client?.stage ? t("STAGE_STATUS." + client?.stage) : "-"}
        </span>
      </div>
      <div className="spacer" />

      <div className={styles.actions}>
        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("VISIT")}
          >
            <IoIosCheckboxOutline size={16} />
          </div>
          <div className="textCenter">{tGlobal("VISIT")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("MEETING")}
          >
            <HiOutlineVideoCamera size={16} />
          </div>
          <div className="textCenter">{tGlobal("MEETING")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("CALL")}
          >
            <RiPhoneLine size={16} />
          </div>
          <div className="textCenter">{tGlobal("CALL")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("EMAIL")}
          >
            <AiOutlineMail size={16} />
          </div>
          <div className="textCenter">{tGlobal("EMAIL")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("REMINDER")}
          >
            <GrNote size={16} />
          </div>
          <div className="textCenter">{tGlobal("REMINDER")}</div>
        </div>
      </div>

      <div className="spacer" />

      <div className="pw2">
        <p className="bold">{tGlobal("DESCRIPTION")}</p>
        {client?.createdAt && (
          <>
            <p className="bold mt1 uppercase">{tGlobal("CREATION_DATE")}</p>
            <p>
              {format(
                new Date(validateTimezoneDate(client?.createdAt)),
                isUSA ? USA_DATE_HOUR : DATE_HOUR
              )}
            </p>
          </>
        )}

        <p className="bold mt1 uppercase">{tGlobal("CLIENT_STATUS")}</p>
        <p
          className={clsx({
            ["purpleText"]: [
              "CLIENT",
              "PROSPECT",
              "QUALIFIED_PROSPECT",
              "LOST",
            ].includes(client?.stage ?? ""),
            ["orangeText"]: [
              "CONTACTED",
              "DISQUALIFIED_PROSPECT",
              "PENDING_DECISION",
            ].includes(client?.stage ?? ""),
            ["greenText"]: ["IN_NEGOTIATION"].includes(client?.stage ?? ""),
          })}
        >
          {client?.stage ? t("STAGE_STATUS." + client?.stage) : "-"}
        </p>

        <p className="bold mt1 uppercase">{tGlobal("MAIN_ADDRESS")}</p>
        <p>{addressToString(client?.location)}</p>

        <p className="bold mt1 uppercase">{tGlobal("EMAIL")}</p>
        <p>{client?.email}</p>

        <p className="bold mt1 uppercase">{tGlobal("CONTACT_NUMBER")}</p>
        <p>{client?.phone}</p>
      </div>

      <div className="spacer" />

      <ClientModalEvent
        isOpen={isOpen}
        onClose={onClose}
        type={modalType}
        onDone={() => {
          onChange(true);
        }}
      />
    </>
  );
};
