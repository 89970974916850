import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import {
  deliveryStatusRendering,
  paymentStatus,
  purchaseStatus,
} from "@/constants/purchases";
import { QUOTED, TPurchase } from "@/http";
import { usePurchase } from "@/store/purchase";
import { formatCurrency } from "@/utils/currency";
import format from "date-fns/format";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { exists } from "i18next";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { get } from "lodash-es";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

type TPurchaseListRow = {
  purchase: TPurchase;
};
export const PurchaseListRow = ({ purchase }: TPurchaseListRow) => {
  const [globalT] = useTranslation("global");
  const [t] = useTranslation("purchases");
  const navigate = useNavigate();
  const { statusFilters, isProcurementFilter } = usePurchase();
  const currentFilter = statusFilters["status"];
  const { role, isCommercial } = useAuth();
  const isQuotes = currentFilter === QUOTED;
  const isSale = !isProcurementFilter && !isQuotes;
  const parentRoute = isQuotes
    ? "quotes"
    : isProcurementFilter
    ? "acquisitions"
    : "purchases";
  const onClick = () => navigate(`/${parentRoute}/${purchase.id}/show`);

  return (
    <tr className={styles.purchaseRow}>
      <td onClick={onClick}>
        <span className="textLine">
          {format(
            new Date(purchase.createdAt),
            isUSA ? USA_DATE_HOUR : DATE_HOUR
          )}
        </span>
      </td>
      {isQuotes &&
        hasPermission(isCommercial, role, [
          "action.quotes.show-commercial-creation",
        ]) && <td onClick={onClick}>{purchase?.createdByName || "-"}</td>}
      <td onClick={onClick}>{purchase.purchaseNumber}</td>
      <td onClick={onClick}>
        <div className={isSale ? "flexColumn center leftAlign lh1" : ""}>
          <p className="textLine">
            {purchase?.paymentMethod
              ? globalT(`PAYMENT_METHOD.${purchase?.paymentMethod}`)
              : purchase?.payments?.length > 0
              ? globalT(
                  `PAYMENT_METHOD.${purchase?.payments[0]?.paymentMethod}`
                )
              : "-"}
          </p>
          {isSale &&
            get(
              purchase,
              "paymentProvider",
              get(purchase, "payments[0].paymentProvider")
            ) && (
              <span className="bold mdFont">
                {t("PAYMENT_PROVIDER")}
                {": "}
                {exists(
                  `global:PAYMENT_METHOD.PROVIDERS.${get(
                    purchase,
                    "paymentProvider",
                    get(purchase, "payments[0].paymentProvider")
                  )}`
                )
                  ? globalT(
                      `PAYMENT_METHOD.PROVIDERS.${get(
                        purchase,
                        "paymentProvider",
                        get(purchase, "payments[0].paymentProvider")
                      )}`
                    )
                  : purchase.paymentProvider}
              </span>
            )}
        </div>
      </td>
      <td onClick={onClick}>{formatCurrency(purchase.total)}</td>
      {!isProcurementFilter && (
        <>
          <td>
            <div className="flexGap lh1 leftAlign">
              <SellerResume seller={purchase?.client} isSlim />
            </div>
          </td>
          <td>
            <div className="flexGap centerAlign">
              <span className="lh1">{purchase?.commercialName ?? "-"}</span>
            </div>
          </td>
        </>
      )}

      {currentFilter != QUOTED && (
        <>
          <td onClick={onClick}>
            <div className="flexColumn center leftAlign lh1">
              <p>
                <span
                  className={
                    ["PENDING"].includes(purchase.status)
                      ? "redText"
                      : ["DONE"].includes(purchase.status)
                      ? "greenText"
                      : ""
                  }
                >
                  {purchaseStatus.find(s => s.code === purchase.status)?.name}
                </span>
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_DELIVERY_STATUS")}
                  {": "}
                </span>
                {
                  deliveryStatusRendering.find(
                    s => s.code === purchase.deliveryStatus
                  )?.name
                }
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_PAYMENT_STATUS")}
                  {": "}
                </span>
                {
                  paymentStatus.find(s => s.code === purchase.paymentStatus)
                    ?.name
                }
              </p>
            </div>
          </td>
        </>
      )}
      {isSale && (
        <td>
          {purchase.hasInvoice ? (
            <div className="greenText">
              <BsFillCheckCircleFill />
            </div>
          ) : (
            <div className="redText">
              <BsFillXCircleFill />
            </div>
          )}
        </td>
      )}
    </tr>
  );
};
