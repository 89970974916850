import { isCO, isUSA } from "@/constants/core";
import { TProduct } from "@/http";
import { useTranslation } from "react-i18next";
import { BiGift } from "react-icons/bi";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

type TProductResume = {
  product: TProduct;
};

export const ProductResume = ({ product }: TProductResume) => {
  const [t] = useTranslation("global");
  return (
    <span className="flexColumn center leftAlign lh1">
      <Link to={`/products/${product.id}`} className="textUnderline">
        {product?.type === "GIFT" && (
          <>
            <BiGift className={styles.giftIcon} />{" "}
          </>
        )}
        {product.name}
      </Link>
      {product.brand?.name && (
        <div className={styles.description}>
          {`${t("BRAND")}: ${product.brand?.name}`}
        </div>
      )}
      {(isCO || !!product?.taxPercentage) && (
        <span className="flexGap center">
          <span className="bold mdFont">
            {(isUSA ? t("TAX") : "IVA") +
              ": " +
              (product?.taxPercentage || 0) +
              " %"}
          </span>
        </span>
      )}
    </span>
  );
};
