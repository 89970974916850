import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";
import { FEAT_CREDIT } from "@/constants/features";
import { Card } from "@/components/shared/Card/Card";
import { PaymentMethodsGroup } from "../../PurchaseCreateForm/PaymentMethodsGroup/PaymentMethodsGroup";
import { Link } from "react-router-dom";
import styles from "../styles.module.scss";
import { useSubscription } from "@/hooks/useSubscription";
import { useTranslation } from "react-i18next";
import { deleteDuplicatedObjects } from "@/utils/object";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SalePaymentsForm } from "../Payments/SalePaymentsForm";
import { SalePaymentDetail } from "../Payments/SalePaymentDetail";
import { SaleTotalPaid } from "../Payments/SaleTotalPaid";

export const WarehouseInfo = ({
  type = "SALE",
  prepurchase,
}: {
  type?: string;
  prepurchase: any;
}) => {
  const { hasFeature } = useSubscription();
  const isQuotation = type === "QUOTE";
  const [t] = useTranslation("purchases");
  const products = useWatch({ name: "products" });
  const payments = useWatch({ name: "payments" }) ?? [];
  const coins = useWatch({ name: "coins" }) ?? [];
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [coinsBalance, setCoinsBalance] = useState(0);
  const [maxCoins, setMaxCoins] = useState(0);
  const { setValue } = useFormContext();

  useEffect(() => {
    const warehouses = products
      ?.map((p: any) => {
        if (p?.dispatchWarehouse) {
          return p?.productSupplier?.warehouses?.find(
            (w: any) => w?.warehouseId === p.dispatchWarehouse
          );
        } else {
          return null;
        }
      })
      ?.filter((w: any) => !!w?.id);
    setWarehouses(deleteDuplicatedObjects(warehouses ?? [], "warehouseId"));
  }, [products]);

  useEffect(() => {
    const choices =
      prepurchase?.paymentMethods?.filter(
        (p: any) => p.code !== "PARTIAL_PAYMENT"
      ) || [];
    setPaymentMethods(choices);
    setCoinsBalance(Number(prepurchase?.coinsBalance?.balance || 0));
    setMaxCoins(
      Number(
        prepurchase?.coinsBalance?.balance || 0 > prepurchase?.total || 0
          ? prepurchase?.total ?? 0
          : prepurchase?.coinsBalance?.balance || 0
      )
    );
  }, [prepurchase]);

  return (
    <>
      {((warehouses?.length > 0 && !isQuotation) ||
        paymentMethods?.length > 0) && (
        <Card>
          <div className="flexGap flexSmColumn">
            {(paymentMethods?.length > 0 || coinsBalance > 0) && (
              <div className="col-12 col-sm-12 noPadding">
                <PaymentMethodsGroup
                  paymentMethods={prepurchase.paymentMethods}
                  coinsBalance={coinsBalance}
                  maxCoins={maxCoins}
                />
                <SalePaymentsForm
                  paymentMethods={prepurchase.paymentMethods}
                  payments={payments}
                  total={prepurchase?.total ?? 0}
                  onDone={(payment: any) => {
                    setValue("payments", [...payments, payment]);
                  }}
                  coins={Number(coins || 0)}
                />
                <div className="flexGap flexColumn">
                  {(payments || []).map((p: any, idx: number) => (
                    <SalePaymentDetail
                      payment={p}
                      key={idx}
                      paymentMethods={prepurchase.paymentMethods}
                      payments={payments}
                      coins={Number(coins || 0)}
                      total={prepurchase?.total ?? 0}
                      onEdit={(payment: any) => {
                        setValue(`payments[${idx}]`, payment);
                      }}
                      onDelete={() => {
                        const newPayments = (payments || []).filter(
                          (_: any, index: number) => idx !== index
                        );
                        setValue("payments", newPayments);
                      }}
                    />
                  ))}
                </div>
                <div className="spacer" />

                <SaleTotalPaid
                  payments={payments}
                  total={prepurchase?.total ?? 0}
                  coins={coins}
                  isQuotation={isQuotation}
                />

                {!hasFeature(FEAT_CREDIT) && (
                  <Link to="/subscription">
                    <p className={styles.paymentMethodCreditPremium}>
                      <PremiumIcon /> {t("PAYMENT_METHOD_CREDIT_PREMIUM")}
                    </p>
                  </Link>
                )}
              </div>
            )}
          </div>
        </Card>
      )}
    </>
  );
};
