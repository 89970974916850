import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { getPurchasesByIds, getTicketsBySupplierId, TPurchase } from "@/http";
import { get } from "lodash-es";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { useNavigate, useSearchParams } from "react-router-dom";
import { buildSearch } from "@/pages/Clients/ClientList";
import clsx from "clsx";
import { Card } from "@/components/shared/Card/Card";
import { hasPermission } from "@/utils/validations/permissions";

export const TicketListTable = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { isCommercial, role, user, commercial } = useAuth();
  const [search] = useSearchParams("page=0");
  const page = +search.get("page")!;
  const type = search.get("type")!;
  const status = search.get("status");

  const mapType =
    {
      PURCHASE_ORDERS: "PURCHASE",
      SALES: "PURCHASE",
      OTHERS: "OTHER",
      PRODUCTS_TITLE: "PRODUCTS",
    }[type] || "";

  const previousParams =
    type === "PURCHASE_ORDERS"
      ? buildSearch(buildSearch("", "sellerId:", user.id), "status:", status)
      : buildSearch(buildSearch("", "status:", status), "type:", mapType);

  const buildSearchParams =
    isCommercial &&
    !hasPermission(isCommercial, role, ["action.tickets.see-all-list"])
      ? buildSearch(previousParams, "commercialId:", commercial?.id)
      : previousParams;

  const params: any = {
    search: buildSearchParams,
    size: 10,
    page,
    sort: "createdAt,desc",
  };

  const swrData = useSWR([params, "tickets-list"], getTicketsBySupplierId);
  const { data, error } = swrData;
  const tickets = get(data, "content", []);
  const purchasesIds = tickets
    .filter(t => !!t.purchaseId)
    .map(t => t.purchaseId);

  const { data: purchases } = useSWR(
    [purchasesIds, "purchases-by-ids"],
    getPurchasesByIds
  );
  const purchasesMap = (purchases || []).reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, TPurchase>);

  if (error) {
    return <p>Error</p>;
  }
  return (
    <Card className="noPadding">
      {tickets?.length > 0 ? (
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("CREATION_DATE")}</th>
                <th>{t("TICKET_NUMBER")}</th>
                <th>{t("ORDER_NUMBER")}</th>
                <th>{t("CREATED_BY")}</th>
                <th>{t("STATUS")}</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map(ticket => (
                <tr
                  key={ticket.id}
                  className={clsx(styles.row, "pointer")}
                  onClick={() => navigate(`/tickets/${ticket.id}`)}
                >
                  <td>
                    {ticket?.createdAt
                      ? format(
                          new Date(ticket.createdAt),
                          isUSA ? USA_DATE_HOUR : DATE_HOUR
                        )
                      : "-"}
                  </td>
                  <td>{ticket.number}</td>
                  <td>
                    {ticket?.purchaseId
                      ? purchasesMap[ticket.purchaseId]?.purchaseNumber ?? "-"
                      : "-"}
                  </td>
                  <td>{ticket.createdByName ?? "-"}</td>
                  <td>
                    {ticket.status ? (
                      <div>
                        <span className="statusChip lh1 textLine">
                          <span
                            className={clsx("dot mrmd", {
                              ["red"]: ["BLOCKED_BY_CLIENT"].includes(
                                ticket.status
                              ),
                              ["gray"]: "PENDING" === ticket.status,
                              ["orange"]: ["REJECTED"].includes(ticket.status),
                              ["green"]: ["IN_PROGRESS", "APPROVED"].includes(
                                ticket.status
                              ),
                            })}
                          />
                          {t(ticket.status)}
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      ) : (
        <h2 className={styles.noData}>{t("NO_DATA")}</h2>
      )}
    </Card>
  );
};
