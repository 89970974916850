import { type TSignupForm } from "@/models/auth";
import { FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";
import { showToastError } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useCountries } from "@/hooks/useCountry";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { isValidPhone } from "@/utils/validations/email";
import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { SHOULD_SHOW_ONBOARDING } from "@/components/onboarding/constants";
import { ClientOTPModal } from "@/components/clients/ClientOTPModal";
import { useState } from "react";
import { useTitle } from "ahooks";

export const SignupPage: React.FC = () => {
  const [t] = useTranslation("global");
  useTitle(t("SIGNUP.SUBMIT_BUTTON") + " | Portal Seeri");

  const [isOTPMOdalOpen, setOTPModalOpen] = useState(false);
  const [isUserValidated, setUserValidated] = useState(false);
  const countries = useCountries();
  const methods = useForm<TSignupForm & { country: string }>({
    defaultValues: { countryCode: "" },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  const { signup } = useAuth();

  const onSubmit = async (values: TSignupForm) => {
    try {
      if (isUserValidated) {
        await signup(values);
        localStorage.setItem(SHOULD_SHOW_ONBOARDING, "yes");
        const { name, email, countryCode } = values;
        window.dataLayer?.push({
          event: "signup-success",
          errors: JSON.stringify([countryCode, email, name].join(" - ")),
        });
      } else {
        setOTPModalOpen(true);
      }
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  const onInvalid: SubmitErrorHandler<TSignupForm> = errors => {
    const values = getValues();

    const flattenedErrors = Object.entries(errors)
      .filter(([k]) => k !== "password")
      .map(([k, v]) => [k, values[k as keyof TSignupForm], v.message]);

    window.dataLayer?.push({
      event: "signup-validation-error",
      errors: JSON.stringify(flattenedErrors, null, 2),
    });
  };

  return (
    <div className={styles.signupContainer}>
      <section className={styles.content}>
        <div className={styles.logo}>
          <img src="/logo_blue.svg" alt="seeri logo" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <h6 className={styles.title}>{t("SIGNUP.TITLE")}</h6>
            <h6 className={styles.description}></h6>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit, onInvalid)}
                className={styles.inputs}
              >
                <InputV2
                  name="name"
                  label={t("SIGNUP.COMPANY_NAME")}
                  required
                  min={1}
                />
                <Autocomplete
                  inputName="country"
                  inputLabel={t("SIGNUP.COUNTRY_CODE")}
                  inputPlaceholder=""
                  required
                  choices={
                    countries?.map(c => ({
                      ...c,
                      id: `${c.name}`,
                      name: `${c.emoji} ${c.name}`,
                    })) ?? []
                  }
                  action={country => {
                    methods.setValue("country", country?.id ?? "");
                    methods.setValue(
                      "countryCode",
                      country?.prefix.toLowerCase() ?? ""
                    );
                    methods.setValue(
                      "phonePrefix",
                      country?.phoneCode ? `+${country?.phoneCode}` : ""
                    );
                  }}
                />
                <div className={styles.gridPhone}>
                  <InputV2
                    name="phonePrefix"
                    label={t("SIGNUP.CONTACT_PHONE_PREFIX")}
                    disabled
                  />
                  <InputV2
                    name="phone"
                    label={t("SIGNUP.CONTACT_PHONE")}
                    required
                    validate={(v: string) =>
                      isValidPhone(v) || t("SIGNUP.INVALID_FORMAT")
                    }
                    maxLength={10}
                  />
                </div>
                <InputV2
                  name="email"
                  label={t("SIGNUP.CONTACT_EMAIL")}
                  type="email"
                  placeholder={t("LOGIN.EMAIL_PLACEHOLDER")}
                  required
                />
                <InputV2
                  name="password"
                  label={t("SIGNUP.PASSWORD")}
                  type="password"
                  placeholder="******"
                  required
                  minLength={8}
                />
                <p className={styles.passwordHelper}>
                  {t("SIGNUP.PASSWORD_HELPER")}
                </p>
                <div className={styles.actions}>
                  <button
                    className={`primary ${styles.buttonSignup}`}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t("SIGNUP.SUBMIT_BUTTON")}
                  </button>
                </div>
                <ClientOTPModal
                  isOTPMOdalOpen={isOTPMOdalOpen}
                  setOTPModalOpen={setOTPModalOpen}
                  isUserValidated={isUserValidated}
                  setUserValidated={setUserValidated}
                  onSubmit={onSubmit}
                  otpOrigin="users"
                />
              </form>
            </FormProvider>
            <div className={styles.goToLogin}>
              <Link to="/login" className={styles.link}>
                {t("SIGNUP.GO_TO_LOGIN")}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.rights}>{t("LOGIN.RIGHTS")}</div>
      </section>
    </div>
  );
};
