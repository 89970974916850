import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import {
  getSellerById,
  getSupplierCommercialById,
  getTicketsComments,
  TTicketDetail,
} from "@/http";
import clsx from "clsx";
import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import { PiUserList } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { TicketCommentsFilter } from "./TicketCommentsFilter";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { useEffect, useState } from "react";
import { useAuth } from "@/store/auth";
import { TicketCommentCreation } from "./TicketCommentCreation";
import { buildSearch } from "@/pages/Clients/ClientList";
import { RiErrorWarningLine } from "react-icons/ri";
import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";

export const TicketComments = ({
  ticket,
  onDone,
}: {
  ticket: TTicketDetail;
  onDone: () => void;
}) => {
  const [search] = useSearchParams("pageComments=0");
  const page = +search.get("pageComments")!;
  const type = search.get("commentType");
  const [t] = useTranslation("global");

  const params: any = {
    size: 4,
    page,
    sort: "createdAt,desc",
    search: buildSearch("", "visibility:", type || ""),
  };

  const swrData = useSWR(
    [ticket.id, params, "tickets-movements"],
    getTicketsComments
  );
  const comments = get(swrData, "data.content", []);
  const isClosed = ["APPROVED", "REJECTED"].includes(ticket.status);

  useEffect(() => {
    if (ticket) {
      swrData.mutate();
    }
  }, [ticket]);

  return (
    <div>
      <p className="bold">{t("COMMENTS_TYPE")}</p>
      <TicketCommentsFilter />
      <div className="spacer" />
      <p className="bold">{t("COMMENTS")}</p>
      <div className="spacer" />
      {!isClosed && ticket.status !== "BLOCKED_BY_CLIENT" && (
        <TicketCommentCreation
          ticket={ticket}
          onDone={() => {
            swrData.mutate();
            onDone();
          }}
        />
      )}
      <div className="spacer" />
      <p className="bold">{t("TICKET_HISTORY_COMMENTS")}</p>
      <div className="spacer" />

      <PaginatedTable swrData={swrData} paginationKey="pageComments">
        <div className="flexGap flexColumn">
          {comments.map(m => (
            <CommentContainer comment={m} key={m?.id} />
          ))}
        </div>
      </PaginatedTable>
    </div>
  );
};

const CommentContainer = ({ comment }: { comment: any }) => {
  const isInternal = comment?.visibility === "INTERNAL";
  const isFromClient = comment?.visibility === "FROM_CLIENT";
  const [t] = useTranslation("global");

  return (
    <div
      className={clsx({
        ["purpleBackground pw1 ph1"]: isInternal,
        ["grayContainer"]: !isInternal,
      })}
    >
      <div className="contentInline">
        <div>
          <span
            className={clsx({
              [styles.userIcon]: isInternal,
              [styles.userIconGray]: !isInternal,
            })}
          >
            <PiUserList size={20} />
          </span>
        </div>
        <div className="flexGap flexColumn mdFont flex1">
          <div className="contentInline">
            <span className="bold textLine">
              {get(
                comment,
                "createdBy.internalName",
                get(comment, "createdBy.email", "-")
              )}
            </span>
            <span className="flexColumn">
              <span className="textLine">
                {format(
                  new Date(comment.createdAt),
                  isUSA ? USA_DATE_HOUR : DATE_HOUR
                )}
              </span>
              <span
                className={clsx("smFont bold rigthAlign textLine", {
                  ["purpleText"]: isInternal,
                })}
              >
                {isInternal
                  ? t("INTERNAL_COMMENT")
                  : isFromClient
                  ? t("FROM_CLIENT_COMMENT")
                  : t("EXTERNAL_COMMENT")}
              </span>
            </span>
          </div>
          <div>{comment.comment}</div>
          {!!comment?.sendToCLient && (
            <div className="flexGap  center orangeText mdFont gapSm">
              <RiErrorWarningLine className="bolder" size={18} />
              <span>
                <span className="bold ">{t("ACTION_REQUIRED") + ": "}</span>
                <span>{t("CLIENT_RESPONSE")}</span>
              </span>
            </div>
          )}
        </div>
      </div>
      {comment?.attachments?.length > 0 && (
        <>
          <ImagesList
            allowExternal
            images={comment?.attachments ?? []}
            showName
          />
        </>
      )}
    </div>
  );
};

export const UserName = ({ userId }: { userId: string }) => {
  const [userName, setUserName] = useState("-");
  const { user } = useAuth();
  useEffect(() => {
    if (userId) {
      if ((userId = user.id)) {
        setUserName(user.name);
      } else {
        getSupplierCommercialById(userId)
          .then(c => setUserName(c.firstName + " " + c.lastName))
          .catch(() => {
            getSellerById(userId)
              .then(s =>
                setUserName(
                  isUSA && s?.legalName
                    ? s.legalName
                    : `${s?.firstName ?? ""} ${s?.lastName ?? ""}`
                )
              )
              .catch(() => setUserName("-"));
          });
      }
    } else {
      setUserName("-");
    }
  }, [userId]);

  return <>{userName}</>;
};
