import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { isUSA } from "@/constants/core";
import { DATE_MONT_YEAR_SLASH } from "@/constants/date";
import { TPurchase } from "@/http";
import { formatCurrency } from "@/utils/currency";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
type TTicketPurchaseInfo = {
  purchase: TPurchase;
};
export const TicketPurchaseInfo = ({ purchase }: TTicketPurchaseInfo) => {
  const [t] = useTranslation("global");
  const [tPurchases] = useTranslation("purchases");
  const { type } = useParams();

  return (
    <div className="grayContainer flexGap flexWrap mdFont">
      <div className="flex1 textCenter flexColumn">
        <span className="textLine">{tPurchases("ORDER_NUMBER")}</span>
        <span className="textLine">
          <span className="bold">{purchase.purchaseNumber}</span>
          {purchase?.createdAt && (
            <span>
              {" "}
              {format(
                new Date(purchase.createdAt),
                isUSA ? DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
              )}
            </span>
          )}
        </span>
      </div>
      <div className="flex1 textCenter flexColumn">
        <span className="textLine">{tPurchases("TOTAL")}</span>
        <span className="bold">{formatCurrency(purchase.total)}</span>
      </div>
      <div className="flex1 textCenter flexColumn">
        <span className="textLine">{tPurchases("PAYMENT_METHOD")}</span>
        <span className="bold textLine">
          {purchase?.paymentMethod
            ? t(`PAYMENT_METHOD.${purchase?.paymentMethod}`) +
              ` (${purchase?.paymentProvider})`
            : purchase?.payments?.length > 0
            ? t(`PAYMENT_METHOD.${purchase?.payments[0]?.paymentMethod}`) +
              ` (${purchase?.payments[0]?.paymentProvider})`
            : "-"}
        </span>
      </div>
      {type !== "PURCHASE_ORDERS" && (
        <div className="flex1 textCenter flexColumn">
          <span className="textLine">{tPurchases("SELLER_NAME")}</span>
          <span className="bold">
            <SellerResume
              seller={purchase.client}
              hideEdit
              isSlim
              className="centerAlignImportant"
            />
          </span>
        </div>
      )}
    </div>
  );
};
