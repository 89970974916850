import { showToastError } from "@/hooks/useToast";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { getRealMimeType, knownMimeTypes } from "@/utils/file-real-mime-type";
import { LuPaperclip } from "react-icons/lu";

type Props = {
  accept?: string;
  setFiles: (files: any[]) => void;
  files: any[];
  minifiedStyle?: boolean;
  height?: string;
  slim?: boolean;
};
export const DragAndDropFile: FC<Props> = ({
  accept,
  files,
  setFiles,
  minifiedStyle,
  height,
  slim = false,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [t] = useTranslation("global");

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      readFiles(e.target.files);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      readFiles(e.dataTransfer.files);
    }
  };

  const readFiles = async (fileList: FileList) => {
    const filesArray = Array.from(fileList).map(file => file);
    const filesToRead: File[] = [];

    for (const file of filesArray) {
      if (files.some(f => f.name === file.name)) {
        continue;
      }

      if (!accept) {
        filesToRead.push(file);
        continue;
      }

      for (const acceptedMimeType of accept.split(",")) {
        if (knownMimeTypes.some(t => t === acceptedMimeType)) {
          if ((await getRealMimeType(file)) !== acceptedMimeType) {
            continue;
          }
        } else {
          if (acceptedMimeType !== file.type) {
            continue;
          }
        }

        filesToRead.push(file);
        break;
      }
    }

    if (filesToRead.length !== filesArray.length) {
      showToastError(t("NO_FILES_LOADED"));
    }

    setFiles([...files, ...filesToRead]);
  };

  const onButtonClick = (e: any) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <>
      <div
        className={slim ? "dNone" : styles.formFileUpload}
        onDragEnter={handleDrag}
        style={{ ...(height ? { height } : {}) }}
      >
        <input
          ref={inputRef}
          type="file"
          className={styles.inputFileUpload}
          multiple={false}
          onChange={handleChange}
          {...(accept ? { accept } : {})}
        />
        <label
          className={`${styles.labelFileUpload} ${
            minifiedStyle ? styles.minified : ""
          } ${dragActive ? styles.active : ""}`}
        >
          <div>
            <button
              type="button"
              className={
                minifiedStyle
                  ? styles.uploadButtonMinified
                  : styles.uploadButton
              }
              onClick={onButtonClick}
            >
              {t("UPLOAD_FILE")}
            </button>
            <div className="spacer" />
            <p className="mdFont">{t("DRAG_FILE")}</p>
          </div>
        </label>
        {dragActive && (
          <div
            className={styles.dragFileElement}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
      {slim && (
        <span className="pointer flexGap gapSm center" onClick={onButtonClick}>
          <LuPaperclip />
          {t("ATTACH_FILES")}
        </span>
      )}
    </>
  );
};
