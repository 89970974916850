import ProductImage from "@/components/layout/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { useSupplierBrands } from "@/hooks/useSupplier";
import { searchProducts, TProduct, TSeller } from "@/http";
import { useAuth } from "@/store/auth";
import { getProductSupplierFromProduct } from "@/utils/products";
import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import styles from "./styles.module.scss";

type TSaleProductsResult = {
  watchKey?: string;
};
export const SaleProductsResult = ({
  watchKey = "products",
}: TSaleProductsResult) => {
  const [t] = useTranslation("purchases");
  const debounceRefProduct = useRef<any>();
  const [options, setOptions] = useState<TProduct[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const { user } = useAuth();
  const { setValue, watch } = useFormContext();
  const warehouseDispatch = useWatch({ name: "warehouseId" });
  const supplierId = watch("supplierId");
  const isSale = watch("purchaseType") === "SALE";
  const seller = watch("seller") as TSeller | undefined;
  const allBrands = useSupplierBrands(user.id);
  const segment =
    seller?.suppliers?.find(s => s.supplierId === supplierId)?.segments?.[0]
      ?.name ?? "";
  const useParentSupplier =
    (watch("parentSupplier") ?? "") === "parentSupplier";

  const isValidStock = (productSupplier: any) => {
    if (productSupplier?.availableStock > 0) {
      return true;
    }

    if (warehouseDispatch) {
      const warehouse = (productSupplier?.warehouses ?? []).find(
        (w: any) => w.warehouseId === warehouseDispatch
      );

      return (
        warehouse?.overStock && (warehouse?.overStockAmount ?? 1000000) > 0
      );
    } else {
      return (productSupplier?.warehouses ?? []).some(
        (w: any) => w?.overStock && (w?.overStockAmount ?? 1000000) > 0
      );
    }
  };

  const isProductLoaded = (id: string) => {
    const product = watch(watchKey ?? "selectedProducts")?.find(
      (product: any) => (watchKey ? product?.id === id : product === id)
    );
    return !!product;
  };

  const isProductWarehouseValid = (warehouses: any[]) => {
    if (warehouseDispatch) {
      const warehouse = warehouses.find(
        w => w?.warehouseId === warehouseDispatch
      );

      return !!warehouse?.id;
    }
    return true;
  };

  const setProduct = (product: TProduct) => {
    const selectedProducts = watch(watchKey ?? "selectedProducts");
    if (watchKey) {
      setValue(watchKey, [...selectedProducts, product]);
    } else {
      setValue("selectedProducts", [...selectedProducts, product.id]);
    }
    setValue("searchProductName", "");
    setShowOptions(false);
  };

  const getFilterProducts = async (name: string, brandId: string) => {
    const { content } = (await searchProducts({
      data: {
        text: name,
        brandId,
        supplierId: useParentSupplier ? user.id : supplierId,
        ...(isSale ? { status: true } : {}),
      },
      params: {
        sort: "name,asc",
        page: 0,
        size: 10,
        segment,
      },
    })) as any;
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const onProductNameChange = (value: string, brandId: string) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(value, brandId);
    }, 250);
  };

  useEffect(() => {
    if (watch("searchProductName")) {
      onProductNameChange(
        watch("searchProductName"),
        watch("brandSearch")?.id ?? ""
      );
    } else {
      setShowOptions(false);
    }
  }, [watch("searchProductName"), watch("brandSearch")]);

  return (
    <>
      {showOptions && (
        <>
          <div className="contentInline mbmd pw1">
            <div className="mdFont">
              <span>{t("RESULTS_SHOW")}</span>
              <span className="bold">{` "${watch("searchProductName")}"`}</span>
            </div>
            <div>
              <SingleMenu
                hideOnSelect
                align="rigth"
                label={
                  <span className="mdFont flexGap center">
                    <IoFilterSharp />
                    <span>
                      {t("FILTER_BY")}:{" "}
                      {watch("brandSearch")
                        ? watch("brandSearch")?.name ?? "-"
                        : t("ALL_BRANDS")}
                    </span>
                    <FaChevronDown size={10} />
                  </span>
                }
                options={[
                  ...(allBrands ?? []).map(b => ({
                    value: b,
                    label: b.name,
                  })),
                  { value: null, label: t("ALL_BRANDS") },
                ]}
                onSelect={value => setValue("brandSearch", value)}
              />
            </div>
          </div>
          <Card className="noPadding">
            <div className={styles.list}>
              <ul>
                {options.map((option: any) => {
                  const productSupplier = getProductSupplierFromProduct(
                    option,
                    useParentSupplier ? user.id : supplierId
                  );

                  return (
                    <li
                      key={option?.id}
                      onClick={() => {
                        const isEnabled = useParentSupplier
                          ? true
                          : isSale
                          ? isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) &&
                            !isProductLoaded(option?.id) &&
                            productSupplier?.status &&
                            isValidStock(productSupplier)
                          : !isProductLoaded(option?.id);
                        if (isEnabled) {
                          setProduct(
                            useParentSupplier
                              ? {
                                  ...option,
                                  unitPrice: option?.purchasePrice || 0,
                                }
                              : option
                          );
                        }
                      }}
                      className={
                        isSale
                          ? !isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) ||
                            isProductLoaded(option?.id) ||
                            !productSupplier?.status ||
                            !isValidStock(productSupplier)
                            ? styles.disabledProduct
                            : ""
                          : isProductLoaded(option?.id)
                          ? styles.disabledProduct
                          : ""
                      }
                    >
                      <div>
                        <ProductImage images={option.media ?? []} />
                      </div>
                      <div className={styles.description}>
                        <div className="flexColumn">
                          <span>{option?.name}</span>
                          {isSale &&
                            (!productSupplier?.status ||
                              !isValidStock(productSupplier)) && (
                              <span className={styles.sku}>
                                * {t("PRODUCT_DISABLED")}
                                <br />
                              </span>
                            )}
                          {isSale &&
                            !isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) && (
                              <span className={styles.sku}>
                                * {t("PRODUCT_WAREHOUSE_DISABLED")}
                                <br />
                              </span>
                            )}
                        </div>

                        {option?.brand?.name && (
                          <span className={`${styles.sku} bold`}>{`${t(
                            "BRAND"
                          )}: ${option.brand.name}`}</span>
                        )}
                        {option.sku && (
                          <span className={styles.sku}>
                            <span className="bold">{`, sku: ${option.sku}`}</span>
                          </span>
                        )}
                        {option.barCode && (
                          <span className={styles.sku}>
                            <span className="bold">{`, upc: ${option.barCode}`}</span>
                          </span>
                        )}
                      </div>
                    </li>
                  );
                })}
                {!options?.length && <li>{t("NO_OPTIONS")}</li>}
              </ul>
            </div>
          </Card>
        </>
      )}
    </>
  );
};
