import { getBanners, TBanner } from "@/http";
import { useAuth } from "@/store/auth";
import useUrlState from "@ahooksjs/use-url-state";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { PaginatedTable } from "../shared/PaginatedTable/PaginatedTable";
import { BannerForm } from "./BannerForm";

export const BannersList = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const [{ page }] = useUrlState({ page: "0" });
  const [isOpen, setIsOpen] = useState(false);

  const params: any = {
    search: `supplierId:${user.id}`,
    size: 10,
    page,
    sort: "name,asc",
  };

  const swrData = useSWR([params, "banners"], getBanners);
  const { data: banners, error, mutate } = swrData;

  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      <h6 className="contentInline">
        <span className="bold">Banners</span>
        {!isOpen && (
          <button className="primary" onClick={() => setIsOpen(true)}>
            {t("CREATE")}
          </button>
        )}
      </h6>
      <div className="divider" />
      {isOpen && (
        <>
          <BannerForm
            onDone={() => {
              mutate();
              setIsOpen(false);
            }}
            onCancel={() => setIsOpen(false)}
          />
          <div className="divider" />
        </>
      )}
      <PaginatedTable swrData={swrData} noScroll>
        {banners?.content?.map(banner => (
          <BannerRow key={banner.id} mutate={mutate} banner={banner} />
        ))}
      </PaginatedTable>
    </>
  );
};

const BannerRow = ({ mutate, banner }: { mutate: any; banner: TBanner }) => {
  return (
    <>
      <BannerForm banner={banner} onDone={() => mutate()} />
    </>
  );
};
