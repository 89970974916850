import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useState } from "react";
import clsx from "clsx";
import { IoEyeOutline } from "react-icons/io5";
import { PreviewFile } from "../../ProductsForm/ProductImage";
import { TProductSerial } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const AttachmentsModal = ({ serial }: { serial: TProductSerial }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const isEvent = (index: number) => index % 2 === 0;
  const [selectedImage, setSelectedImage] = useState(serial?.attachments?.[0]);

  const renderMedia = () => {
    if (!selectedImage?.src) {
      return <Spinner />;
    }

    if (selectedImage?.mimeType?.includes("image")) {
      return <img src={selectedImage?.src} className={styles.mainImage} />;
    } else if (selectedImage?.mimeType?.includes("video")) {
      return (
        <video className={styles.mainImage} controls>
          <source src={selectedImage.src} type={selectedImage.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (selectedImage?.mimeType === "application/pdf") {
      return (
        <div className={styles.mainImage}>
          <embed
            src={selectedImage.src}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      );
    } else {
      return (
        <div className={clsx(styles.mainImage, "centerAlign flexGap center")}>
          {t("FILE_NOT_SUPPORTED")}
        </div>
      );
    }
  };

  return (
    <>
      <span
        onClick={() => setIsOpen(true)}
        className="purpleText flexGap gapSm center centerAlign pointer"
      >
        <IoEyeOutline />
        <span>{t("SEE")}</span>
      </span>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={<>{t("SERIAL") + ": " + serial.serialNumber}</>}
      >
        <div className={styles.containerImage}>
          <div className={styles.imageMainContainer}>
            {serial?.attachments?.length > 0 ? renderMedia() : null}
          </div>
          <div className={styles.containerThumbnailImages}>
            {serial?.attachments?.length > 0 ? (
              serial?.attachments?.map((media: any, index: number) => {
                return (
                  <div
                    key={`${index}-image`}
                    className={`${
                      isEvent(index)
                        ? styles.borderIsEvent
                        : styles.borderIsNotEvent
                    } pointer`}
                  >
                    <PreviewFile
                      file={media}
                      onClick={() => setSelectedImage(media)}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
