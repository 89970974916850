import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { ChangeEvent, useRef } from "react";
import { useProducts } from "@/store/products";
import { ProductsGroupActions } from "./ProductsGroupActions";
import { useSupplierBrands } from "@/hooks/useSupplier";
import { useAuth } from "@/store/auth";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { BrandName } from "@/components/shared/Details";
import { FiChevronDown } from "react-icons/fi";
import clsx from "clsx";

export const ProductsGroupFilters = () => {
  const { tabCurrentFilter, setTabCurrentFilter } = useProducts();
  const { user } = useAuth();
  const [t] = useTranslation("products-page");

  return (
    <>
      <div className={`flexGap ${styles.tabContainer}`}>
        <div className={clsx("noPadding col-sm-12")}>
          <div>
            <p className="bold">{t("WAREHOUSE")}</p>
            <select
              value={tabCurrentFilter}
              onChange={e => setTabCurrentFilter(e.target.value)}
            >
              {[
                { text: "FILTERS.SEE_ALL", value: "" },
                ...(user?.addresses || []).map(a => ({
                  text: a.name,
                  value: a.id ?? "",
                })),
              ].map((option, index) => (
                <option
                  key={`${option.value}_${index}_select`}
                  value={option.value}
                >
                  {t(option.text)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={clsx("noPadding centerVAlign rigthAlignFlex col-sm-12")}
        >
          <ProductsGroupActions />
        </div>
      </div>
    </>
  );
};

export const SearchProductInput = () => {
  const debounceRef = useRef<any>();
  const [t] = useTranslation("products-page");
  const { setNameFilter, nameFilter } = useProducts();

  const onQueryChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setNameFilter(event.target.value);
    }, 350);
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          type="text"
          placeholder={t("SEARCH_PRODUCT")}
          onChange={onQueryChanged}
          defaultValue={nameFilter}
        />
      </div>
    </>
  );
};

export const BrandFilterInput = () => {
  const { user } = useAuth();
  const [t] = useTranslation("products-page");
  const { brandFilter, setBrandFilter } = useProducts();

  const supplierBrands = useSupplierBrands(user.id);

  return (
    <>
      <div className={styles.sort}>
        {supplierBrands?.length > 0 && (
          <>
            <SingleMenu
              onSelect={setBrandFilter}
              hideOnSelect
              align="rigth"
              label={
                <div className="menuTooltip flexGap center textLine">
                  {brandFilter ? (
                    <BrandName id={brandFilter} />
                  ) : (
                    t("PRODUCT.DETAIL.BRAND")
                  )}
                  <FiChevronDown color="brand.primary.500" />
                </div>
              }
              options={[
                ...supplierBrands.map(brand => ({
                  value: brand.id,
                  label: brand.name,
                })),
                { value: "", label: t("ALL_BRANDS_DESC") },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};
