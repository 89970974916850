import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";

export const TicketTypeFilter = () => {
  const [t] = useTranslation("global");
  const [search, setSearch] = useSearchParams("status=");
  const type = search.get("type");

  const onTypeChange = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("type", value);
    setSearch(newSearch);
  };

  return (
    <div className="flexGap center">
      <span className="bold textLine">{t("SELECT_TICKET_TYPE")}</span>
      <SingleMenu
        className={styles.lineContent}
        label={
          <div className={clsx(styles.filterContainer, "contentInline")}>
            <span>{!type ? t("SELECT") : t(type)}</span>
            <span>
              <FaChevronDown size={"0.7rem"} />
            </span>
          </div>
        }
        options={[
          { value: "SALES", label: t("SALES") },
          { value: "PURCHASE_ORDERS", label: t("PURCHASE_ORDERS") },
          { value: "PRODUCTS_TITLE", label: t("PRODUCTS_TITLE") },
          { value: "OTHERS", label: t("OTHERS") },
          { value: "ALL", label: t("ALL") },
        ]}
        onSelect={onTypeChange}
        hideOnSelect
      />
    </div>
  );
};
