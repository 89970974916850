import { TInvoice } from "../invoiceData";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./posStyles";

type Props = {
  invoice: TInvoice;
  translate: any;
};
export const SupplierInfo = ({ translate, invoice }: Props) => {
  const supplier = invoice.supplier;
  const mainAddress = supplier?.address;

  return (
    <View style={styles.header}>
      {supplier?.name && (
        <Text style={styles.headerText}>{supplier.name.toUpperCase()}</Text>
      )}
      {supplier?.companyId && (
        <Text style={styles.headerText}>{supplier.companyId}</Text>
      )}

      {(mainAddress?.addressLine1 || mainAddress?.addressLine2) && (
        <Text style={styles.addressText}>{`${mainAddress?.addressLine1 || ""} ${
          mainAddress?.addressLine2 || ""
        }`}</Text>
      )}
      {(mainAddress?.city || mainAddress?.state || mainAddress?.country) && (
        <Text style={styles.addressText}>
          {`${mainAddress?.city ? mainAddress?.city + ", " : ""}${
            mainAddress?.state ? mainAddress?.city + ", " : ""
          }${mainAddress?.country || ""}`}

          {mainAddress?.phone && (
            <Text>
              {" | "}
              {mainAddress?.phone}
            </Text>
          )}
        </Text>
      )}

      <View style={styles.spacer} />

      <Text style={styles.headerText}>{translate("INVOICE_INFO_TEXT")}</Text>
      <Text style={styles.headerText}>{invoice.number}</Text>

      <View style={styles.divider} />
    </View>
  );
};
