import {
  TProduct,
  TPurchase,
  getInvoicesByIds,
  getProductByIds,
  updateSupplier,
} from "@/http";
import styles from "./invoiceShow.module.scss";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useAuth } from "@/store/auth";
import { TInvoice } from "./invoiceData";
import { useEffect, useState } from "react";
import { biuldInvoiceData, biuldSupplierInfo } from "./utils";
import { Supplier } from "@/models/supplier";
import useSWR from "swr";
import { get } from "lodash-es";
import { Spinner } from "../shared/Spinner/Spinner";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import invoice_es from "../../i18n/es/invoice.json";
import invoice_en from "../../i18n/en/invoice.json";
import { Link } from "react-router-dom";
import { SwitchLocalFlag } from "./SwitchLocalFlag";
import { PosPDF } from "./POS/PosPDF";

export type TPurchaseShow = {
  purchase: TPurchase;
};
export const InvoiceShow = ({ purchase }: TPurchaseShow) => {
  const { user } = useAuth();
  const [products, setProducts] = useState<TProduct[]>();
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation("invoice");
  const productsIds = purchase.products?.map(p => p.id);
  const lang = user.countryCode.toUpperCase() === "US" ? "en" : "es";

  useEffect(() => {
    if (productsIds && productsIds.length > 0) {
      getProductByIds(productsIds).then(r => setProducts(r));
    }
  }, []);

  if (!Object.keys(user.address ?? {}).length) {
    return (
      <div className="warningContainer textCenter flexColumn">
        <span>{t("FUNCTION_INVOICE_DISABLED")}</span>
        <Link to="/account" className="textUnderline bold">
          {t("GO_TO_PROFILE")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <div className={styles.invoiceWrapper}>
        <div className={`${styles.titleWrapper} row ${styles.actions}`}>
          <div className={`flexGap flexSmColumn centerVAlign contentInline`}>
            <div className={`col-3 noPadding col-sm-6`} />
            <div className="col-sm-6 noPadding">
              {!isLoading && (
                <InvoiceViewer
                  downloadButton
                  purchase={purchase}
                  products={products ?? []}
                  lang={lang}
                />
              )}
            </div>
          </div>
          <div className="spacer" />
          <div>
            <SwitchLocalFlag
              setLoading={setLoading}
              isLoading={isLoading}
              item="showCommercialOnInvoice"
            />
            <div className="spacer" />
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <InvoiceViewer
            purchase={purchase}
            products={products ?? []}
            lang={lang}
          />
        )}
      </div>
    </>
  );
};

type TSwitchSupplierFlag = {
  setLoading: (value: boolean) => void;
  isLoading: boolean;
  type: string;
};
export const SwitchSupplierFlag = ({
  setLoading,
  isLoading,
  type,
}: TSwitchSupplierFlag) => {
  const [t] = useTranslation("invoice");
  const { user, setUser } = useAuth();

  const handleChange = async () => {
    try {
      setLoading(true);
      const newUser = await updateSupplier(user.id, {
        [type]: !get(user, type),
      });
      setUser(newUser);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="flexGap centerVAlign">
      <label className="switchBtn">
        <input
          type="checkbox"
          onChange={handleChange}
          checked={get(user, type)}
          className={isLoading ? "loading" : ""}
        />
        <div className={"slide"}></div>
      </label>
      <span>{t(`SHOW_${type.toUpperCase()}`)}</span>
    </div>
  );
};

type TInvoiceData = {
  downloadButton?: boolean;
  purchase: TPurchase;
  products: TProduct[];
  lang: "es" | "en";
};
const InvoiceViewer = ({
  purchase,
  products,
  lang,
  downloadButton = false,
}: TInvoiceData) => {
  const { user } = useAuth();
  const [t, setT] = useState(() => (key: string) => key);
  const [globalT] = useTranslation("global");
  const isSupplierSale = purchase.supplierId === user.id;

  let supplier: Partial<Supplier> = user;
  supplier = biuldSupplierInfo(isSupplierSale, supplier);

  const isQuote = purchase.status === "QUOTED";
  const showPrices = isQuote
    ? isQuote
    : Boolean(isSupplierSale && user.showPricingInfoOnInvoice);
  const showClientInfo = Boolean(
    isSupplierSale && user.showClientInfoOnInvoice
  );
  const invoiceIds = [...new Set(purchase.products.map(p => p.invoiceId))];
  const invoiceId = invoiceIds.length == 1 ? invoiceIds[0] : undefined;
  const { data } = useSWR([[invoiceId], "invoiceByIds"], getInvoicesByIds);
  const invoiceName = data?.find(invoice => invoice.id === invoiceId)?.name;
  const invoiceData: TInvoice = biuldInvoiceData(
    purchase,
    invoiceName,
    showPrices,
    showClientInfo,
    supplier,
    products,
    t
  );

  useEffect(() => {
    const localInstance = i18n.createInstance();
    localInstance
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            invoice: invoice_en,
          },
          es: {
            invoice: invoice_es,
          },
        },
        lng: lang,
        fallbackLng: "en",
      })
      .then(() => {
        setT(() => localInstance.getFixedT(lang, "invoice"));
      });
  }, [lang]);

  if (downloadButton) {
    return (
      <PDFDownloadLink
        document={<PosPDF translate={t} invoice={invoiceData} />}
      >
        <button className="primary lg">{globalT("DOWNLOAD")}</button>
      </PDFDownloadLink>
    );
  }

  return (
    <PDFViewer width="100%" height="700" className="app">
      <PosPDF translate={t} invoice={invoiceData} />
    </PDFViewer>
  );
};
