import { useState } from "react";
import styles from "./styles.module.scss";
import { useProducts } from "@/store/products";
import { MdOutlineModeEdit } from "react-icons/md";
import BrandImage from "@/components/layout/BrandImage";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { optionsToValueString } from "@/utils/variants";
import { StatusProduct } from "./StatusProduct";
import { PreviewFile } from "../ProductsForm/ProductImage";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useNavigate } from "react-router";

type TImagesVariant = {
  enableEditing: boolean;
  isSupplierBrand: boolean;
  setEnableEditing: (value: boolean) => void;
};

export const ImagesVariant = ({
  enableEditing,
  isSupplierBrand,
}: TImagesVariant) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { selectedVariantEdit } = useProducts();
  const [selectedImage, setSelectedImage] = useState(
    selectedVariantEdit?.media?.[0]
  );
  const { isCommercial, role } = useAuth();

  const isEvent = (index: number) => index % 2 === 0;

  const renderMedia = () => {
    if (!selectedImage?.src) {
      return <Spinner />;
    }

    if (selectedImage?.mimeType?.includes("image")) {
      return <img src={selectedImage?.src} className={styles.mainImage} />;
    } else if (selectedImage?.mimeType?.includes("video")) {
      return (
        <video className={styles.mainImage} controls>
          <source src={selectedImage.src} type={selectedImage.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (selectedImage?.mimeType === "application/pdf") {
      return (
        <div className={styles.mainImage}>
          <embed
            src={selectedImage.src}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      );
    } else {
      return (
        <div className={clsx(styles.mainImage, "centerAlign flexGap center")}>
          {t("FILE_NOT_SUPPORTED")}
        </div>
      );
    }
  };

  return (
    <div className={`col-5 col-sm-12 noPadding`}>
      <div className="row">
        <div className="col-12 noPadding">
          {(!enableEditing || !isSupplierBrand) && (
            <>
              <div className={`row ${styles.containerName}`}>
                <div className={`col-12 noPadding ${styles.nameText}`}>
                  {selectedVariantEdit?.name} |{" "}
                  {optionsToValueString(
                    selectedVariantEdit?.productOptions ?? {}
                  )}
                  {!enableEditing &&
                    hasPermission(isCommercial, role, [
                      "action.product.edit",
                    ]) && (
                      <span>
                        <MdOutlineModeEdit
                          className={`noPadding pointer ${styles.editIcon}`}
                          onClick={() =>
                            navigate(`/products/${selectedVariantEdit.id}/edit`)
                          }
                        />
                      </span>
                    )}
                </div>
              </div>

              <StatusProduct />
              <div className="spacer" />
              <div>
                <BrandImage
                  useEmpty
                  id={selectedVariantEdit?.brandId}
                  style={{ margin: "0px 0px 10px 0px" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.containerImage}>
        <div className={styles.imageMainContainer}>
          {selectedVariantEdit?.media?.length > 0 ? renderMedia() : null}
        </div>
        <div className={styles.containerThumbnailImages}>
          {selectedVariantEdit?.media?.length > 0 ? (
            selectedVariantEdit?.media?.map((media: any, index: number) => {
              return (
                <div
                  key={`${index}-image`}
                  className={`${
                    isEvent(index)
                      ? styles.borderIsEvent
                      : styles.borderIsNotEvent
                  } pointer`}
                >
                  <PreviewFile
                    file={media}
                    onClick={() => setSelectedImage(media)}
                  />
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
