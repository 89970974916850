import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TSupplierEvent } from "../../types/supplier-event";

const BASE_PATH = "/api/suppliers/events";

export const createSupplierEvent = async (data: Partial<TSupplierEvent>) => {
  return getInstance()
    .post<TSupplierEvent>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplierEvent = async (
  eventId: string,
  data: Partial<TSupplierEvent>
) => {
  return getInstance()
    .put<TSupplierEvent>(`${BASE_PATH}/${eventId}`, data)
    .then(res => res.data);
};

export const getSupplierEventById = async (eventId: string) => {
  return getInstance()
    .get<TSupplierEvent>(`${BASE_PATH}/${eventId}`)
    .then(res => res.data);
};

export const getSupplierEvents = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierEvent>>(BASE_PATH, {
      params,
    })
    .then(res => res.data);
};

export const deleteSupplierEvent = async (eventId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${eventId}`)
    .then(res => res.data);
};
