import ProductImage from "@/components/layout/ProductImage";
import { SerialModal } from "@/components/products/ProductDetail/Serials/SerialsModal";
import { Card } from "@/components/shared/Card/Card";
import { MultipleSelectionSerial } from "@/components/shared/Input/MultipleSelectionSerial";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { isCO, isUSA } from "@/constants/core";
import { customToast } from "@/hooks/useToast";
import {
  getProductsbyIds,
  TProduct,
  TProductSerial,
  TSeller,
  TUnitType,
} from "@/http";
import { useAuth } from "@/store/auth";
import { getProductSupplierFromProduct } from "@/utils/products";
import { hasPermission } from "@/utils/validations/permissions";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ProductSearch } from "../../PurchaseOrders/ProductSearch/ProductSearch";

import styles from "./styles.module.scss";

export const SaleProducts = () => {
  const { watch, setValue } = useFormContext();
  const [showUnitType, setShowUnitType] = useState(false);
  const products = watch("products");
  const supplierIdWatch = watch("supplierId");
  const sellerIdWatch = watch("sellerId");
  const [t] = useTranslation("global");
  const { isCommercial, role } = useAuth();
  const isSale = watch("purchaseType") === "SALE";
  const seller = watch("seller") as TSeller | undefined;
  const supplierId = watch("supplierId");
  const warehouseDispatch = useWatch({ name: "warehouseId" });
  const segment =
    seller?.suppliers?.find(s => s.supplierId === supplierId)?.segments?.[0]
      ?.name ?? "";
  const { user } = useAuth();

  useEffect(() => {
    if (products?.length) {
      const productsIds = products.map(({ id }: TProduct) => id);
      const productsCollection = products
        .map(
          ({
            id,
            discountPercentage,
            wholesalePrice,
            dispatchWarehouse,
            quantity,
            unitRate,
            unitType,
            serials,
          }: any) => ({
            id,
            discountPercentage,
            wholesalePrice,
            dispatchWarehouse,
            quantity,
            unitRate,
            unitType,
            serials,
          })
        )
        .reduce((acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {});
      setValue("products", []);
      getProductsbyIds(productsIds, segment)
        .then(products =>
          setValue(
            "products",
            products?.map(p => ({ ...p, ...productsCollection[p.id] }))
          )
        )
        .catch(() => {
          customToast({ title: t("ERROR_LOADING_PRODUCTS"), status: "warn" });
          setValue("products", []);
        });
    }
  }, [sellerIdWatch]);

  useEffect(() => {
    if (products?.length > 0) {
      setShowUnitType(
        (products ?? [])?.some((p: any) => p?.unitTypes?.length > 1)
      );
    } else {
      setShowUnitType(false);
    }
  }, [products]);

  return (
    <Card
      contentBottom={
        supplierIdWatch ? (
          <div className="flexGap centerAlign">
            <div className="col-6 col-sm-12">
              <ProductSearch />
            </div>
          </div>
        ) : null
      }
      className="noPadding"
    >
      {products?.length > 0 && (
        <div className="tableScroll">
          <table className={styles.table}>
            <thead>
              <tr className={`${styles.row} bold`}>
                <td></td>
                <td>{t("PURCHASES.ORDERS.PRODUCT")}</td>
                <td>{t("PURCHASES.ORDERS.PRICE")}</td>

                {hasPermission(isCommercial, role, [
                  "action.purchase.discount",
                ]) && <td>{t("DISCOUNT_PERCENTAGE")}</td>}
                {showUnitType && <td>{t("PURCHASES.ORDERS.UNIT_TYPE")}</td>}
                {isSale && !warehouseDispatch && (
                  <td>{t("PURCHASES.ORDERS.WAREHOUSE")}</td>
                )}
                <td>{t("PURCHASES.ORDERS.QUANTITY")}</td>
                {user.allowSerials && <td>{t("SERIALS")}</td>}
                <td>{t("PURCHASES.ORDERS.ACTIONS")}</td>
              </tr>
            </thead>
            <tbody>
              {(products ?? []).map((p: any, idx: number) => (
                <SaleProductRow
                  product={p}
                  idx={idx}
                  key={idx}
                  showUnitType={showUnitType}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

const SaleProductRow = ({
  product,
  idx,
  showUnitType,
}: {
  product: any;
  idx: number;
  showUnitType: boolean;
}) => {
  const [productSupplier, setProductSupplier] = useState<any>();
  const [warehouse, setWarehouse] = useState<any>();
  const { watch, setValue } = useFormContext();
  const [t] = useTranslation("global");
  const products = watch("products");
  const warehouseId = useWatch({ name: `products[${idx}].dispatchWarehouse` });
  const { user, isCommercial, role } = useAuth();
  const isSale = watch("purchaseType") === "SALE";
  const warehouseDispatch = useWatch({ name: "warehouseId" });
  const unitRateWatch = useWatch({ name: `products[${idx}].unitRate` });
  const unitRate = Number(unitRateWatch || 1);
  const methods = useFormContext();

  const isValidStock = (productSupplier: any) => {
    if (productSupplier?.availableStock / unitRate > 0) {
      return true;
    }

    if (warehouseId) {
      const warehouse = (productSupplier?.warehouses ?? []).find(
        (w: any) => w.warehouseId === warehouseId
      );

      return (
        warehouse?.overStock &&
        (warehouse?.overStockAmount ?? 1000000) / unitRate > 0
      );
    } else {
      return (productSupplier?.warehouses ?? []).some(
        (w: any) =>
          w?.overStock && (w?.overStockAmount ?? 1000000) / unitRate > 0
      );
    }
  };

  const isProductWarehouseValid = (warehouses: any[]) => {
    if (warehouseDispatch) {
      const warehouse = warehouses.find(
        w => w?.warehouseId === warehouseDispatch
      );

      return !!warehouse?.id;
    }
    return true;
  };

  const onDelete = (idx: number) => {
    const productsFiltered = (products ?? []).filter(
      (p: string, i: number) => idx !== i
    );
    setValue("products", productsFiltered);
  };

  const handleWarehouse = (id: string) => {
    const warehouse =
      productSupplier?.warehouses?.find((a: any) => a.warehouseId === id) ?? {};
    setWarehouse(warehouse);
  };

  const getWarehouseName = (id: string) => {
    return user?.addresses?.find(a => a.id === id)?.name;
  };

  const setSerial = (serial: TProductSerial) => {
    const currentSerial = methods.watch(`products[${idx}].serials`);

    if (currentSerial?.length) {
      methods.setValue(`products[${idx}].serials`, [
        ...currentSerial,
        serial.id,
      ]);
    } else {
      methods.setValue(`products[${idx}].serials`, [serial.id]);
    }
  };

  useEffect(() => {
    if (product) {
      const ps = getProductSupplierFromProduct(product, user.id);
      if (warehouseDispatch) {
        const warehousesIds = (ps?.warehouses ?? []).map(
          (w: any) => w.warehouseId
        );
        if (warehousesIds.includes(warehouseDispatch)) {
          setTimeout(() => {
            setValue(`products[${idx}].dispatchWarehouse`, warehouseDispatch);
          }, 500);
        } else {
          setTimeout(() => {
            setValue(`products[${idx}].dispatchWarehouse`, "");
            setValue(`products[${idx}].invalidProduct`, true);
          }, 500);
        }
      } else {
        if (ps?.warehouses?.length === 1 && isSale) {
          if (!warehouseId)
            setValue(
              `products[${idx}].dispatchWarehouse`,
              ps?.warehouses?.[0]?.warehouseId
            );
        } else {
          setValue(`products[${idx}].dispatchWarehouse`, "");
          if (warehouseId) {
            setTimeout(() => {
              setValue(`products[${idx}].dispatchWarehouse`, warehouseId);
            }, 500);
          }
        }
      }
      setProductSupplier(ps);
      setValue(`products[${idx}].productSupplier`, ps);
    } else {
      setProductSupplier({});
      setValue(`products[${idx}].productSupplier`, {});
    }
    setValue(`products[${idx}].wholesalePrice`, product?.wholesalePrice);
    setValue(
      `products[${idx}].discountPercentage`,
      product?.discountPercentage
    );
  }, [product, isSale, idx, warehouseDispatch]);

  useEffect(() => {
    if (warehouseId) {
      handleWarehouse(warehouseId);
    }
  }, [warehouseId, productSupplier]);

  useEffect(() => {
    if (unitRateWatch) {
      const unit = (product.unitTypes ?? []).find(
        (u: TUnitType) => Number(u?.rate) === Number(unitRateWatch)
      );
      setValue(`products[${idx}].unitType`, unit?.name);
    }
  }, [unitRateWatch]);

  return (
    <tr
      className={clsx(
        {
          [styles.warningProduct]:
            (!isProductWarehouseValid(productSupplier?.warehouses ?? []) ||
              !productSupplier?.id ||
              !productSupplier?.status ||
              !isValidStock(productSupplier)) &&
            isSale,
        },
        styles.row
      )}
    >
      <td>
        <ProductImage images={product?.media ?? []} />
      </td>
      <td className={styles.productText}>
        <div className="flexColumn center leftAlign lh1">
          <span>{product?.name}</span>

          {(isCO || !!product?.taxPercentage) && (
            <span className="flexGap center">
              <span className="bold mdFont">
                {(isUSA ? t("TAX") : "IVA") +
                  ": " +
                  (product?.taxPercentage || 0) +
                  " %"}
              </span>
              <Link
                to={`/products/${product.id}`}
                className="textUnderline circleButton"
              >
                <BiEdit />
              </Link>
            </span>
          )}
        </div>
      </td>
      {(
        isSale
          ? productSupplier?.status &&
            isValidStock(productSupplier) &&
            isProductWarehouseValid(productSupplier?.warehouses ?? [])
          : !isSale
      ) ? (
        <>
          <td>
            <NumericInput
              name={`products[${idx}].wholesalePrice`}
              label=""
              decimals
              hideControls
            />
          </td>
          <>
            {hasPermission(isCommercial, role, [
              "action.purchase.discount",
            ]) && (
              <td className={styles.inputContainer}>
                <NumericInput
                  name={`products[${idx}].discountPercentage`}
                  label=""
                  decimals
                  hideControls
                  min={0.01}
                  max={100}
                />
              </td>
            )}
            {showUnitType ? (
              <td>
                {product?.unitTypes?.length > 0 ? (
                  <SelectV2
                    name={`products[${idx}].unitRate`}
                    label=""
                    required
                    choices={(product?.unitTypes ?? [])
                      .sort((a: any, b: any) => {
                        if (a?.isDefault === b?.isDefault) {
                          return 0;
                        }
                        return a?.isDefault ? -1 : 1;
                      })
                      .map((u: TUnitType) => ({
                        value: u?.rate,
                        label: u?.name,
                      }))}
                  />
                ) : (
                  <>
                    N/A
                    <input
                      type="hidden"
                      value={1}
                      {...methods.register(`products[${idx}].unitRate`)}
                    />
                  </>
                )}
              </td>
            ) : (
              <input
                type="hidden"
                value={1}
                {...methods.register(`products[${idx}].unitRate`)}
              />
            )}
            {isSale && !warehouseDispatch && (
              <td
                className={`${styles.inputContainer} ${styles.warehouseSelect}`}
              >
                <SelectV2
                  name={`products[${idx}].dispatchWarehouse`}
                  label=""
                  required
                  choices={(productSupplier?.warehouses ?? []).map(
                    (w: any) => ({
                      value: w.warehouseId,
                      label: getWarehouseName(w.warehouseId),
                    })
                  )}
                  infoLabel={
                    warehouse?.id
                      ? `${t("PURCHASES.ORDERS.STOCK_AVAILABLE")}: ${
                          product?.partialUnitsAllowed
                            ? (warehouse?.availableStock / unitRate).toFixed(2)
                            : Math.floor(warehouse?.availableStock / unitRate)
                        }`
                      : ""
                  }
                />
              </td>
            )}
          </>
          <td className={styles.inputContainer}>
            <NumericInput
              name={`products[${idx}].quantity`}
              label=""
              required
              decimals={product?.partialUnitsAllowed}
              disabled={isSale ? !warehouse?.id : false}
              max={
                isSale
                  ? unitRate !== 1
                    ? Math.floor(
                        Number(warehouse?.availableStock ?? 0) / unitRate +
                          (warehouse?.overStock
                            ? Number(warehouse?.overStockAmount ?? 1000000) /
                              unitRate
                            : 0)
                      )
                    : Number(warehouse?.availableStock ?? 0) +
                      (warehouse?.overStock
                        ? Number(warehouse?.overStockAmount ?? 1000000)
                        : 0)
                  : undefined
              }
              min={product?.partialUnitsAllowed ? 0.1 : 1}
            />
          </td>
          {user.allowSerials && (
            <td>
              <div className={styles.serialInput}>
                <MultipleSelectionSerial
                  disabled={
                    !((methods.watch(`products[${idx}].quantity`) || 0) > 0)
                  }
                  maxOptions={methods.watch(`products[${idx}].quantity`) || 0}
                  name={`products[${idx}].serials`}
                  productId={product.id}
                  infoLabel={
                    (methods.watch(`products[${idx}].quantity`) || 0) > 0 && (
                      <div className="rigthAlign">
                        <SerialModal
                          productId={product.id}
                          onDone={setSerial}
                          slim
                        />
                      </div>
                    )
                  }
                />
              </div>
            </td>
          )}
        </>
      ) : (
        <td colSpan={warehouseDispatch ? 3 : 4} className="orangeText">
          {t("PURCHASES.ORDERS.PRODUCT_UNAVAILABLE")}
        </td>
      )}
      <td>
        <AiOutlineDelete
          className="pointer mt2"
          onClick={() => onDelete(idx)}
        />
      </td>
    </tr>
  );
};
