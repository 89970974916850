export const validateOnlyNumber = (event: any) => {
  const isNumber = /[0-9]/.test(event.key);
  const isAllowedKey = ["Backspace", "ArrowRight", "ArrowLeft", "Tab"].includes(
    event.key
  );
  const isCtrlCommand =
    event.ctrlKey && ["c", "v", "x"].includes(event.key.toLowerCase());

  if (!isNumber && !isAllowedKey && !isCtrlCommand) {
    event.preventDefault();
  }
};

export const validateNoSpaces = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const isNoSpace = /^\S*$/.test(event.key);
  const isCtrlCommand =
    event.ctrlKey && ["c", "v", "x"].includes(event.key.toLowerCase());

  if (!isNoSpace && !isCtrlCommand) {
    event.preventDefault();
  }
};

export const validateDecimalNumber = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const currentValue = event.currentTarget.value;
  const selectionStart = event.currentTarget.selectionStart ?? 0;
  const regex = /^\d*\.?\d{0,2}$/;

  const isCtrlCommand =
    event.ctrlKey && ["c", "v", "x"].includes(event.key.toLowerCase());

  const newValue =
    currentValue.slice(0, selectionStart) +
    event.key +
    currentValue.slice(selectionStart);

  if (!regex.test(newValue) && !isCtrlCommand) {
    event.preventDefault();
  }
};
