import { Card } from "@/components/shared/Card/Card";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { TicketCreationForm } from "@/components/tickets/TicketList/TicketCreation/TicketCreationForm";
import { TicketPurchaseInfo } from "@/components/tickets/TicketList/TicketCreation/TicketPurchaseInfo";
import { FEAT_TICKETS } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { getPurchaseById } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";

export const TicketCreatePage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("purchases");
  const { hasFeature } = useSubscription();
  const { purchaseId } = useParams();
  const { data: purchase } = useSWR(
    purchaseId ? [purchaseId, "purchase-by-id"] : null,
    getPurchaseById
  );

  useEffect(() => {
    setTitle(t("CREATE_TICKET"), "/tickets", undefined, undefined, true);
  }, [t]);

  if (!hasFeature(FEAT_TICKETS)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <Card className="noPadding">
      <p className="bold pw1 phmd purpleBackground">{t("CREATE_TICKET")}</p>
      <div className="pw1 ph1">
        {purchase && (
          <>
            <TicketPurchaseInfo purchase={purchase} />
            <TicketCreationForm purchase={purchase} />
          </>
        )}
      </div>
    </Card>
  );
};
