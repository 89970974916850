import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import Addresses from "@/components/profile/addresses/Addresses";
import PersonalData from "@/components/profile/personal-data/PersonalData";
import { BoxesList } from "@/components/profile/boxes/BoxesList";
import { PaymentProviders } from "@/components/profile/PaymentProviders/PaymentProviders";
import { DeliveryProvidersList } from "@/components/profile/DeliveryProviders/DeliveryProvidersList";
import { TermsAndConditios } from "@/components/profile/TermsAndConditions/TermsAndConditions";

const SupplierProfile = () => {
  const { setTitle } = useHeaderTitle(s => s);
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle(t("ACCOUNT.TITLE"));
  }, [t]);
  return (
    <>
      <PersonalData />
      <div className="spacer" />
      <TermsAndConditios />

      <PaymentProviders />
      <div className="spacer" />
      <DeliveryProvidersList />
      <BoxesList />
      <Addresses />
    </>
  );
};

export default SupplierProfile;
