import ProductImage from "@/components/layout/ProductImage";
import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { Card } from "@/components/shared/Card/Card";
import { ProductName } from "@/components/shared/Details";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { getPurchaseById, getTicketById } from "@/http";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";
import { TicketCommercialUpdate } from "./TicketCommercialUpdate";
import { TicketDetailInfo } from "./TicketDetailInfo";
import styles from "./styles.module.scss";
import { TicketClose } from "./TicketClose";
import { TicketComments } from "./TicketComments";
import { useSearchParams } from "react-router-dom";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { TicketHistory } from "./TicketHistory";
import { TicketReopen } from "./TicketReopen";

export const TicketDetail = () => {
  const { id } = useParams();
  const [search, setSearch] = useSearchParams("tab=COMMENTS");
  const tab = search.get("tab")!;
  const [t] = useTranslation("global");
  const { data: ticket, mutate: ticketUpdate } = useSWR(
    id ? [id, "ticket-by-id"] : null,
    getTicketById
  );
  const { data: purchase } = useSWR(
    ticket?.purchaseId ? [ticket.purchaseId, "purchase-by-id"] : null,
    getPurchaseById
  );

  const onTabChange = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tab", value);
    setSearch(newSearch);
  };

  if (!ticket) {
    return <Spinner />;
  }

  return (
    <Card className="noPadding">
      <div className="purpleBackground pw1 phmd bold mdFont">
        {t("MANAGE_TICKET")}
        {" > "}
        {ticket?.number}
      </div>
      <div className="pw1 ph1">
        {ticket && <TicketDetailInfo purchase={purchase!} ticket={ticket} />}

        <div className="flexGap flexSmColumn">
          <div className="col-6 col-sm-12">
            <TicketCommercialUpdate
              ticket={ticket}
              onDone={() => ticketUpdate()}
            />

            {(ticket?.products?.length || 0) > 0 && (
              <>
                <div className="spacer" />
                <p className="bold">{t("SELECTED_PRODUCTS")}</p>
                {ticket?.products.map((p: any, idx: number) => (
                  <div
                    className="grayContainer mtmd pw1 ph1 contentInline center"
                    key={idx}
                  >
                    <span className="flexGap center bold mdFont">
                      <ProductImage id={p.id} />
                      <ProductName id={p.id} />
                    </span>
                    <span>
                      {p.quantity} {t(p.quantity === 1 ? "UNIT" : "UNITS")}
                    </span>
                  </div>
                ))}
              </>
            )}
            {["APPROVED", "REJECTED"].includes(ticket.status) && (
              <>
                <div className="spacer" />
                <p className="bold">{t("SUPPLIER_RESPONSE")}</p>
                <div className={styles.responsible}>{t(ticket.status)}</div>
              </>
            )}

            {(ticket?.attachments?.length || 0) > 0 && (
              <>
                <div className="spacer" />
                <p className="bold">{t("ATTACHMENT_FILES")}</p>
                <ImagesList
                  allowExternal
                  images={ticket?.attachments ?? []}
                  showName
                />
              </>
            )}
          </div>
          <div className="dividerVertical" />
          <div className="col-6 col-sm-12">
            <FilterTabs
              options={[
                { text: t("COMMENTS"), value: "COMMENTS" },
                { text: t("TICKET_HISTORY"), value: "HISTORY" },
              ]}
              currentFilter={tab}
              action={onTabChange}
            />
            <div className="spacer" />

            <>
              {tab === "COMMENTS" && (
                <TicketComments ticket={ticket} onDone={() => ticketUpdate()} />
              )}
              {tab === "HISTORY" && <TicketHistory ticket={ticket} />}
            </>
          </div>
        </div>
        <div className="spacer" />
        <div className="pw1 ph1 rigthAlign">
          <TicketClose ticket={ticket} onDone={() => ticketUpdate()} />
          <TicketReopen ticket={ticket} onDone={() => ticketUpdate()} />
        </div>
      </div>
    </Card>
  );
};
