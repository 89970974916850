import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { isUSA } from "@/constants/core";
import { DATE_MONT_YEAR_SLASH } from "@/constants/date";
import { getSellerById, TPurchase, TTicketDetail } from "@/http";
import { formatCurrency } from "@/utils/currency";
import clsx from "clsx";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export const TicketDetailInfo = ({
  purchase,
  ticket,
}: {
  purchase: TPurchase;
  ticket: TTicketDetail;
}) => {
  const [t] = useTranslation("global");
  const [tPurchases] = useTranslation("purchases");
  const isPurchase = ticket?.type === "PURCHASE";
  const isOther = ticket?.type === "OTHER";
  const isProduct = ticket?.type === "PRODUCTS";
  const { data: seller } = useSWR(
    [ticket?.sellerId, "getSellerById"],
    getSellerById
  );

  return (
    <div className="grayContainer flexGap flexWrap mdFont">
      {isPurchase && (
        <div className="flex1 textCenter flexColumn">
          <span className="textLine">{tPurchases("ORDER_NUMBER")}</span>
          <span className="textLine">
            <span className="bold">{purchase?.purchaseNumber}</span>
            {purchase?.createdAt && (
              <span>
                {" "}
                {format(
                  new Date(purchase?.createdAt),
                  isUSA ? DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                )}
              </span>
            )}
          </span>
        </div>
      )}
      {!isPurchase && (
        <div className="flex1 textCenter flexColumn">
          <span className="textLine">{tPurchases("CREATED_AT")}</span>
          <span className="textLine bold">
            {format(
              new Date(ticket?.createdAt),
              isUSA ? DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
            )}
          </span>
        </div>
      )}
      <div className="flex1 textCenter flexColumn">
        <span className="textLine">{tPurchases("SELLER_NAME")}</span>
        <span className="bold">
          <SellerResume
            seller={seller}
            hideEdit
            isSlim
            className="centerAlignImportant"
          />
        </span>
      </div>
      {isPurchase && (
        <>
          <div className="flex1 textCenter flexColumn">
            <span className="textLine">{t("TICKET_REQUIREMENT")}</span>
            <span className="bold textLine">
              {tPurchases(ticket.requirement)}
            </span>
          </div>
          <div className="flex1 textCenter flexColumn">
            <span className="textLine">{tPurchases("PAYMENT_METHOD")}</span>
            <span className="bold textLine">
              {purchase?.paymentMethod
                ? t(`PAYMENT_METHOD.${purchase?.paymentMethod}`) +
                  ` (${purchase?.paymentProvider})`
                : purchase?.payments?.length > 0
                ? t(`PAYMENT_METHOD.${purchase?.payments[0]?.paymentMethod}`) +
                  ` (${purchase?.payments[0]?.paymentProvider})`
                : "-"}
            </span>
          </div>
          <div className="flex1 textCenter flexColumn">
            <span className="textLine">{t("SALE_TOTAL")}</span>
            <span className="bold textLine">
              {formatCurrency(purchase?.total ?? 0)}
            </span>
          </div>
        </>
      )}
      {isProduct && get(ticket, "products[0].serials[0]", null) && (
        <>
          <div className="flex1 textCenter flexColumn">
            <span className="textLine">{tPurchases("PRODUCT_SERIAL")}</span>
            <span className="bold textLine">
              {get(ticket, "products[0].serials[0]", "")}
            </span>
          </div>
        </>
      )}

      {isOther && (
        <>
          <div className="flex1 textCenter flexColumn">
            <span className="textLine">{tPurchases("SUBJECT")}</span>
            <span className="bold textLine">{ticket.subject}</span>
          </div>
        </>
      )}

      <div className="flex1 textCenter flexColumn">
        <span className="textLine">{t("TICKET_STATUS")}</span>
        <span className="bold textLine">
          <span
            className={clsx("dot mrmd", {
              ["red"]: ["BLOCKED_BY_CLIENT"].includes(ticket.status),
              ["gray"]: "PENDING" === ticket.status,
              ["orange"]: ["REJECTED"].includes(ticket.status),
              ["green"]: ["IN_PROGRESS", "APPROVED"].includes(ticket.status),
            })}
          />
          {t(ticket.status)}
        </span>
      </div>
    </div>
  );
};
