import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSupplierCommercial, TSupplierCommercial } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./styles.module.scss";
import { useCommercials } from "@/store/commercials";
import { useTranslation } from "react-i18next";

type TDeleteCommercial = {
  commercial: TSupplierCommercial;
};
export const CommercialDelete = ({ commercial }: TDeleteCommercial) => {
  const [t] = useTranslation("global");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { getCommercials } = useCommercials();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierCommercial(commercial.id ?? "");
      setIsLoading(false);
      setModalOpen(false);
      getCommercials(user.id);
      showToastSuccess(t("COMMERCIALS.COMMERCIAL_DELETED"));
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <>
      <ToolTip title={t("COMMERCIALS.DELETE_COMMERCIAL")} position="Left">
        <AiOutlineDelete cursor="pointer" onClick={() => setModalOpen(true)} />
      </ToolTip>
      <Modal isOpen={isModalOpen} size="sm" onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <h6 className="bold">{t("COMMERCIALS.DELETE_COMMERCIAL")}</h6>
          <div className={"divider"} />
          <div className={styles.content}>
            <p>{`${t("COMMERCIALS.SURE_DELETE")} ${commercial.firstName} ${
              commercial.lastName
            }?`}</p>
            <div className={"spacer"} />
            <div className={"spacer"} />
          </div>
          <div className={"divider"} />
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
