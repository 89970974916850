import { InputV2 } from "@/components/shared/Input/InputV2";
import { isAfter, parseISO } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const DatesInput = ({ disabled = false }: { disabled: boolean }) => {
  const [t] = useTranslation("global");
  const { watch } = useFormContext();
  const startsAt = watch("startsAt");

  const validateEndsDate = (ends: string) => {
    if (!startsAt || !ends) {
      return true;
    }
    const startDate = parseISO(startsAt);
    const endsDate = parseISO(ends);

    return isAfter(endsDate, startDate);
  };

  return (
    <>
      <div className="flexGap">
        <div className="col-6 col-sm-12 noPadding">
          <InputV2
            type="datetime-local"
            label={t("OFFERS.START_DATE")}
            name="startsAt"
            required
            disabled={disabled}
          />
        </div>
        <div className="col-6 col-sm-12 noPadding">
          <InputV2
            disabled={!startsAt || disabled}
            type="datetime-local"
            label={t("OFFERS.END_DATE")}
            name="endsAt"
            validate={validateEndsDate}
            validMessage={t("OFFERS.ENDS_DATE_MUST_GREATER_STARTS")}
            required
          />
        </div>
      </div>
    </>
  );
};
