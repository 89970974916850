import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSerial, TProductSerial } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";

type TSerialDelete = {
  serial: TProductSerial;
  onDone?: () => void;
};
export const SerialDelete = ({ serial, onDone }: TSerialDelete) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await deleteSerial(serial.productId, serial.id!);
      onDone?.();
      setIsLoading(false);
      showToastSuccess(t("SERIAL_DELETED"));
      setModalOpen(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToolTip title={t("DELETE")} position="Left">
        <span className="pointer circleButton">
          <AiOutlineDelete onClick={() => setModalOpen(true)} />
        </span>
      </ToolTip>
      <Modal
        title={t("DELETE_SERIAL")}
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        footer={
          <button
            className="primary md"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{`${t("SURE_DELETE_SERIAL")} "${serial.serialNumber}"?`}</p>
      </Modal>
    </>
  );
};
