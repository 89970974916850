import {
  getSupplierAddressbyId,
  getSupplierAddresses,
  getSupplierBrandsV3,
  getSupplierById,
  getSupplierCommercialById,
  getSupplierCommercials,
  TSupplierCommercial,
} from "@/http";
import useSWR from "swr";

export const useSupplierBrands = (supplierId: string) => {
  const { data: brands } = useSWR(
    supplierId ? [supplierId, "supplierBrands"] : null,
    getSupplierBrandsV3
  );

  return brands ?? [];
};

export const useSupplierAddressMain = (supplierId: string) => {
  const { data: addresses = { content: [] } } = useSWR(
    supplierId
      ? [
          supplierId,
          {
            page: 0,
            size: 100,
          },
          "supplierMainAddress",
        ]
      : null,
    getSupplierAddresses
  );

  return addresses.content.length
    ? addresses.content.find(address => address.isMain)
    : { id: "" };
};

export const useSupplierAddressById = (
  supplierId: string,
  addressId: string
) => {
  const { data: address } = useSWR(
    supplierId ? [supplierId, addressId, "supplierAddress"] : null,
    getSupplierAddressbyId
  );

  return address;
};

export const useSupplierNameById = (supplierId: string) => {
  const { data: supplier } = useSWR(
    supplierId ? [supplierId, "supplierById"] : null,
    getSupplierById
  );

  return supplier?.name ?? "-";
};

export const useSupplierById = (supplierId: string) => {
  const { data: supplier } = useSWR(
    supplierId ? [supplierId, "supplierById"] : null,
    getSupplierById
  );

  return supplier;
};

export const useUserNameById = async (userId: string) => {
  if (!userId) {
    return { name: "-", email: "" };
  }

  try {
    const supplier = await getSupplierById(userId);
    if (supplier?.name || supplier?.email) {
      return { name: supplier.name, email: supplier.email };
    }
    return { name: "-", email: "" };
  } catch (_) {
    try {
      const commercial = await getSupplierCommercialById(userId);
      if (commercial?.firstName || commercial?.lastName || commercial?.email) {
        return {
          name: commercial.firstName + " " + commercial.lastName,
          email: commercial.email,
        };
      }
      return { name: "-", email: "" };
    } catch (_) {
      return { name: "-", email: "" };
    }
  }
};

export const useCommercialById = (commercialId: string) => {
  const { data: commercial } = useSWR(
    commercialId ? [commercialId, "commercialById"] : null,
    getSupplierCommercialById
  );

  return commercial ?? ({} as TSupplierCommercial);
};

export const useCommercials = (supplierId: string) => {
  const params = { size: 1000, page: 0 };
  return getSupplierCommercials(params);
};
