import { formatFileName } from "@/components/products/ProductsForm/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { isUSA } from "@/constants/core";
import { getSupplierEvents } from "@/http/resources/suppliers/events";
import { validateTimezoneDate } from "@/utils/date";
import useUrlState from "@ahooksjs/use-url-state";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";
import { useParams } from "react-router";
import useSWR from "swr";
import { ClientDeleteEvent } from "./ClientDeleteEvent";
import { ClientModalEvent } from "./ClientModalEvent";

export const ClientEvents = ({
  onChange,
  sharedReload,
}: {
  onChange: (value: boolean) => void;
  sharedReload: boolean;
}) => {
  const { id } = useParams();
  const [t] = useTranslation("global");
  const [{ page }] = useUrlState({ page: "0" });
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [supplierEvent, setSupplierEvent] = useState<any>(null);

  const swrData = useSWR(
    id
      ? [
          {
            search: `clientId:${id}`,
            size: 10,
            page,
            sort: "scheduledAt,desc",
          },
          id,
          "supplier-events",
        ]
      : null,
    getSupplierEvents
  );

  const { data: events, error, mutate } = swrData;

  const onClose = () => {
    setIsOpen(false);
    setModalType("");
    setSupplierEvent(null);
  };

  useEffect(() => {
    if (sharedReload) {
      mutate();
      onChange(false);
    }
  }, [sharedReload]);

  return (
    <>
      {error ? (
        <p>Error</p>
      ) : (
        <PaginatedTable swrData={swrData} noScroll>
          <div className="flexGap flexColumn">
            {events?.content?.map(event => (
              <Card key={event.id} className="pwmd">
                <div className="contentInline center mdFont ">
                  <div className="textJustify">
                    <span className="bold">{t(event.type)}</span>{" "}
                    <span>
                      {format(
                        new Date(validateTimezoneDate(event.scheduledAt)),
                        isUSA
                          ? `MMMM d '${t("OF")}' yyyy HH:mm`
                          : `d '${t("OF")}' MMMM '${t("OF")}' yyyy HH:mm`,
                        {
                          locale: isUSA ? enUS : es,
                        }
                      )}
                    </span>
                    <br />
                    <span>{event.title}</span>
                    {event.description && (
                      <>
                        {" | "}
                        <span className="bold">{event.description}</span>
                      </>
                    )}
                  </div>
                  <div className="flexGap center">
                    {event?.attachment && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={event.attachment}
                        className="statusChip pwmd flexGap center"
                      >
                        <VscFilePdf />
                        <span className="smFont">
                          {formatFileName(event?.attachment ?? "")}
                        </span>
                      </a>
                    )}
                    <span
                      className="circleButton pointer"
                      onClick={() => {
                        setSupplierEvent(event);
                        setModalType(event.type);
                        setIsOpen(true);
                      }}
                    >
                      <AiOutlineEdit />
                    </span>
                    <ClientDeleteEvent event={event} onDone={() => mutate()} />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </PaginatedTable>
      )}
      {supplierEvent && modalType && (
        <ClientModalEvent
          isOpen={isOpen}
          onClose={onClose}
          type={modalType}
          event={supplierEvent}
          onDone={() => {
            onChange(true);
          }}
        />
      )}
    </>
  );
};
