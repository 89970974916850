import { TClient, TSeller, createSeller, updateSeller } from "@/http";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../shared/Modal/Modal";
import styles from "./styles.module.scss";
import { FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import { useCountries } from "@/hooks/useCountry";
import { InputV2 } from "../shared/Input/InputV2";
import { validateOnlyNumber } from "@/utils/keyboard";
import { isUSA } from "@/constants/core";
import { isValidEmail } from "@/utils/validations/email";
import { RecordCustomAttributes } from "../custom-fields/RecordCustomAttributes";
import { Spinner } from "../shared/Spinner/Spinner";
import { ClientOTPModal } from "./ClientOTPModal";
import { db } from "@/core/firebase";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { AiOutlineEdit, AiOutlineUserAdd } from "react-icons/ai";
import { DocumentTypeInput } from "../purchases/PurchaseCreateForm/PurchaseInvoice/DocumentTypeInput";
import clsx from "clsx";
import ToolTip from "../shared/Tooltip/Tooltip";
import { MdOutlineEdit } from "react-icons/md";
import { singleSelectStyles } from "@/constants/input";
import { SelectV2 } from "../shared/Input/SelectV2";

type ClientModalProps = {
  client?: TSeller | TClient;
  onDone?: (client: TSeller) => void;
  iconButton?: boolean;
  smallIconButton?: boolean;
  hideButton?: boolean;
  outlinedButton?: boolean;
};

export const ClientModal: FC<ClientModalProps> = ({
  client,
  onDone,
  smallIconButton,
  iconButton,
  hideButton = false,
  outlinedButton,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const { user, isCommercial, commercial } = useAuth();
  const countries = useCountries();
  const [open, setIsOpen] = useState(false);
  const [isOTPMOdalOpen, setOTPModalOpen] = useState(false);
  const [isUserValidated, setUserValidated] = useState(false);
  const [t] = useTranslation("global");
  const [tClient] = useTranslation("clients-page");
  const [isLoading, setLoading] = useState(false);
  const referenceOTP = db.collection("Properties").doc("OTP");

  const isEdit = !!client?.id;
  const record = client || {};

  const methods = useForm<any>({ defaultValues: record });
  const commercialId = client?.commercialId
    ? client.commercialId
    : isCommercial
    ? commercial?.id
    : undefined;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    if (open) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);
  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      values.stage = values?.stage ? values?.stage : undefined;
      const doc = await referenceOTP.get();
      const isOtpRequired = doc.exists
        ? doc.data()?.otp_suppliers_client
        : false;

      let savedClient;
      if (isEdit) {
        savedClient = await updateSeller(client.id!, {
          ...values,
          commercialId,
        });
      } else if (isOtpRequired && isUserValidated) {
        savedClient = await createSeller({
          ...values,
          supplierId: user.id,
          commercialId,
        } as any);
      } else if (isOtpRequired) {
        setOTPModalOpen(true);
        return;
      } else {
        savedClient = await createSeller({
          ...values,
          supplierId: user.id,
          commercialId,
        } as any);
      }
      showToastSuccess(t("CLIENTS.CLIENT_CREATED"));
      onDone?.(savedClient);
      setIsOpen(false);
      methods.reset(record);
    } catch (error) {
      setUserValidated(false);
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  const countryOptions = countries?.map(c => ({
    label: `${c.emoji} ${c.name}`,
    value: `+${c.phoneCode}`,
  }));

  return (
    <>
      {!hideButton && (
        <>
          {!smallIconButton && (
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className={clsx(
                { ["primary"]: !outlinedButton, ["outlined"]: outlinedButton },
                styles.modalTriggerButton
              )}
            >
              {!smallIconButton && !iconButton && !isSmallScreen && (
                <span className={styles.responsive}>
                  {t(client ? "CLIENTS.EDIT_CLIENT" : "CLIENTS.NEW_CLIENT")}
                </span>
              )}
              {!smallIconButton && (iconButton || isSmallScreen) && (
                <span>
                  {client ? (
                    <AiOutlineEdit size={"1.0rem"} />
                  ) : (
                    <AiOutlineUserAdd size={"1.1rem"} />
                  )}
                </span>
              )}
            </button>
          )}
          {smallIconButton && (
            <ToolTip
              title={t(client ? "CLIENTS.EDIT_CLIENT" : "CLIENTS.NEW_CLIENT")}
              position="Left"
            >
              <span
                className="pointer circleButton"
                onClick={() => setIsOpen(true)}
              >
                {client ? <MdOutlineEdit /> : <span className="bold">+</span>}
              </span>
            </ToolTip>
          )}
        </>
      )}
      <FormProvider {...methods}>
        {open && (
          <Modal
            isOpen={open}
            onClose={() => {
              setIsOpen(false);
              methods.reset(record);
            }}
            title={t(client ? "CLIENTS.EDIT_CLIENT" : "CLIENTS.NEW_CLIENT")}
            footer={
              <button
                type="button"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
                className="primary lg"
              >
                {!isLoading && t("SAVE")} {isLoading && <Spinner />}
              </button>
            }
          >
            <div className={styles.formGrid}>
              <InputV2 name="legalName" label={t("CLIENTS.LEGAL_NAME")} />
              <InputV2
                name="email"
                validate={isValidEmail}
                label={t("CLIENTS.EMAIL")}
              />

              <InputV2
                name="firstName"
                label={t(isUSA ? "CLIENTS.CONTACT_NAME" : "CLIENTS.NAME")}
              />
              {!isUSA && (
                <InputV2 name="lastName" label={t("CLIENTS.LAST_NAME")} />
              )}

              <div>
                <p>{t("COUNTRY")}</p>
                <Select
                  isSearchable
                  name="phonePrefix"
                  defaultValue={countryOptions?.find(
                    ({ value }) => client?.phonePrefix == value
                  )}
                  placeholder={t("SELECT_OPTION")}
                  required
                  options={countryOptions}
                  styles={singleSelectStyles}
                  isDisabled={!countries?.length}
                  onChange={({ value = "" }: any) => {
                    methods.setValue("phonePrefix", value);
                  }}
                />
              </div>
              <div className="flexGap">
                <div className="col-2 noPadding">
                  <InputV2
                    name="phonePrefix"
                    label={t("SIGNUP.CONTACT_PHONE_PREFIX")}
                    disabled
                  />
                </div>
                <div className="col-10 noPadding">
                  <InputV2
                    name="phone"
                    label={t("CLIENTS.PHONE")}
                    onKeyPress={validateOnlyNumber}
                    maxLength={11}
                    minLength={10}
                  />
                </div>
              </div>
              <DocumentTypeInput documentType={client?.documentType} />
              <InputV2
                name="documentId"
                label={t("CLIENTS.DOC_NUMBER")}
                type="text"
              />
              <SelectV2
                name="stage"
                label={t("STATUS")}
                choices={[
                  "CLIENT",
                  "CONTACTED",
                  "DISQUALIFIED_PROSPECT",
                  "IN_NEGOTIATION",
                  "LOST",
                  "PENDING_DECISION",
                  "PROSPECT",
                  "QUALIFIED_PROSPECT",
                ].map(s => ({ label: tClient("STAGE_STATUS." + s), value: s }))}
                allowEmpty
              />
            </div>
            {
              <div className="flex flexGap flexColumn">
                <RecordCustomAttributes appliesTo="SELLERS" forForm />
                <div className="spacer" />
              </div>
            }
            <ClientOTPModal
              isOTPMOdalOpen={isOTPMOdalOpen}
              setOTPModalOpen={setOTPModalOpen}
              isUserValidated={isUserValidated}
              setUserValidated={setUserValidated}
              onSubmit={onSubmit}
            />
          </Modal>
        )}
      </FormProvider>
    </>
  );
};
