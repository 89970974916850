import { ClientName } from "@/components/offers/_form/ClientNameChip";
import { CommercialResume } from "@/components/shared/Commercials/CommercialResume/CommercialResume";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { getSupplierCommercialById, getSupplierCommercials } from "@/http";
import { useCommercialsVisits } from "@/store/commercials-visit";
import { format } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./styles.module.scss";
import ReactPaginate from "react-paginate";
import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { FormProvider, useForm } from "react-hook-form";
import { CommercialVisitDetail } from "./CommercialVisitDetail";

export const CommercialVisitsList = () => {
  const [t] = useTranslation("global");
  const {
    commercialsVisits,
    currentPage,
    totalPages,
    setCurrentPage,
    getCommercialsVisits,
    commercial,
  } = useCommercialsVisits();

  useEffect(() => {
    getCommercialsVisits();
  }, [currentPage, commercial]);

  return (
    <>
      <CommercialSelection />
      {commercialsVisits?.length > 0 ? (
        <div className="tableScroll">
          <table className={styles.table}>
            <thead className={styles.purpleHead}>
              <tr>
                <th>{t("CLIENT_NAME")}</th>
                <th>{t("VISIT_DATE")}</th>
                <th>{t("COMMERCIAL_NAME")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {commercialsVisits.map((c: any) => (
                <tr key={c?.id} className={styles.row}>
                  <td>
                    <ClientName id={c.clientId} isBold />
                  </td>
                  <td>
                    {format(
                      new Date(c?.createdAt),
                      isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                    )}
                  </td>
                  <td>
                    <CommercialDetail id={c?.commercialId} />
                  </td>
                  <td>
                    <CommercialVisitDetail visit={c} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t("NO_DATA")}</p>
      )}
      {totalPages > 1 && (
        <div className={styles.paginate}>
          <ReactPaginate
            forcePage={currentPage}
            onPageChange={p => setCurrentPage(p.selected)}
            pageCount={totalPages}
            previousLabel="←"
            nextLabel="→"
            marginPagesDisplayed={3}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.previous}
            nextLinkClassName={styles.next}
            disabledClassName={styles.disabled}
            activeClassName={styles.selected}
            breakClassName={styles.break}
          />
        </div>
      )}
    </>
  );
};

const CommercialSelection = () => {
  const { setCommercial } = useCommercialsVisits();
  const methods = useForm();
  const [t] = useTranslation("global");
  const { data } = useSWR([{ size: 1000 }], getSupplierCommercials);

  return (
    <FormProvider {...methods}>
      <div className="flexGap center">
        <span className="mbmd">{t("COMMERCIAL_NAME")}</span>
        <Autocomplete
          inputName="commercialName"
          inputLabel=""
          inputPlaceholder={t("ALL_COMMERCIAL")}
          action={commercial => {
            methods.setValue("commercial", commercial);
            methods.setValue("commercialName", commercial?.name);
            setCommercial(commercial);
          }}
          choices={(data?.content ?? []).map(c => ({
            ...c,
            id: c.id,
            name: c.firstName + " " + c.lastName,
          }))}
        />
      </div>
    </FormProvider>
  );
};

export const CommercialDetail = ({ id }: { id: string }) => {
  const { data: commercial } = useSWR(
    id ? [id, "getCommercialById"] : null,
    getSupplierCommercialById
  );

  if (!commercial) {
    return null;
  }

  return (
    <>
      <CommercialResume commercial={commercial} />
    </>
  );
};
