import { ADMIN_ROLE, TSupplierRole } from "@/http";

export const hasPermission = (
  isCommercial: boolean,
  role: TSupplierRole | null,
  requiredPermissions: string[]
) => {
  if (role?.name === ADMIN_ROLE) {
    return true;
  }

  if (!isCommercial) {
    return true;
  }

  if (!requiredPermissions.length) {
    return true;
  }

  return requiredPermissions.every(permission =>
    role?.permissions?.includes(permission)
  );
};

export const isAdmin = (isCommercial: boolean, role: TSupplierRole | null) => {
  if (!isCommercial) {
    return true;
  }

  if (role?.name === ADMIN_ROLE) {
    return true;
  }

  return false;
};
