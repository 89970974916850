import { parseISO } from "date-fns";

const mapOfferPayload = (values: any, strategy: "DISCOUNT" | "PRODUCTS") => {
  const basePayload = {
    name: values.name,
    stackable: values.stackable,
    priority: values.couponCode ? 0 : values.priority,
    startsAt: parseISO(values.startsAt).toISOString(),
    endsAt: parseISO(values.endsAt).toISOString(),
    type: values.type,
    targetSegments: values.targetSegments,
    maximumUses: values.maxUsesOffer ? values.maximumUses : undefined,
    maximumUsesByUser: values.maxUsesClient
      ? values.maximumUsesByUser
      : undefined,
    maximumUsesByPurchase: values.maxUsesPurchase
      ? values.maximumUsesByPurchase
      : undefined,
    conditionValue: values.conditionValue,
    appliedValue: values.appliedValue,
    couponCode: values.couponCode ? values.couponCode : undefined,
  };

  if (strategy === "DISCOUNT") {
    return {
      ...basePayload,
      strategy: "DISCOUNT",
      condition: values.condition,
      targetValues: values.targetValues,
      targetType: values.targetType,
    };
  }

  if (strategy === "PRODUCTS") {
    return {
      ...basePayload,
      strategy: "PRODUCTS",
      condition: "QUANTITY",
      appliedProducts: (values.appliedProducts || []).map((p: any) => ({
        id: p?.id ?? "",
        value: p?.value ?? "",
      })),
      conditionProducts: (values.conditionProducts || []).map((p: any) => ({
        id: p?.id ?? "",
        value: p?.value ?? "",
      })),
    };
  }

  return basePayload;
};

export const mapDiscountOfferPayload = (values: any) => {
  return mapOfferPayload(values, "DISCOUNT");
};

export const mapProductsOfferPayload = (values: any) => {
  return mapOfferPayload(values, "PRODUCTS");
};
