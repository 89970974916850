import ProductImage from "@/components/layout/ProductImage";
import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { ProductName } from "@/components/shared/Details";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { SingleSelectionProduct } from "@/components/shared/Input/SingleSelectionProduct";
import { TextArea } from "@/components/shared/Input/TextArea";
import { SellerInputSearch } from "@/components/shared/Sellers/SellerInputSearch/SellerInputSearch";
import { showToastError } from "@/hooks/useToast";
import { createTicket } from "@/http";
import { CommercialSelection } from "@/pages/Clients/ClientList";
import { uploadImageTicketObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TicketCreatedModal } from "./TicketCreatedModal";

export const TicketTypeCreationForm = () => {
  const [isLoading, setLoading] = useState(false);
  const methods = useForm();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const files = methods.watch("files") || [];
  const { type } = useParams();
  const navigate = useNavigate();
  const isProducts = type === "PRODUCTS_TITLE";
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState<any>(null);

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);

      if (!files?.length) {
        showToastError({ message: t("ADD_EVIDENCE") });
        return;
      }

      const payload: any = {
        sellerId: values.sellerId,
        attachments: [],
        type: {
          OTHERS: "OTHER",
          PRODUCTS_TITLE: "PRODUCTS",
        }[type ?? ""],
        description: values.reason,
        commercialId: values?.commercialId ? values?.commercialId : undefined,
        requirement: values.type,
      };

      if (isProducts) {
        payload.products = values.products;
      } else {
        payload.subject = values.subject;
      }

      if (files.length) {
        await Promise.all(files?.map(uploadImageTicketObject)).then(images => {
          payload.attachments = images;
        });
      }

      const data = await createTicket(payload);
      methods.reset();
      setIsOpen(true);
      setTicket(data);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="flexGap flexSmColumn">
          <div className="col-6 col-sm-12">
            <p className="bold">{t("CLIENT_NAME")}</p>
            <SellerInputSearch />

            {isProducts ? (
              <>
                <SingleSelectionProduct
                  value={""}
                  name="products[0].id"
                  required
                  bold
                />
                {methods.watch("products[0].id") && (
                  <div className="purpleBackground mtmd pw1 ph1 flexGap center bold">
                    <ProductImage id={methods.watch("products[0].id")} />
                    <ProductName id={methods.watch("products[0].id")} />
                  </div>
                )}
                <div className="spacer" />
                <InputV2
                  name="products[0].serials[0]"
                  label={<span className="bold">{t("SERIAL_OPTIONAL")}</span>}
                />
              </>
            ) : (
              <InputV2
                name="subject"
                label={<span className="bold">{t("SUBJECT")}</span>}
                required
              />
            )}

            <TextArea
              name="reason"
              label={<span className="bold">{t("TELL_US_WHAT_HAPPEN")}</span>}
              rows={6}
              required
            />
          </div>
          <div className="dividerVertical" />
          <div className="col-6 col-sm-12">
            <CommercialSelection
              values={null}
              onValueChange={(item: any) =>
                methods.setValue("commercialId", item?.value ?? "")
              }
              label={
                <span className="bold">{t("COMMERCIAL_RESPONSIBLE")}</span>
              }
            />
            <div className="spacer" />

            <p className="bold">{tGlobal("UPLOAD_FILE")}</p>
            <DragAndDropFile
              minifiedStyle
              accept="image/png,image/jpeg"
              files={files}
              setFiles={files => methods.setValue("files", files)}
            />
            {files.length > 0 && (
              <>
                <div className="spacer" />
                <p className="bold">{t("EVIDENCE")}</p>
              </>
            )}
            <ImagesList
              images={files}
              setImages={files => methods.setValue("files", files)}
              showName
            />
          </div>
        </div>
      </FormProvider>
      <div className="spacer" />
      <div className="spacer" />
      <div className="rigthAlign">
        <button
          disabled={isLoading}
          onClick={() => {
            navigate("/tickets");
          }}
          className="lg outlined"
        >
          {t("CANCEL")}
        </button>
        <button
          className="primary lg"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {t("CREATE_TICKET")}
        </button>
      </div>
      {isOpen && ticket && (
        <TicketCreatedModal isOpen={isOpen} ticket={ticket} />
      )}
    </>
  );
};
