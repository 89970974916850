import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  getProductById,
  QUOTED,
  TPurchase,
  updatePurchaseProducts,
} from "@/http";
import { useAuth } from "@/store/auth";
import { isFinishedPurchase } from "@/utils/actions-disabled";
import { formatCurrency } from "@/utils/currency";
import {
  getProductSupplierFromProduct,
  groupByPending,
} from "@/utils/products";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductInput } from "../../PurchaseCreateForm/Products/ProductInput";
import styles from "./styles.module.scss";
import { usePurchase } from "@/store/purchase";
import { useSupplierById } from "@/hooks/useSupplier";

export type Props = {
  purchase: TPurchase;
};
export const UpdateProducts = ({ purchase }: Props) => {
  const { getPurchase } = usePurchase();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();
  const methods = useForm();
  const pendingProducts = methods.watch("products");
  const quantities = methods.watch("quantities");
  const isQuotation = purchase.status === QUOTED;
  const pendingProductsMapped =
    groupByPending(purchase?.products)["PENDING"] || [];
  const supplier = useSupplierById(purchase?.supplierId ?? "");
  const isStockIn = supplier?.parentSupplierId === user.id;

  const purchaseProducts =
    purchase?.supplierId === user.id
      ? pendingProductsMapped.filter(pp => pp.supplierId === user.id)
      : purchase?.products;

  const usedProducts = purchase?.products?.filter(
    pp => !purchaseProducts?.find(p => pp.id === p.id)
  );

  const mapProducts = async () => {
    try {
      setIsLoading(true);
      const pendingProductsData = await Promise.all(
        purchaseProducts?.map(async (pp: any) => {
          const product = await getProductById(pp.id);
          const supplierProduct = getProductSupplierFromProduct(
            product,
            pp.supplierId
          );

          return {
            supplierPrice: pp?.supplierPrice ?? null,
            availableStock: supplierProduct?.availableStock ?? 0,
            name: `${product.name} | ${formatCurrency(
              pp?.supplierPrice ?? 0
            )} x ${t("UNIT")}`,
            id: product.id,
            supplierId: supplierProduct.supplierId,
            locked: true,
            reservedQuantity: pp.quantity,
            type: pp.type || product.type,
            unitType: pp?.unitType ?? "-",
            unitRate: pp?.unitRate ? Number(pp?.unitRate) : 1,
          };
        })
      );

      const quantities = purchaseProducts.map(({ quantity }: any) => ({
        quantity,
      }));

      methods.setValue("products", pendingProductsData);
      methods.setValue("quantities", quantities);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  const addProduct = () => {
    methods.setValue("products", [
      ...pendingProducts,
      { id: crypto.randomUUID() },
    ]);
    methods.setValue("quantities", [...quantities, { quantity: 0 }]);
  };

  const onDelete = (index: number) => {
    methods.setValue(
      "products",
      pendingProducts.filter((_: any, idx: number) => idx !== index)
    );
    methods.setValue(
      "quantities",
      quantities.filter((_: any, idx: number) => idx !== index)
    );
    if (methods.formState.isSubmitted) {
      methods.trigger("products");
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      const { products: valuesProducts = [], quantities = [] } = values;

      const products = valuesProducts.map((p: any, idx: number) => ({
        ...p,
        quantity: quantities?.[idx]?.quantity,
      }));
      await updatePurchaseProducts(purchase.id, {
        products: [...products, ...usedProducts],
        recalculate: true,
      });
      getPurchase(purchase.id);
      methods.reset();
      setIsOpen(false);
      showToastSuccess(t("PURCHASE_UPDATED"));
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (
    isFinishedPurchase(purchase) ||
    purchase?.deliveryStatus === "DELIVERED"
  ) {
    return null;
  }

  return (
    <>
      <div>
        <button
          className="primary"
          onClick={() => {
            setIsOpen(true);
            mapProducts();
          }}
        >
          {t("UPDATE_PRODUCTS")}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          methods.reset();
          setIsOpen(false);
        }}
        title={t("UPDATE_PRODUCTS")}
        footer={
          <>
            <button
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
              disabled={isSubmitting}
            >
              {t("CANCEL")}
            </button>
            {pendingProducts?.length > 0 && (
              <button
                className="primary lg ml1"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                {t("UPDATE")}
              </button>
            )}
          </>
        }
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.productsContainer}>
              <FormProvider {...methods}>
                {pendingProducts?.map((pp: any, idx: any) => (
                  <div key={pp?.id} className="col-12 noPadding">
                    <input
                      type="hidden"
                      {...methods.register(`products.${idx}.type`)}
                      value={pp.type}
                    />
                    <ProductInput
                      index={idx}
                      canDelete
                      source=""
                      onDelete={onDelete}
                      supplierId={isStockIn ? user.id : purchase?.supplierId}
                      isQuote={isQuotation}
                      modifyPrice
                      reservedQuantity={pp?.reservedQuantity ?? 0}
                    />
                    {idx !== pendingProducts?.length - 1 && (
                      <div className="divider col-12 noPadding" />
                    )}
                  </div>
                ))}
              </FormProvider>
              <div className="spacer col-12 noPadding" />

              <button
                className="primary"
                onClick={addProduct}
                disabled={isSubmitting}
              >
                + {t("ADD_PRODUCT")}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
