import { Card } from "@/components/shared/Card/Card";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { TicketTypeCreationForm } from "@/components/tickets/TicketList/TicketCreation/TicketTypeCreationForm";
import { FEAT_TICKETS } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const TicketTypeCreatePage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("purchases");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("CREATE_TICKET"), "/tickets", undefined, undefined, true);
  }, [t]);

  if (!hasFeature(FEAT_TICKETS)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <Card className="noPadding">
      <p className="bold pw1 phmd purpleBackground">{t("CREATE_TICKET")}</p>
      <div className="pw1 ph1">
        <TicketTypeCreationForm />
      </div>
    </Card>
  );
};
