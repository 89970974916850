import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Images.module.scss";

type TImage = { image: any; removeImage?: (e: any, vlaue: string) => void };
export const Image = ({ image, removeImage }: TImage) => {
  const [img, setImg] = useState<any>("");
  if (image.name) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      setImg(reader.result);
    };
  }
  return (
    <div className={styles.imageItem}>
      <div className={styles.img}>
        {img ? (
          <img alt={`img - ${image.name}`} src={img} />
        ) : image?.src ? (
          <img alt={"img"} src={image.src} />
        ) : (
          <Spinner />
        )}
      </div>
      {removeImage != undefined && (
        <button onClick={e => removeImage(e, image.name || image.src)}>
          x
        </button>
      )}
    </div>
  );
};

type TPreviewFile = {
  file: any;
  removeFile?: (e: any, vlaue: string) => void;
  onClick?: (...args: any) => void;
  showName?: boolean;
};
export const PreviewFile = ({
  file,
  removeFile,
  onClick,
  showName = false,
}: TPreviewFile) => {
  const [preview, setPreview] = useState<any>("");
  const [t] = useTranslation("global");
  const allowedExtensions = ["png", "jpg", "jpeg", "webp"];

  if (file.name) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  const renderPreview = () => {
    if (!preview && !file?.src) {
      return <Spinner />;
    }

    if (
      file.type?.includes("image") ||
      file?.mimeType?.includes("image") ||
      allowedExtensions.some(ext =>
        file?.src?.toLowerCase().endsWith(`.${ext}`)
      )
    ) {
      return (
        <img alt={`img - ${file.name || file.src}`} src={preview || file.src} />
      );
    } else if (
      file.type?.includes("video") ||
      file?.mimeType?.includes("video")
    ) {
      return (
        <video style={{ maxWidth: "100%", height: "auto" }}>
          <source src={preview || file.src} type={file.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (
      file.type === "application/pdf" ||
      file?.mimeType === "application/pdf" ||
      file?.src?.endsWith(".pdf")
    ) {
      return <img src="/pdf.png" alt={file.name || "pdf"} />;
    } else {
      return <p>{t("FILE_NOT_SUPPORTED")}</p>;
    }
  };

  return (
    <div className={styles.imageItem}>
      <div className={styles.img} onClick={onClick}>
        {renderPreview()}
        {showName && (
          <div className="smFont textCenter">
            {formatFileName(file?.name || file.src || "")}
          </div>
        )}
      </div>
      {removeFile && (
        <button onClick={e => removeFile(e, file.name || file.src)}>x</button>
      )}
    </div>
  );
};

export const formatFileName = (filename = "") => {
  const name = filename.split("/").pop() ?? "";
  return name.length > 8 ? `${name.slice(0, 5)}...${name.slice(-6)}` : filename;
};
