import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { SellerInputSearch } from "@/components/shared/Sellers/SellerInputSearch/SellerInputSearch";
import { DATE_TIME } from "@/constants/date";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TSupplierEvent } from "@/http";
import {
  createSupplierEvent,
  updateSupplierEvent,
} from "@/http/resources/suppliers/events";
import { validateTimezoneDate } from "@/utils/date";
import { uploadEventObject } from "@/utils/image-upload";
import { addHours, format, isAfter, parseISO } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const ClientModalEvent = ({
  isOpen,
  onClose,
  onDone,
  type,
  event,
  requireClient = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  type: string;
  event?: TSupplierEvent;
  requireClient?: boolean;
}) => {
  const isEdit = !!event?.id;
  const [t] = useTranslation("global");
  const { id: clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState<any[]>(
    isEdit && event.attachment ? [{ src: event.attachment }] : []
  );
  const defaultValues = isEdit
    ? {
        ...event,
        scheduledAt: format(
          new Date(validateTimezoneDate(event.scheduledAt)),
          DATE_TIME
        ),
        addReminder: !!event.rememberHours,
      }
    : { addReminder: false, scheduledAt: "" };
  const methods = useForm({ defaultValues });

  const eventDate = methods.watch("scheduledAt");

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (images?.length > 0) {
        const image = await uploadEventObject(images[0]);
        values.attachment = image?.src;
      } else {
        values.attachment = null;
      }

      if (!values.addReminder) {
        values.rememberHours = 0;
      }

      values.scheduledAt = parseISO(values.scheduledAt).toISOString();

      if (isEdit) {
        await updateSupplierEvent(event?.id ?? "", { ...values });
      } else {
        await createSupplierEvent({
          ...values,
          type,
          clientId: requireClient ? values.sellerId : clientId,
        });
      }
      showToastSuccess(t(isEdit ? "EVENT_UPDATED" : "EVENT_CREATED"));
      methods.reset();
      onDone?.();
      onClose?.();
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      title={t("EVENT_TITLE_" + (isEdit ? event?.type ?? "" : type))}
      footer={
        <>
          <button onClick={onClose} disabled={isLoading}>
            {t("CANCEL")}
          </button>
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t(isEdit ? "SAVE" : "CREATE")}
          </button>
        </>
      }
    >
      <FormProvider {...methods}>
        <div className="flexGap flexSmColumn grayContainer">
          <div className="col-6 col-sm-12 noPadding">
            <InputV2
              type="datetime-local"
              name="scheduledAt"
              label={t("EVENT_SCHEDULED")}
              required
            />
          </div>
          <div className="dividerVertical " />

          <div className="col-6 col-sm-12 noPadding">
            <div className="spacer" />
            {eventDate &&
              isAfter(parseISO(eventDate), addHours(new Date(), 2)) && (
                <>
                  <Checkbox name="addReminder" label={t("ADD_REMINDER")} />
                  {methods.watch("addReminder") && (
                    <SelectV2
                      name="rememberHours"
                      label={t("REMEMBER_HOURS")}
                      required
                      choices={[
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "3", label: "3" },
                        { value: "6", label: "6" },
                        { value: "12", label: "12" },
                        { value: "24", label: "24" },
                      ]}
                    />
                  )}
                </>
              )}
          </div>
        </div>
        <div className="spacer" />
        <div className="pw1">
          {requireClient && (
            <>
              <p>{t("CLIENT")}</p>
              <SellerInputSearch />
              <div className="spacer" />
            </>
          )}
          <InputV2 name="title" label={t("EVENT_SUBJECT")} required />
          <TextArea
            name="description"
            label={t("DESCRIPTION")}
            required
            rows={6}
          />
          <p>{t("ATTACHMENT")}</p>
          {images?.length < 1 ? (
            <DragAndDropFile
              minifiedStyle
              accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
              files={images}
              setFiles={setImages}
            />
          ) : (
            <ImagesList images={images} setImages={setImages} showName />
          )}
        </div>
      </FormProvider>
    </Modal>
  );
};
