import React, { useRef, useEffect } from "react";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import {
  Schema,
  DOMParser as ProseMirrorDOMParser,
  DOMSerializer,
} from "prosemirror-model";
import { setBlockType, toggleMark } from "prosemirror-commands";
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import "prosemirror-view/style/prosemirror.css";
import {
  BsParagraph,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeUnderline,
} from "react-icons/bs";
import { MdOutlineFormatItalic } from "react-icons/md";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa";
import styles from "./styles.module.scss";

const schema = new Schema({
  nodes: {
    doc: { content: "block+" },
    paragraph: {
      attrs: { align: { default: "left" }, fontSize: { default: "16px" } },
      group: "block",
      content: "inline*",
      toDOM: node => [
        "p",
        {
          style: `text-align: ${node.attrs.align}; font-size: ${node.attrs.fontSize}`,
        },
        0,
      ],
      parseDOM: [
        {
          tag: "p",
          getAttrs: (dom: HTMLElement) => ({
            align: dom.style.textAlign || "left",
            fontSize: dom.style.fontSize || "16px",
          }),
        },
      ],
    },
    heading: {
      attrs: {
        level: { default: 1 },
        align: { default: "left" },
        fontSize: { default: "24px" },
      },
      group: "block",
      content: "inline*",
      defining: true,
      toDOM: node => [
        "h" + node.attrs.level,
        {
          style: `text-align: ${node.attrs.align}; font-size: ${node.attrs.fontSize}`,
        },
        0,
      ],
      parseDOM: [
        {
          tag: "h1, h2, h3",
          getAttrs: (dom: HTMLElement) => {
            return {
              level: parseInt(dom.tagName[1]),
              align: dom.style.textAlign || "left",
              fontSize:
                dom.style.fontSize || (dom.tagName === "H1" ? "32px" : "24px"),
            };
          },
        },
      ],
    },
    text: { group: "inline" },
  },
  marks: {
    bold: { parseDOM: [{ tag: "strong" }], toDOM: () => ["strong"] },
    italic: { parseDOM: [{ tag: "em" }], toDOM: () => ["em"] },
    underline: { parseDOM: [{ tag: "u" }], toDOM: () => ["u"] },
  },
});

interface HTMLEditorProps {
  initialHTML?: string;
  onValueChange: (value: string) => void;
}

export const HTMLEditor: React.FC<HTMLEditorProps> = ({
  initialHTML = "",
  onValueChange,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const viewRef = useRef<EditorView | null>(null);

  useEffect(() => {
    if (!editorRef.current) return;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = initialHTML;

    const doc = ProseMirrorDOMParser.fromSchema(schema).parse(tempDiv);

    const state = EditorState.create({
      doc,
      schema,
      plugins: [
        keymap({
          "Shift-Enter": (state, dispatch) => {
            if (dispatch) {
              dispatch(
                state.tr.replaceSelectionWith(schema.nodes.hard_break.create())
              );
            }
            return true;
          },
          ...baseKeymap,
        }),
      ],
    });

    const view = new EditorView(editorRef.current, {
      state,
      dispatchTransaction: transaction => {
        const newState = view.state.apply(transaction);
        view.updateState(newState);

        const output = DOMSerializer.fromSchema(schema).serializeFragment(
          newState.doc.content
        );
        const container = document.createElement("div");
        container.appendChild(output);
        onValueChange(container.innerHTML);
      },
      attributes: {
        class: styles.noBorder,
      },
    });

    viewRef.current = view;

    return () => {
      view.destroy();
    };
  }, [initialHTML, onValueChange]);

  const applyCommand = (
    command: (state: EditorState, dispatch?: any) => boolean
  ) => {
    const view = viewRef.current;
    if (view) {
      command(view.state, view.dispatch);
      view.focus();
    }
  };

  const setFontSize = (fontSize: any) => {
    const view = viewRef.current;
    if (view) {
      const { state, dispatch } = view;
      const { $from } = state.selection;
      const nodeType = $from.parent.type;

      dispatch(
        state.tr.setNodeMarkup($from.before(), nodeType, {
          ...$from.parent.attrs,
          fontSize,
        })
      );
      view.focus();
    }
  };

  const setAlignment = (align: string) => {
    const view = viewRef.current;
    if (view) {
      const { state, dispatch } = view;
      const { $from } = state.selection;
      const applicableNodes = ["paragraph", "heading"];
      const nodeType = applicableNodes.includes($from.parent.type.name)
        ? $from.parent.type
        : null;

      if (nodeType) {
        dispatch(
          state.tr.setNodeMarkup($from.before(), nodeType, {
            ...$from.parent.attrs,
            align,
          })
        );
        view.focus();
      }
    }
  };

  return (
    <div>
      <div className={styles.toolsBar}>
        <button onClick={() => applyCommand(toggleMark(schema.marks.bold))}>
          <BsTypeBold />
        </button>
        <button onClick={() => applyCommand(toggleMark(schema.marks.italic))}>
          <MdOutlineFormatItalic />
        </button>
        <button
          onClick={() => applyCommand(toggleMark(schema.marks.underline))}
        >
          <BsTypeUnderline />
        </button>
        <button
          onClick={() =>
            applyCommand(setBlockType(schema.nodes.heading, { level: 1 }))
          }
        >
          <BsTypeH1 />
        </button>
        <button
          onClick={() =>
            applyCommand(setBlockType(schema.nodes.heading, { level: 2 }))
          }
        >
          <BsTypeH2 />
        </button>
        <button
          onClick={() => applyCommand(setBlockType(schema.nodes.paragraph, {}))}
        >
          <BsParagraph />
        </button>
        <button onClick={() => setAlignment("left")}>
          <FaAlignLeft />
        </button>
        <button onClick={() => setAlignment("center")}>
          <FaAlignCenter />
        </button>
        <button onClick={() => setAlignment("right")}>
          <FaAlignRight />
        </button>
        <button onClick={() => setFontSize("14px")}>14px</button>
        <button onClick={() => setFontSize("16px")}>16px</button>
        <button onClick={() => setFontSize("18px")}>18px</button>
        <button onClick={() => setFontSize("24px")}>24px</button>
        <button onClick={() => setFontSize("32px")}>32px</button>
      </div>

      <div ref={editorRef} className={styles.editor} />
    </div>
  );
};
