import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { isUSA } from "@/constants/core";
import { FaArrowRightLong } from "react-icons/fa6";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { getTicketsMovements, TTicketDetail } from "@/http";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { CommercialResume } from "@/components/shared/Commercials/CommercialResume/CommercialResume";
import { useCommercialById } from "@/hooks/useSupplier";
import { useEffect } from "react";

export const TicketHistory = ({ ticket }: { ticket: TTicketDetail }) => {
  const [t] = useTranslation("global");
  const [search] = useSearchParams("pageHistory=0");
  const page = +search.get("pageHistory")!;

  const params: any = {
    size: 10,
    page,
    sort: "createdAt,desc",
  };

  const swrData = useSWR(
    [ticket.id, params, "tickets-movements"],
    getTicketsMovements
  );
  const { error } = swrData;
  const movements = get(swrData, "data.content", []);

  const movementComponents: Record<string, React.FC<{ data: any }>> = {
    COMMERCIAL: CommercialMovement,
    ATTACHMENTS: AttachmentMovement,
    STATUS: StatusMovement,
  };

  useEffect(() => {
    if (ticket) {
      swrData.mutate();
    }
  }, [ticket]);

  if (error) {
    return null;
  }

  return (
    <div>
      <PaginatedTable swrData={swrData} paginationKey="pageHistory">
        <div className="flexGap flexColumn">
          {movements.map(m => {
            const MovementComponent = movementComponents[m?.type];
            return MovementComponent ? (
              <div key={m?.id} className="grayContainer">
                <MovementComponent data={m} />
              </div>
            ) : null;
          })}
        </div>
      </PaginatedTable>
    </div>
  );
};

const AttachmentMovement = ({ data }: { data: any }) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="bold mbmd contentInline">
        <span>{t("ATACHMENT_CHANGE")}</span>
        <DateFormated date={data?.createdAt} />
      </div>
    </>
  );
};

const CommercialMovement = ({ data }: { data: any }) => {
  const [t] = useTranslation("global");
  const previous = useCommercialById(data?.previous);
  const current = useCommercialById(data?.current);

  return (
    <>
      <div className="bold mbmd contentInline">
        <span>{t("RESPONSIBLE_CHANGE")}</span>
        <DateFormated date={data?.createdAt} />
      </div>
      <div className="spacer" />
      <div className="contentInline">
        <CommercialResume commercial={previous} />
        <FaArrowRightLong />
        <CommercialResume commercial={current} />
      </div>
    </>
  );
};

const StatusMovement = ({ data }: { data: any }) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="bold mbmd contentInline">
        <span>{t("STATUS_CHANGE")}</span>
        <DateFormated date={data?.createdAt} />
      </div>
      <div className="contentInline">
        <span> {t(data?.previous)}</span>
        <FaArrowRightLong />
        <span> {t(data?.current)}</span>
      </div>
    </>
  );
};

const DateFormated = ({ date }: { date: string }) => {
  return (
    <span className="mdFont">
      {format(new Date(date), isUSA ? USA_DATE_HOUR : DATE_HOUR)}
    </span>
  );
};
