import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { TextArea } from "@/components/shared/Input/TextArea";
import { showToastError } from "@/hooks/useToast";
import { addTicketComment, TTicketDetail } from "@/http";
import { uploadImageTicketObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const TicketCommentCreation = ({
  onDone,
  ticket,
}: {
  onDone: (...args: any) => void;
  ticket: TTicketDetail;
}) => {
  const methods = useForm();
  const files = methods.watch("files") || [];
  const [t] = useTranslation("global");

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const payload: any = {
        comment: values.comment,
        visibility: values.visibility ? "INTERNAL" : "EXTERNAL",
        sendToClient: values.visibility ? false : values?.sendToClient,
      };
      if (values?.files?.length) {
        await Promise.all(values?.files?.map(uploadImageTicketObject)).then(
          images => {
            payload.attachments = images;
          }
        );
      } else {
        payload.attachments = [];
      }
      await addTicketComment(ticket.id, payload);
      methods.reset({
        files: [],
        comment: "",
        visibility: false,
        sendToClient: false,
      });
      onDone();
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <TextArea rows={4} name="comment" label="" required />

      <Checkbox name="visibility" label={t("INTERNAL_COMMENT")} />
      {!methods.watch("visibility") && (
        <Checkbox name="sendToClient" label={t("REQUIRE_CLIENT_RESPONSE")} />
      )}
      {files.length > 0 && (
        <>
          <ImagesList
            images={files}
            setImages={files => methods.setValue("files", files)}
            showName
          />
          <div className="spacer" />
        </>
      )}
      <div className="contentInline">
        <div>
          <DragAndDropFile
            accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
            slim
            files={files}
            setFiles={files => methods.setValue("files", files)}
          />
        </div>
        <div>
          <button
            disabled={isLoading}
            onClick={() => methods.reset({ files: [], comment: "" })}
          >
            {t("CANCEL")}
          </button>
          <button
            className="primary md"
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t("SAVE")}
          </button>
        </div>
      </div>
    </FormProvider>
  );
};
