import { FC, ReactNode, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import styles from "./styles.module.scss";
import { createPortal } from "react-dom";
import clsx from "clsx";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  size?: string;
  children?: ReactNode;
  noScroll?: boolean;
  showOverflow?: boolean;
  title?: ReactNode;
  footer?: ReactNode;
};
export const Modal: FC<Props> = ({
  isOpen,
  children,
  onClose,
  size,
  noScroll,
  showOverflow,
  title,
  footer,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    if (isOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div className={styles.overlay}>
      <div
        className={clsx({
          [styles.containerSm]: size === "sm",
          [styles.containerXl]: size === "xl",
          [styles.container]: size !== "sm" && size !== "xl",
          [styles.noScroll]: noScroll,
          [styles.overflow]: showOverflow,
        })}
      >
        <div className={styles.borderTop} />
        <div className={styles.right}>
          <button
            className={styles.close}
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            <FaTimes />
          </button>

          <div className={styles.content}>
            {!!title && (
              <div className={styles.title}>
                <h6 className="bold">{title}</h6>
                <div className="divider" />
                <div className="spacer" />
              </div>
            )}
            <div
              className={clsx(styles.children, {
                ["showOverflow"]: showOverflow,
              })}
            >
              {children}
            </div>
            {!!footer && (
              <div className={styles.footer}>
                {!isSmallScreen ? (
                  <div className={styles.footer}>
                    <div className="divider" />
                    <div className="spacer" />
                    <div className="rigthAlign">{footer}</div>
                    <div className="spacer" />
                  </div>
                ) : (
                  <>
                    {createPortal(
                      <div className={clsx("rigthAlign", styles.footerContent)}>
                        {footer}
                      </div>,
                      document.body
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
