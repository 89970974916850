import ProductImage from "@/components/layout/ProductImage";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { TPurchase } from "@/http";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TTicketProducts = {
  purchase: TPurchase;
};
export const TicketProducts = ({ purchase }: TTicketProducts) => {
  const [t] = useTranslation("global");
  const methods = useFormContext();
  return (
    <>
      <p className="mbmd bold">{t("SELECT_THE_RETURN_PRODUCTS")}</p>
      <div className="flexGap flexColumn">
        {purchase.products.map((p, idx) => (
          <div
            key={p.id}
            className={clsx("flexGap center", styles.noMarginInput)}
          >
            <input
              type="hidden"
              value={p.id}
              {...methods.register(`products.[${idx}].id`)}
            />
            <Checkbox
              name={`products.[${idx}].selected`}
              label={
                <div className="flexGap center">
                  <ProductImage images={p?.images ?? []} />
                </div>
              }
            />
            <div className="mdFont flex1">
              <div className="contentInline">
                <span className="bold mdFont">{p.name}</span>
                <span className="rigthAlign">
                  {p.quantity} {t(p.quantity > 1 ? "UNITS" : "UNIT")}
                </span>
              </div>

              {methods.watch(`products.[${idx}].selected`) && (
                <>
                  {p.quantity > 1 ? (
                    <div className={clsx("contentInline center")}>
                      <span>{t("SELECT_QUANTITY_DAMAGED")}</span>
                      <div
                        className={clsx(
                          styles.quantityInput,
                          styles.noMarginInput
                        )}
                      >
                        <NumericInput
                          name={`products.[${idx}].quantity`}
                          label=""
                          decimals
                          required
                          max={p.quantity}
                          min={0.1}
                        />
                      </div>
                    </div>
                  ) : (
                    <input
                      type="hidden"
                      value={1}
                      {...methods.register(`products.[${idx}].quantity`)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
