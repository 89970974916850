import { useState, useEffect, ReactNode } from "react";
import Select from "react-select";
import { getSegments, TSegment } from "@/http";
import { useTranslation } from "react-i18next";
import { selectMultipleStyles } from "@/constants/input";
import useSWR from "swr";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import clsx from "clsx";

type TMultipleClientSegmentSelection = {
  disabled?: boolean;
  required?: boolean;
  infoLabel?: string;
  name: string;
  label?: ReactNode;
};

export const MultipleClientSegmentSelection = ({
  disabled,
  required,
  infoLabel,
  name,
  label,
}: TMultipleClientSegmentSelection) => {
  const [t] = useTranslation("global");
  const [options, setOptions] = useState<any[]>([]);
  const { data: segments = [] } = useSWR("segments", getSegments);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const formattedOptions = (segments || []).map((s: TSegment) => ({
      value: s.id,
      label: s.name,
    }));

    if (JSON.stringify(options) !== JSON.stringify(formattedOptions)) {
      setOptions(formattedOptions);
    }
  }, [segments, options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? t("FIELD_REQUIRED") : undefined }}
      render={({ field: { onChange, value } }) => (
        <div
          className={clsx(styles.field, {
            [styles.required]: get(errors, name),
          })}
        >
          {label && <p className={styles.label}>{label}</p>}
          <Select
            isMulti
            options={options}
            value={options?.filter(opt => value?.includes(opt.value))}
            onChange={selected =>
              onChange(selected.map((opt: any) => opt.value))
            }
            isDisabled={disabled}
            placeholder={t("SELECT_OPTION")}
            styles={selectMultipleStyles}
            noOptionsMessage={() => t("NO_OPTIONS")}
          />
          {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
          {!!get(errors, name) && (
            <span className={styles.errorsLabel}>
              {get(errors as any, name).message}
            </span>
          )}
        </div>
      )}
    />
  );
};
