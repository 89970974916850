import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const TicketCommentsFilter = () => {
  const [search, setSearch] = useSearchParams("commentType=");
  const { t } = useTranslation("global");

  const type = search.get("commentType")!;

  const onStatusChange = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("commentType", value);
    setSearch(newSearch);
  };

  return (
    <>
      <FilterTabs
        options={[
          { text: t("SEE_ALL"), value: "" },
          { text: t("INTERNAL"), value: "INTERNAL" },
          { text: t("EXTERNAL"), value: "EXTERNAL" },
        ]}
        currentFilter={type}
        action={onStatusChange}
      />
    </>
  );
};
