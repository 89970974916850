import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { validateTimezoneDate } from "@/utils/date";
import { validateDecimalNumber, validateOnlyNumber } from "@/utils/keyboard";
import { createOffer, updateOffer } from "@/http";
import { ProductGiftArrayInput } from "./ProductGiftArrayInput";
import { TGlobalOffer } from "@/http/types/global-offers";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { DatesInput } from "./DatesInput";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { MultipleClientSegmentSelection } from "@/components/shared/Input/MultipleClientSegmentSelection";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { MultipleSelectionBrand } from "@/components/shared/Input/MultipleSelectionBrand";
import { MultipleSelectionCategory } from "@/components/shared/Input/MultipleSelectionCategory";
import { MultipleSelectionProduct } from "@/components/shared/Input/MultipleSelectionProduct";
import { format } from "date-fns";
import { mapDiscountOfferPayload, mapProductsOfferPayload } from "./mapOffers";
import { DATE_TIME } from "@/constants/date";
import { MdOutlineEdit } from "react-icons/md";

type TFormOffer = {
  offer?: TGlobalOffer;
  onDone?: (offer: TGlobalOffer) => void;
};

export const FormOffer = ({ offer, onDone }: TFormOffer) => {
  const isEdit = !!offer;
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [isLoading, setisLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(!isEdit);

  const defaultValues = isEdit
    ? {
        ...offer,
        startsAt: offer.startsAt
          ? format(new Date(validateTimezoneDate(offer.startsAt)), DATE_TIME)
          : "",
        endsAt: offer.endsAt
          ? format(new Date(validateTimezoneDate(offer.endsAt)), DATE_TIME)
          : "",
      }
    : {
        type: "ADDITIONAL_PRODUCT",
        conditionProducts: [{ id: undefined, value: 1 }],
        appliedProducts: [{ id: undefined, value: 1 }],
      };

  const methods = useForm<any>({ defaultValues });

  const offerType = methods.watch("type");

  const isPayXTakeY = offerType === "ADDITIONAL_PRODUCT";
  const isDiscountPercentage = offerType === "PERCENTAGE_DISCOUNT";
  const isDiscountQuantity = offerType === "QUANTITY_DISCOUNT";

  const isDiscount = isDiscountPercentage || isDiscountQuantity;

  const onSubmit = async (values: any) => {
    setisLoading(true);
    try {
      let data;
      if (isDiscount) {
        data = mapDiscountOfferPayload(values);
      } else {
        data = mapProductsOfferPayload(values);
      }

      let newOffer;
      if (isEdit) {
        newOffer = await updateOffer(offer.id, data);
      } else {
        newOffer = await createOffer(data);
      }
      setisLoading(false);
      showToastSuccess(
        t(isEdit ? "OFFERS.OFFER_UPDATED" : "OFFERS.OFFER_CREATED")
      );
      onDone?.(newOffer);
      methods.reset(newOffer);
      navigate(`/offers/${newOffer.id}/show`);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
      setisLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      {isEdit && (
        <div className="bolder flexGap center">
          <span>{offer.name}</span>
          {!isEditable && (
            <span
              className="circleButton pointer"
              onClick={() => {
                setIsEditable(true);
                methods.reset(defaultValues);
              }}
            >
              <MdOutlineEdit />
            </span>
          )}
        </div>
      )}
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={styles.section}>
          <div>
            <h6 className={styles.title}>{t("OFFERS.GENERAL")}</h6>
            <div className="divider" />
            <div className={styles.inputRow}>
              <div className="flexGap">
                <div className="col-5 col-sm-12 noPadding">
                  <SelectV2
                    name="type"
                    label={t("OFFERS.OFFER_TYPE")}
                    required
                    disabled={!isEditable}
                    choices={[
                      {
                        value: "ADDITIONAL_PRODUCT",
                        label: t("OFFERS.ADDITIONAL_PRODUCT"),
                      },
                      {
                        value: "PERCENTAGE_DISCOUNT",
                        label: t("OFFERS.PERCENTAGE_DISCOUNT"),
                      },
                      {
                        value: "QUANTITY_DISCOUNT",
                        label: t("OFFERS.QUANTITY_DISCOUNT"),
                      },
                    ]}
                  />
                </div>
                <div className="col-7 col-sm-12 noPadding">
                  <InputV2
                    name="name"
                    label={t("OFFERS.OFFER_NAME")}
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className={`divider ${styles.inputRow}`} />

            <div className={styles.inputRow}>
              <h6 className="bold">{t("OFFERS.CLIENT_BUYS")}</h6>
              {isDiscount && (
                <>
                  <div className="mbmd">
                    <RadioGroupV2
                      required
                      inline
                      color="purple"
                      name="condition"
                      label={t("OFFERS.CONDITION")}
                      options={[
                        {
                          label: t("QUANTITY"),
                          value: "QUANTITY",
                          disabled: !isEditable,
                        },
                        {
                          label: t("AMOUNT"),
                          value: "AMOUNT",
                          disabled: !isEditable,
                        },
                      ]}
                    />
                  </div>
                  <InputV2
                    name={"conditionValue"}
                    label={t("OFFERS.MIN_VALUE")}
                    onKeyPress={validateDecimalNumber}
                    disabled={!isEditable}
                    infoLabel={
                      methods.watch("condition") === "QUANTITY"
                        ? t("OFFERS.MIN_VALUE_INFO")
                        : ""
                    }
                  />
                  <SelectV2
                    name={"targetType"}
                    label={t("OFFERS.ARTICLE_FROM")}
                    required
                    disabled={!isEditable}
                    choices={[
                      {
                        label: t("OFFERS.SPECIFIC_PRODUCTS"),
                        value: "PRODUCTS",
                      },
                      {
                        label: t("OFFERS.SPECIFIC_BRANDS"),
                        value: "BRANDS",
                      },
                      {
                        label: t("OFFERS.SPECIFIC_CATEGORIES"),
                        value: "CATEGORIES",
                      },
                    ]}
                  />

                  {methods.watch("targetType") === "PRODUCTS" && (
                    <div className="row">
                      <MultipleSelectionProduct
                        values={offer?.targetValues || []}
                        name="targetValues"
                        required
                        disabled={!isEditable}
                      />
                    </div>
                  )}
                  {methods.watch("targetType") === "BRANDS" && (
                    <div className="row">
                      <MultipleSelectionBrand
                        name="targetValues"
                        required
                        disabled={!isEditable}
                      />
                    </div>
                  )}
                  {methods.watch("targetType") === "CATEGORIES" && (
                    <div className="row">
                      <MultipleSelectionCategory
                        name="targetValues"
                        required
                        disabled={!isEditable}
                      />
                    </div>
                  )}
                </>
              )}
              {isPayXTakeY && (
                <>
                  <p className={styles.description}>
                    {t("OFFERS.CLIENT_RECEIVES_MESSAGE")}
                  </p>
                  <div className={styles.inputRow}>
                    <ProductGiftArrayInput
                      values={offer?.conditionProducts ?? []}
                      name="conditionProducts"
                      disabled={!isEditable}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <h6 className="bold">{t("OFFERS.CLIENT_RECEIVES")}</h6>

            {isPayXTakeY && (
              <>
                <div className={styles.inputRow}>
                  <ProductGiftArrayInput
                    values={offer?.appliedProducts ?? []}
                    name="appliedProducts"
                    disabled={!isEditable}
                  />
                </div>
              </>
            )}

            {isDiscountPercentage && (
              <NumericInput
                name="appliedValue"
                label={t("OFFERS.DISCOUNT_RATE")}
                min={0}
                max={100}
                required
                hideControls
                disabled={!isEditable}
                decimals
              />
            )}
            {isDiscountQuantity && (
              <InputV2
                name="appliedValue"
                label={t("OFFERS.MAX_VALUE_TO_OFFER")}
                onKeyPress={validateDecimalNumber}
                disabled={!isEditable}
              />
            )}
          </div>
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.CLIENT_SEGMENT")}</h6>
          <div className="divider" />
          <MultipleClientSegmentSelection
            name="targetSegments"
            label={t("SEGMENT")}
            disabled={!isEditable}
          />
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.COUPON")}</h6>
          <div className="divider" />
          <InputV2
            name="couponCode"
            label={t("OFFERS.COUPON_CODE_LABEL")}
            disabled={!isEditable}
          />
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.LIMITATIONS")}</h6>
          <div className="divider" />
          <br />

          <Checkbox
            disabled={!isEditable}
            name={"maxUsesOffer"}
            label={t("OFFERS.LIMIT_DISCOUNT")}
          />
          {methods.watch("maxUsesOffer") && (
            <InputV2
              name="maximumUses"
              label={t("OFFERS.NUMBER_USES")}
              onKeyPress={validateOnlyNumber}
              required
              disabled={!isEditable}
            />
          )}

          <Checkbox
            name={"maxUsesClient"}
            label={t("OFFERS.LIMITATE_NUMBER_USES_BY_USER")}
            disabled={!isEditable}
          />
          {methods.watch("maxUsesClient") && (
            <InputV2
              name="maximumUsesByUser"
              label={t("OFFERS.NUMBER_USES_BY_USER")}
              onKeyPress={validateOnlyNumber}
              disabled={!isEditable}
              required
            />
          )}

          <Checkbox
            name={"maxUsesPurchase"}
            disabled={!isEditable}
            label={t("OFFERS.LIMITATE_NUMBER_USES_BYPURCHASE")}
          />
          {methods.watch("maxUsesPurchase") && (
            <InputV2
              disabled={!isEditable}
              name="maximumUsesByPurchase"
              label={t("OFFERS.NUMBER_USES_BY_PURCHASE")}
              onKeyPress={validateOnlyNumber}
              required
            />
          )}

          <Checkbox
            name={"stackable"}
            label={t("OFFERS.STACKABLE")}
            disabled={!isEditable}
          />
          {!methods.watch("couponCode") && (
            <NumericInput
              disabled={!isEditable}
              name="priority"
              label={t("OFFERS.PRIORITY")}
              infoLabel={t("OFFERS.PRIORITY_INFO")}
              required
              min={1}
              hideControls
            />
          )}
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.ACTIVITY_DATES")}</h6>
          <div className="divider" />
          <br />
          <DatesInput disabled={!isEditable} />
        </div>

        {isEditable && (
          <div className="col-12 rigthAlign">
            {isEdit && (
              <button
                type="button"
                className="lg"
                disabled={isLoading}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  methods.reset(defaultValues);
                  setIsEditable(false);
                }}
              >
                {t("CANCEL")}
              </button>
            )}
            <button type="submit" className="primary lg" disabled={isLoading}>
              {t("SAVE")}
            </button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
