import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { TicketDetail } from "@/components/tickets/TicketDetail/TicketDetail";
import { FEAT_TICKETS } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const TicketDetailPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("TICKETS_MANAGEMENT"), "/tickets", undefined, undefined, true);
  }, [t]);

  if (!hasFeature(FEAT_TICKETS)) {
    return <LockedFeaturePlaceholder />;
  }
  return <TicketDetail />;
};
