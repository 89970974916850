import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const InvoiceOrderPage = () => {
  const { setTitle } = useHeaderTitle();
  const { id = "" } = useParams();
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { getOrderDetail, orderDetail } = useOrderDetails();

  useEffect(() => {
    if (id) {
      getOrderDetail(id, user.id);
    }
  }, [id]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (id) {
        getOrderDetail(id, user.id);
      }
    }, 10000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    setTitle(
      `${t("PURCHASES.DETAIL.TITLE")} » ${
        orderDetail?.subPurchaseNumber ?? "-"
      } » Invoice`,
      `/orders/${id}`
    );
  }, [t, orderDetail]);

  if (!Object.keys(orderDetail).length) return <Spinner />;

  return <></>;
};
