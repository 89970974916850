export const selectMultipleStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
    border: "1px solid #0b263f",
    backgroundColor: state.isDisabled ? "rgb(247, 249, 252)" : "transparent",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    height: "20px",
    alignItems: "center",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  input: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  menu: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 5 }),
};

export const singleSelectStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "0.5rem",
    height: "1.2rem",
    marginTop: "0.5rem",
    border: "1px solid #0b263f",
    backgroundColor: state.isDisabled ? "rgb(247, 249, 252)" : "transparent",
  }),
};
