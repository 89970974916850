import { TOTAL_ORDERS_PER_PAGE } from "@/constants/export-orders";
import { getPurchaseById, getPurchases, TPurchase } from "@/http";
import { logError } from "@/utils/errors";
import { create } from "zustand";

type TUsePurchase = {
  getPurchases: (
    supplierId: string,
    isCommercial: boolean,
    commercialId?: string,
    showAllPurchases?: boolean
  ) => void;
  isLoadingPurchase: boolean;
  purchases: any[];
  currentPage: number;
  setCurrentPage: (currentPageGroup: number) => void;
  totalPages: number;
  setCurrentFilter: (value: string) => void;
  currentFilter: string;
  setPurchaseNumberFilter: (value: string) => void;
  purchaseNumberFilter: string;
  setSupplierIdFilter: (value: string) => void;
  supplierIdFilter: string;
  purchase: TPurchase;
  setPurchase: (value: TPurchase) => void;
  getPurchase: (value: string) => Promise<void>;
  sortBy: string;
  sortDirection: string | string[];
  setSorting: (sortBy: string, sortDirection: string | string[]) => void;
  isProcurementFilter: boolean;
  setIsProcurement: (value: boolean) => void;
  statusFilters: any;
  setStatusFilters: (value: any) => void;
};
export const usePurchase = create<TUsePurchase>((set, get) => ({
  setCurrentFilter: (currentFilter: string) => set(() => ({ currentFilter })),
  currentFilter: "PENDING",
  statusFilters: { status: "PENDING" },
  setStatusFilters: ({ key, value }: { key: string; value: string }) => {
    const { statusFilters } = get();
    const finalValue = statusFilters[key] === value ? "" : value;
    const payload = {
      ...statusFilters,
      [key]: finalValue,
    };
    set(() => ({ statusFilters: payload }));
  },
  isProcurementFilter: false,
  setIsProcurement: isProcurementFilter => {
    const payload = {
      isProcurementFilter,
      ...(isProcurementFilter ? { supplierIdFilter: "" } : {}),
    };
    set(() => ({ ...payload }));
  },
  getPurchases: async (
    supplierId,
    isCommercial,
    commercialId?,
    showAllPurchases?
  ) => {
    try {
      set({ isLoadingPurchase: true });
      const {
        currentPage,
        purchaseNumberFilter,
        supplierIdFilter,
        sortBy,
        sortDirection,
        isProcurementFilter,
        statusFilters,
      } = get();

      const filter = supplierIdFilter
        ? ""
        : Object.entries(statusFilters)
            .filter(([, value]) => value !== "")
            .map(([key, value]) => {
              if (key === "status" && value === "IS_INVOICED") {
                return "hasInvoice:false";
              }
              return `${key}:${value}`;
            })
            .join(",");

      const supplierSegment = !isProcurementFilter
        ? `supplierId:${supplierId}`
        : "";
      const sellerSegment = supplierIdFilter
        ? `sellerId:${supplierIdFilter}`
        : "";
      const purchaseSegment = purchaseNumberFilter
        ? `text:${purchaseNumberFilter}`
        : "";
      const commercialSegment =
        isCommercial && !showAllPurchases ? `commercialId:${commercialId}` : "";
      const procurementSegment = isProcurementFilter
        ? `sellerId:${supplierId}`
        : "";
      const filterSegment = filter.length ? filter : "";

      const search = [
        supplierSegment,
        sellerSegment,
        purchaseSegment,
        commercialSegment,
        procurementSegment,
        filterSegment,
      ]
        .filter(Boolean)
        .join(",");

      const data = await getPurchases({
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPage,
        search,
        sort: `${sortBy},${sortDirection}`,
      });
      set({
        purchases: data.content,
        totalPages: data.totalPages,
        isLoadingPurchase: false,
      });
    } catch (e) {
      set({
        purchases: [],
        totalPages: 0,
        isLoadingPurchase: false,
      });
    }
  },
  setPurchaseNumberFilter: purchaseNumberFilter =>
    set(() => ({ purchaseNumberFilter })),
  purchaseNumberFilter: "",
  isLoadingPurchase: false,
  purchases: [],
  currentPage: 0,
  totalPages: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
  setSupplierIdFilter: supplierIdFilter => set(() => ({ supplierIdFilter })),
  supplierIdFilter: "",
  setPurchase: purchase => set(() => ({ purchase })),
  purchase: {} as TPurchase,
  getPurchase: async id => {
    try {
      if (id) {
        const purchase = await getPurchaseById(id);
        set({ purchase });
      }
    } catch (e) {
      logError(e);
      set({ purchase: {} as TPurchase });
    }
  },
  sortBy: "createdAt",
  sortDirection: "DESC",
  setSorting: (sortBy, sortDirection) => set(() => ({ sortBy, sortDirection })),
}));
