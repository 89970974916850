import { StyleSheet } from "@react-pdf/renderer";

export const mmToPoints = (mm: number) => mm * 2.83465;
export const posWidth = mmToPoints(70);

export const styles = StyleSheet.create({
  page: { width: posWidth, padding: 10 },
  header: { textAlign: "center", fontSize: 10, marginBottom: 5 },
  headerText: { fontSize: 9, textAlign: "center" },
  addressText: { fontSize: 7, textAlign: "center" },
  section: { marginBottom: 5, fontSize: 8 },
  infoRow: { marginBottom: 3, fontSize: 8 },
  bold: { fontWeight: "bold" },
  table: { marginTop: 5, marginBottom: 5 },
  spacer: { height: 15 },
  divider: {
    marginVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "dotted",
    width: "100%",
  },
  dividerDouble: {
    marginVertical: 5,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderTopColor: "#000",
    height: 4,
    borderBottomStyle: "dotted",
    borderTopStyle: "dotted",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    fontSize: 6,
    paddingVertical: 2,
  },
  tableHeader: { fontWeight: "bold" },
  tableCell: { width: "20%", textAlign: "center" },
  tableItem: { width: "10%", textAlign: "center" },
  descriptionCell: { width: "30%", textAlign: "left" },
  footer: { textAlign: "center", fontSize: 8, marginTop: 10 },
  contentLine: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    fontSize: 8,
    marginTop: 2,
  },
  total: {
    fontSize: 10,
    textAlign: "center",
    marginTop: 5,
    fontWeight: "bold",
  },
  subTotal: {
    fontSize: 7,
    textAlign: "right",
    marginTop: 5,
    fontWeight: "bold",
  },
});
