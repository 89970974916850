import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { ticketsFilters } from "@/constants/tickets";
import { TicketListTable } from "./TicketListTable/TicketListTable";
import { TicketCreateButton } from "./TicketCreation/TicketCreateButton";
import { useSearchParams } from "react-router-dom";
import { TicketTypeFilter } from "./TicketListTable/TicketTypeFilter";

export const TicketList = () => {
  const [search, setSearch] = useSearchParams("status=");
  const status = search.get("status") || "";

  const onStatusChange = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("status", value);
    setSearch(newSearch);
  };

  return (
    <>
      <div className="contentInline flexSmColumn">
        <TicketTypeFilter />
        <TicketCreateButton />
      </div>
      <div className="spacer" />
      <div className="flexGap flexSmColumn">
        <div className="col-6 noPadding col-sm-12">
          <FilterTabs
            options={[
              { text: "FILTERS.ALL", value: "" },
              ...ticketsFilters.map(status => ({
                text: status.name,
                value: status.code,
              })),
            ]}
            currentFilter={status}
            action={onStatusChange}
          />
        </div>
        <div className="col-6 noPadding col-sm-12" />
      </div>
      <div className="spacer" />
      <TicketListTable />
    </>
  );
};
