import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { createPrePurchaseV5 } from "@/http";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { Pricing } from "../../PurchaseCreateForm/Prepurchase/Pricing";
import { Card } from "@/components/shared/Card/Card";
import { debounce } from "lodash-es";
import { filterSaleUnitRateProducts, mapSaleProducts } from "@/utils/sale";

type TResume = {
  setLoading: (loading: boolean) => void;
  setPrepurchase: (prepurchase: any) => void;
  prepurchase: any;
};
export const SaleResume = ({
  setLoading,
  prepurchase,
  setPrepurchase,
}: TResume) => {
  const { user } = useAuth();
  const [t] = useTranslation("global");
  const products = useWatch({ name: "products" });
  const seller = useWatch({ name: "seller" });
  const supplierId = user.id;
  const paymentMethod = useWatch({ name: "paymentMethod" });
  const paymentProvider = useWatch({ name: "paymentProvider" });
  const isPaid = useWatch({ name: "isPaid" });
  const isDelivered = useWatch({ name: "isDelivered" });
  const shipping = useWatch({ name: "shippingPrice" });
  const addressId = useWatch({ name: "addressId" });
  const isSale = useWatch({ name: "purchaseType" }) === "SALE";
  const warehouseDispatch = useWatch({ name: "warehouseId" });
  const coupon = useWatch({ name: "coupon" });
  const coins = useWatch({ name: "coins" });
  const payments = useWatch({ name: "payments" });

  const [finalProducts, setFinalProducts] = useState<any[]>([]);

  const isProductsValid = () => {
    const productError = finalProducts.some(
      ({ id, quantity, warehouseId, unitRate }: Record<string, string>) => {
        if (isSale) {
          return !id || !Number(quantity) || !warehouseId || !unitRate;
        } else {
          return !id || !Number(quantity) || !unitRate;
        }
      }
    );

    return !productError;
  };

  const executePrepurchase = () => {
    if (finalProducts?.length && isProductsValid()) {
      setLoading(true);
      const payload = {
        isForSeller: true,
        products: finalProducts,
        sellerId: seller?.id,
        paymentMethod,
        supplierId,
        addressId,
        shipping,
        paymentProvider,
        couponCode: coupon,
        coins,
        payments,
      } as any;

      payload.paymentStatus = isPaid === true ? "PAID" : null;
      payload.deliveryStatus = isDelivered === true ? "DELIVERED" : null;

      createPrePurchaseV5(payload)
        .then(prepurchase => setPrepurchase(prepurchase))
        .finally(() => setLoading(false));
    } else {
      setPrepurchase({});
    }
  };

  useEffect(() => {
    const debouncedEffectFunction = debounce(executePrepurchase, 600);

    debouncedEffectFunction();

    return () => {
      debouncedEffectFunction.cancel();
    };
  }, [
    finalProducts,
    paymentMethod,
    paymentProvider,
    addressId,
    shipping,
    warehouseDispatch,
    coupon,
    coins,
    payments,
  ]);

  useEffect(() => {
    if (products?.length) {
      const productsMapped = mapSaleProducts(products ?? []);

      const productsMappedFiltered = isSale
        ? filterSaleUnitRateProducts(productsMapped, warehouseDispatch)
        : productsMapped;

      setFinalProducts(productsMappedFiltered);
    } else {
      setFinalProducts([]);
    }
  }, [products, warehouseDispatch]);

  return (
    <Card className="noPadding">
      <div className="pw2 ph1 bold">{t("PURCHASES.ORDERS.RESUME_INFO")}</div>
      <div className="divider noPadding" />
      <div className="pw2 ph1">
        {Object.keys(prepurchase).length > 0 ? (
          <Pricing
            prepurchase={prepurchase}
            ligthStyle2
            editShipping
            editCoupon={isSale}
          />
        ) : (
          <>{t("PURCHASES.ORDERS.FILL_PURCHASE")}</>
        )}
      </div>
    </Card>
  );
};
