import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import {
  TFilterGroup,
  TFilterValues,
  TProduct,
  TProductSerial,
  TProductType,
  TRequestProductDelete,
  TUnitType,
} from "../../types/product";
import { TBrand } from "../../types/brand";

export * from "./options";
export * from "./v2";

const BASE_PATH = "/api/products";

export const getProductsBySubCategoryId = async (subCategoryId: string) => {
  const range = "[0,10]";
  return getInstance()
    .get<TProduct[]>(`${BASE_PATH}/${subCategoryId}`, {
      headers: { range },
    })
    .then(res => res.data);
};

export const getProducts = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TProduct>>(`${BASE_PATH}/list`, { params })
    .then(res => res.data);
};

export const getActiveBrandsByCategoryId = async (params: {
  category?: string;
  sort?: string;
}) => {
  return getInstance()
    .get<TBrand[]>(`${BASE_PATH}/brands`, { params })
    .then(res => res.data);
};

export const getProductById = async (productId: string) => {
  return getInstance()
    .get<TProduct>(`${BASE_PATH}/id/${productId}`)
    .then(res => res.data);
};

export const createProductUnitType = async (
  productId: string,
  data: Partial<TUnitType>
) => {
  return getInstance()
    .post<TUnitType>(`${BASE_PATH}/${productId}/unit-types`, data)
    .then(res => res.data);
};

export const updateProductUnitType = async (
  productId: string,
  unitTypeId: string,
  data: Partial<TUnitType>
) => {
  return getInstance()
    .put<TUnitType>(`${BASE_PATH}/${productId}/unit-types/${unitTypeId}`, data)
    .then(res => res.data);
};

export const deleteProductUnitType = async (
  productId: string,
  unitTypeId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${productId}/unit-types/${unitTypeId}`)
    .then(res => res.data);
};

export const getProductByIds = async (
  productIds: string[]
): Promise<TProduct[]> => {
  interface productIdsResponse {
    products: TProduct[];
  }

  return getInstance()
    .post<productIdsResponse>(`${BASE_PATH}/api/products/by-ids`, {
      productIds,
    })
    .then(res => res.data.products);
};

export const createProduct = async (data: TProduct) => {
  return getInstance()
    .post<TProduct>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateProduct = async (productId: string, data: TProduct) => {
  return getInstance()
    .put<TProduct>(`${BASE_PATH}/id/${productId}`, data)
    .then(res => res.data);
};

export const softDeleteProduct = async (
  productId: string,
  data: TRequestProductDelete
) => {
  return getInstance()
    .delete<TProduct>(`${BASE_PATH}/id/${productId}`, { data })
    .then(res => res.data);
};

export const getProductsbyIds = async (
  productIds: string[],
  segment?: string
) => {
  return getInstance()
    .post<{ products: TProduct[] }>(
      `${BASE_PATH}/by-ids`,
      { productIds },
      {
        headers: {
          "x-log": "omit",
        },
        params: {
          segment,
        },
      }
    )
    .then(res => res.data?.products);
};

export const getProductTypes = async (params: TRestListParams) => {
  return getInstance()
    .get<TProductType[]>(`${BASE_PATH}/types`, { params })
    .then(res => res.data);
};

export const getBrandsByCategoryId = async (data: {
  categoryId: string;
  subcategoryId?: string;
}) => {
  return getInstance()
    .post<TBrand[]>(`${BASE_PATH}/findBrands`, data)
    .then(res => res.data);
};

export const updateProductsByBrand = async (
  brandId: string,
  data: Partial<TProduct>
) => {
  return getInstance()
    .put(`${BASE_PATH}/brand/${brandId}`, data)
    .then(res => res.data);
};

export const getProductFilterValues = async (params: TFilterValues) => {
  return getInstance()
    .get<TFilterGroup>(`${BASE_PATH}/filter-values`, { params })
    .then(res => res.data);
};

export const getProductSerials = async (
  productId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TProductSerial>>(`${BASE_PATH}/${productId}/serials`, {
      params,
    })
    .then(res => res.data);
};

export const deleteSerial = async (productId: string, serialId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${productId}/serials/${serialId}`, {})
    .then(res => res.data);
};

export const updateSerial = async (
  productId: string,
  serialId: string,
  data: Partial<TProductSerial>
) => {
  return getInstance()
    .put<TUnitType>(`${BASE_PATH}/${productId}/serials/${serialId}`, data)
    .then(res => res.data);
};

export const createSerial = async (
  productId: string,
  data: Partial<TProductSerial>
) => {
  return getInstance()
    .post<TProductSerial>(`${BASE_PATH}/${productId}/serials`, data)
    .then(res => res.data);
};

export const relateSerialToPurchase = async (
  productId: string,
  serialId: string,
  purchaseId: string
) => {
  return getInstance()
    .post<TProductSerial>(
      `${BASE_PATH}/${productId}/serials/${serialId}/associate`,
      { purchaseId }
    )
    .then(res => res.data);
};

export const deleteSerialToPurchase = async (
  productId: string,
  serialId: string
) => {
  return getInstance()
    .delete<TProductSerial>(
      `${BASE_PATH}/${productId}/serials/${serialId}/disassociate`
    )
    .then(res => res.data);
};

export const getProductsFilterValues = async (supplierId: string) => {
  return getInstance()
    .get<TRestListData<TProductSerial>>(`${BASE_PATH}/filter-values`, {
      params: { supplierId },
    })
    .then(res => res.data);
};
