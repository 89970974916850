import { isUSA } from "@/constants/core";
import { formatCurrency } from "@/utils/currency";
import { Text, View } from "@react-pdf/renderer";
import { TInvoice } from "../invoiceData";
import { buildCleanName } from "../utils";
import { styles } from "./posStyles";

type Props = {
  invoice: TInvoice;
  translate: any;
};

export const ProductsTable = ({ translate, invoice }: Props) => {
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={styles.tableItem}>IT</Text>
        <Text style={styles.descriptionCell}>{translate("DESCRIPTION")}</Text>
        <Text style={styles.tableCell}>{translate("QUANTITY")}</Text>
        <Text style={styles.tableCell}>{translate("VR_UNIT")}</Text>
        <Text style={styles.tableCell}>{translate("VR_TOTAL")}</Text>
      </View>
      {(invoice?.items || []).map((item: any, idx: number) => (
        <View style={styles.tableRow} key={idx}>
          <Text style={styles.tableItem}>{idx + 1}</Text>
          <Text style={styles.descriptionCell}>
            {buildCleanName(
              item?.name,
              item?.properties,
              item?.customAttributes ?? [],
              item?.tax
            )}
          </Text>
          <Text style={styles.tableCell}>{item?.quantity}</Text>
          <Text style={styles.tableCell}>
            {formatCurrency(item?.unitPrice || 0)}
          </Text>
          <Text style={styles.tableCell}>
            {formatCurrency(item?.total || 0)}
          </Text>
        </View>
      ))}

      {invoice?.total !== invoice?.subTotal && (
        <View style={styles.subTotal}>
          <Text>
            {translate("SUB_TOTAL")}: {formatCurrency(invoice?.subTotal || 0)}
          </Text>
        </View>
      )}

      {invoice?.discount && (
        <View style={styles.subTotal}>
          <Text>
            {translate("DISCOUNT")}: {formatCurrency(invoice?.discount || 0)}
          </Text>
        </View>
      )}

      {!!invoice?.totalTaxes && (
        <>
          <View style={styles.subTotal}>
            <Text>
              {isUSA ? translate("TAX") : "IVA"}:{" "}
              {formatCurrency(invoice?.totalTaxes)}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};
