import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import { getProductByIds, searchProducts } from "@/http";
import { useTranslation } from "react-i18next";
import { selectMultipleStyles } from "@/constants/input";
import useSWR from "swr";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash-es";
import { useAuth } from "@/store/auth";
import { ProductSelectOption } from "@/components/offers/_form/ProductSelectOption";

export const MultipleSelectionProduct = ({
  name,
  disabled = false,
  required = false,
  values = [],
}: {
  name: string;
  required: boolean;
  disabled: boolean;
  values: string[];
}) => {
  const [t] = useTranslation("global");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    data: products,
    isValidating,
    mutate,
  } = useSWR(
    values.length > 0 ? [values, "products-supplier"] : null,
    getProductByIds
  );
  const methods = useFormContext();
  const defaultValues = products
    ?.filter(({ id }) => values?.includes(id))
    ?.map(i => ({ value: i.id, label: i.name, ...i }));

  useEffect(() => {
    mutate();
  }, [values, disabled]);

  return (
    <>
      {!isValidating && (
        <ProductInput
          values={defaultValues ?? []}
          disabled={disabled}
          onValueChange={(values: any[]) =>
            methods.setValue(
              name,
              (values || [])?.map((v: any) => v?.value)
            )
          }
        />
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("LOGIN.FIELD_REQUIRED") : undefined,
        }}
        render={() => <></>}
      />

      {!!get(errors, name) && (
        <span className="redText mdFont ml2">
          {get(errors as any, name).message}
        </span>
      )}
    </>
  );
};

type TProductInput = {
  values: any[];
  disabled?: boolean;
  onValueChange: (values: any[]) => void;
};
const ProductInput = ({
  values = [],
  disabled,
  onValueChange,
}: TProductInput) => {
  const { user } = useAuth();
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(values);

  const fetchOptions = async (input: string) => {
    try {
      const response = await searchProducts({
        data: { text: input, supplierId: user.id },
        params: {
          sort: "name,asc",
          page: 0,
          size: 10,
        },
      });
      const optionsData = response?.content?.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));
      setOptions(optionsData);
    } catch (_) {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    onValueChange?.(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (inputValue) {
      debouncedFetchOptions(inputValue);
    } else {
      debouncedFetchOptions("");
    }
  }, [inputValue, debouncedFetchOptions]);

  return (
    <>
      <p>{t("PRODUCT")}</p>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={t("SELECT_OPTION")}
        styles={selectMultipleStyles}
        noOptionsMessage={() => t("NO_OPTIONS")}
        formatOptionLabel={(product, { context }) =>
          context === "menu" ? (
            <ProductSelectOption product={product} />
          ) : (
            product.name
          )
        }
      />
    </>
  );
};
