import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { formatCurrency } from "@/utils/currency";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";
import { TPaymentMethod } from "@/http";

import { NumericInput } from "@/components/shared/Input/NumericInput";
type TPaymentMethodsGroup = {
  paymentMethods: TPaymentMethod[];
  isEdit?: boolean;
  paymentMethod?: string;
  paymentProvider?: string;
  coinsBalance?: number;
  maxCoins?: number;
};

export const PaymentMethodsGroup = ({
  isEdit,
  coinsBalance = 0,
  maxCoins = 0,
}: TPaymentMethodsGroup) => {
  const { watch } = useFormContext();
  const [t] = useTranslation("purchases");
  const purchaseType = watch("purchaseType");

  return (
    <>
      <div className="contentInline">
        {!isEdit && (
          <div className="flexGap center">
            {<span className="bold">{t("PAYMENT_METHOD")}</span>}

            <ToolTip
              title={t("PAYMENT_LABEL_REMINDER")}
              position="Right"
              width="15rem"
              theme="orange"
            >
              <span>
                <RiErrorWarningLine className="orangeText mtmd" />
              </span>
            </ToolTip>
          </div>
        )}
      </div>

      {purchaseType === "SALE" && coinsBalance > 0 && (
        <>
          <div className="flexGap">
            <NumericInput
              name="coins"
              label={t("COINS_PARTIAL_PAYMENT")}
              decimals
              max={maxCoins}
              infoLabel={
                t("COINS_AVAILABLE") + ": " + formatCurrency(coinsBalance)
              }
            />
          </div>
        </>
      )}
    </>
  );
};
