import { Page, Document, Text, View } from "@react-pdf/renderer";
import PoweredLabel from "../components/PoweredLabel";
import { TInvoice } from "../invoiceData";
import { posWidth } from "./posStyles";
import { styles } from "./posStyles";
import { ProductsTable } from "./ProductsTable";
import { PurchaseInfo } from "./PurchaseInfo";
import { SupplierInfo } from "./SupplierInfo";
import { TotalsInfo } from "./TotalsInfo";

type Props = {
  invoice: TInvoice;
  translate: any;
};
export const PosPDF = ({ translate, invoice }: Props) => {
  return (
    <Document>
      <Page size={{ width: posWidth }} style={styles.page}>
        <SupplierInfo translate={translate} invoice={invoice} />
        <PurchaseInfo translate={translate} invoice={invoice} />
        <ProductsTable translate={translate} invoice={invoice} />
        <TotalsInfo translate={translate} invoice={invoice} />

        <View style={styles.footer}>
          <Text>{translate("GREETINGS")}</Text>
          <View style={styles.spacer} />
          <PoweredLabel />
        </View>
      </Page>
    </Document>
  );
};
