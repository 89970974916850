import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import { InputV2 } from "@/components/shared/Input/InputV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import localStyles from "./ProductGiftArrayInput.module.scss";
import { validateDecimalNumber } from "@/utils/keyboard";
import { get } from "lodash-es";
import { SingleSelectionProduct } from "@/components/shared/Input/SingleSelectionProduct";

export const ProductGiftArrayInput = ({
  name = "gifts",
  disabled = false,
  values = [],
}: {
  name?: string;
  disabled?: boolean;
  values: any[];
}) => {
  const [t] = useTranslation("global");
  const {
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
    keyName: "id",
    rules: {
      minLength: { value: 1, message: t("LOGIN.FIELD_REQUIRED") },
      required: { value: true, message: t("LOGIN.FIELD_REQUIRED") },
    },
    shouldUnregister: true,
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className={localStyles.inputs}>
            <div>
              <SingleSelectionProduct
                value={values?.[index]?.id ?? ""}
                name={`${name}[${index}].id`}
                disabled={disabled}
                required
              />
            </div>
            <div>
              <InputV2
                name={`${name}.${index}.value`}
                label={t("OFFERS.QUANTITY")}
                onKeyPress={validateDecimalNumber}
                required
                disabled={disabled}
              />
            </div>
            {!disabled && (
              <div className="rigthAlign centerVAlign">
                <ToolTip title={t("OFFERS.DELETE_OFFER")} position="Left">
                  <span className="circleButton">
                    <AiOutlineDelete
                      cursor="pointer"
                      size={15}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </span>
                </ToolTip>
              </div>
            )}
          </div>
        );
      })}
      {!disabled && (
        <div>
          <button
            type="button"
            className="secondary"
            onClick={() =>
              append({ id: "", quantity: 0, invoiceIncluded: false })
            }
          >
            + {t("ADD")}
          </button>
          <p className={localStyles.errorsLabel}>
            {(get(errors, `${name}.root`) as any)?.message?.toString()}
          </p>
        </div>
      )}
    </>
  );
};
