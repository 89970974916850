import { Card } from "@/components/shared/Card/Card";
import { showToastError } from "@/hooks/useToast";
import { updateSupplier } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import { HTMLEditor } from "./HTMLEditor";
import styles from "./styles.module.scss";

export const TermsAndConditios = () => {
  const [t] = useTranslation("global");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, setUser } = useAuth();
  const [newTerms, setNewTerms] = useState(user.termsAndCondition);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newUser = await updateSupplier(user.id, {
        termsAndCondition: newTerms,
      });
      setUser(newUser);
      setIsEdit(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <p className="bolder flexGap center">
        <span>{t("TERMS_AND_CONDITIONS")} </span>
        {!isEdit && (
          <span className="pointer circleButton">
            <BiEdit
              onClick={() => {
                setIsEdit(true);
              }}
            />
          </span>
        )}
      </p>

      <div className="spacer" />
      <div>
        {isEdit ? (
          <>
            <HTMLEditor
              initialHTML={user.termsAndCondition ?? ""}
              onValueChange={setNewTerms}
            />
          </>
        ) : (
          <div
            className={styles.termsContainer}
            dangerouslySetInnerHTML={{
              __html: user.termsAndCondition ?? t("NO_DATA"),
            }}
          />
        )}
      </div>
      {isEdit && (
        <>
          <div className="spacer" />
          <div className="rigthAlign">
            <button
              className="outlined"
              onClick={() => setIsEdit(false)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary"
              onClick={handleSave}
              disabled={isLoading}
            >
              {t("SAVE")}
            </button>
          </div>
        </>
      )}
      <div className="spacer" />
    </Card>
  );
};
