import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { getSubpurchaseByTrackingCode } from "@/http";
import { getSupplierDeliveryProviderByCode } from "@/http/resources/suppliers/delivery-provider";
import { debounce } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdRemoveCircle } from "react-icons/io";
import useSWR from "swr";

type TProviderLogisticDispatchForm = {
  setIsLoading: (value: boolean) => void;
  setUsedTrackingCode: (value: boolean) => void;
  usedTrackingCode: boolean;
  files: any[];
  setFiles: (files: any[]) => void;
};

export const ProviderLogisticDispatchForm = ({
  setIsLoading,
  setUsedTrackingCode,
  usedTrackingCode,
  files,
  setFiles,
}: TProviderLogisticDispatchForm) => {
  const [t] = useTranslation("orders-page");
  const methods = useFormContext();
  const deliveryProviderCode = methods.watch("dispatchValue");

  const { data: deliveryProviderStatus } = useSWR(
    deliveryProviderCode
      ? [deliveryProviderCode, "supplier-delivery-providers"]
      : null,
    getSupplierDeliveryProviderByCode
  );

  const validateTrackingCode = debounce(async (trackingCode: string) => {
    setIsLoading(true);
    const { content = [] } = await getSubpurchaseByTrackingCode(trackingCode);
    const mappedContent = content.filter(
      order => order.trackingCode === trackingCode
    );
    setUsedTrackingCode(!!mappedContent?.length);
    methods.trigger("trackingCode");
    setIsLoading(false);
  }, 500);

  return (
    <>
      {deliveryProviderStatus && deliveryProviderStatus?.length > 0 && (
        <SelectV2
          name="providerDeliveryStatus"
          label={t("DELIVERY_STATUS")}
          required
          choices={deliveryProviderStatus.map(ds => ({
            label: ds.providerDeliveryStatusName,
            value: ds.providerDeliveryStatus,
          }))}
        />
      )}
      <InputV2
        name="trackingCode"
        label={t("REFERENCE_CODE")}
        onChange={e => validateTrackingCode(e.target.value)}
        infoLabel={
          usedTrackingCode &&
          methods.watch("trackingCode") && (
            <p className="redText">*{t("USED_REFERENCE_CODE")}</p>
          )
        }
      />
      <p> {t("REFERENCE")}</p>
      <div>
        {!files.length && (
          <DragAndDropFile
            accept="image/png,image/jpeg,application/pdf"
            files={files}
            setFiles={setFiles}
            minifiedStyle
          />
        )}
        {files.length > 0 && (
          <div className="flexGap center mtmd">
            <ToolTip title={t("DELETE_GUIDE")} position="Right">
              <IoMdRemoveCircle
                className="redText mtmd"
                cursor="pointer"
                onClick={() => setFiles([])}
              />
            </ToolTip>
            {files[0]?.name ?? "-"}
          </div>
        )}
      </div>
    </>
  );
};
