import { Card } from "@/components/shared/Card/Card";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { Modal } from "@/components/shared/Modal/Modal";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { getPurchases } from "@/http";
import { buildSearch } from "@/pages/Clients/ClientList";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import clsx from "clsx";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const TicketCreateButton = () => {
  const { user, commercial } = useAuth();
  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useSearchParams("pageorders=0");
  const page = +search.get("pageorders")!;
  const type = search.get("tickettype")!;
  const purchaseId = search.get("purchaseticket")!;
  const navigate = useNavigate();

  const buildSearchParams = buildSearch(
    type === "PURCHASE_ORDERS"
      ? buildSearch("", "sellerId:", user.id)
      : buildSearch("", "supplierId:", user.id),
    "createdBy:",
    commercial?.id
  );

  const params: any = {
    search: buildSearchParams,
    size: 5,
    page,
    sort: "createdAt,desc",
  };

  const swrData = useSWR([params, "purchases-tickets"], getPurchases);
  const { data, error } = swrData;
  const purchases = get(data, "content", []);

  const onTypeChange = (value: string) => {
    if (["PRODUCTS_TITLE", "OTHERS"].includes(value)) {
      navigate(`/tickets/create/${value}`);
    } else {
      const newSearch = new URLSearchParams(search);
      newSearch.set("tickettype", value);
      setSearch(newSearch);
      setIsOpen(true);
    }
  };

  const onPurchaseIdChange = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("purchaseticket", value);
    setSearch(newSearch);
  };

  const onClose = () => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tickettype", "");
    setSearch(newSearch);
    setIsOpen(false);
  };

  const createTicket = () => {
    navigate(`/tickets/create/${type}/${purchaseId}`);
  };

  useEffect(() => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("purchaseticket", "");
    setSearch(newSearch);
  }, [page]);

  return (
    <>
      <SingleMenu
        hideOnSelect
        align="rigth"
        width="9rem"
        label={
          <button className="primary textLine">{t("CREATE_TICKET")}</button>
        }
        options={[
          { value: "SALES", label: globalT("SALES") },
          { value: "PRODUCTS_TITLE", label: globalT("PRODUCTS_TITLE") },
          { value: "OTHERS", label: globalT("OTHERS") },
        ]}
        onSelect={onTypeChange}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("CREATE_TICKET")}
        footer={
          <>
            <button onClick={onClose}>{globalT("CANCEL")}</button>
            <button
              className="primary lg"
              onClick={createTicket}
              disabled={!purchaseId}
            >
              {globalT("CONTINUE")}
            </button>
          </>
        }
      >
        {error ? (
          <p>Error</p>
        ) : (
          <>
            {purchases?.length > 0 ? (
              <>
                <p className="bold">
                  {type !== "PURCHASE_ORDERS"
                    ? t("SELECT_SALE")
                    : t("SELECT_ORDER")}
                </p>
                <div className="spacer" />
                <Card className="mdFont noPadding">
                  <PaginatedTable swrData={swrData} paginationKey="pageorders">
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>{t("CREATED_AT")}</th>
                          <th>
                            {type !== "PURCHASE_ORDERS"
                              ? globalT("SALE_NUMBER")
                              : globalT("ORDER_NUMBER")}
                          </th>
                          <th>{t("PURCHASE_PAYMENT_METHOD")}</th>
                          <th>{t("TOTAL")}</th>
                          {type !== "PURCHASE_ORDERS" && (
                            <th className="leftAlign">
                              {t(isUSA ? "LEGAL_NAME" : "CLIENT")}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {purchases.map(p => (
                          <tr
                            key={p.id}
                            className={clsx("pointer", styles.row, {
                              [styles.selected]: purchaseId === p.id,
                            })}
                            onClick={() => onPurchaseIdChange(p.id)}
                          >
                            <td>
                              {format(
                                new Date(p.createdAt),
                                isUSA ? USA_DATE_HOUR : DATE_HOUR
                              )}
                            </td>
                            <td>{p.purchaseNumber}</td>
                            <td>
                              {p?.paymentMethod
                                ? globalT(`PAYMENT_METHOD.${p?.paymentMethod}`)
                                : p?.payments?.length > 0
                                ? globalT(
                                    `PAYMENT_METHOD.${p?.payments[0]?.paymentMethod}`
                                  )
                                : "-"}
                            </td>
                            <td>{formatCurrency(p.total)}</td>
                            {type !== "PURCHASE_ORDERS" && (
                              <td className="lh1 leftAlign">
                                <SellerResume seller={p.client} hideEdit />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </PaginatedTable>
                </Card>
              </>
            ) : (
              <>{t("NO_DATA")}</>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
