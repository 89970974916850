import { Modal } from "@/components/shared/Modal/Modal";
import { useTranslation } from "react-i18next";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router";

export const TicketCreatedModal = ({
  isOpen,
  ticket,
}: {
  isOpen: boolean;
  ticket: any;
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  return (
    <Modal isOpen={isOpen} onClose={() => navigate("/tickets")} size="sm">
      <div className="textCenter">
        <div className="spacer" />
        <div className="spacer" />
        <span className="greenText">
          <FaRegCheckCircle size={35} />
        </span>

        <p className="bolder purpleText">{t("TICKET_CREATED")}</p>
        <div className="spacer" />
        <p className="mdFont">
          {t("TICKET_CREATED_DESCRIPTION")}{" "}
          <span className="bold">{ticket.number}</span>
        </p>
        <div className="spacer" />
        <div className="spacer" />
        <button className="primary lg" onClick={() => navigate("/tickets")}>
          {t("ACCEPT")}
        </button>
        <div className="spacer" />
      </div>
    </Modal>
  );
};
