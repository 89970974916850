import { ClientDetail } from "./ClientDetail";
import { Card } from "@/components/shared/Card/Card";
import { ClientEvents } from "./ClientEvents";
import { useState } from "react";

export const CRMSection = ({ client }: { client: any }) => {
  const [reloadData, setReloadData] = useState(false);

  return (
    <div className="flexGap flexSmColumn">
      <div className="col-4 col-sm-12 noPadding">
        <Card className="noPadding">
          <ClientDetail client={client} onChange={setReloadData} />
        </Card>
      </div>
      <div className="col-8 col-sm-12 noPadding">
        <Card className="pwmd">
          <ClientEvents onChange={setReloadData} sharedReload={reloadData} />
        </Card>
      </div>
    </div>
  );
};
