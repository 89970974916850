import { Modal } from "@/components/shared/Modal/Modal";
import {
  assignClientToSegment,
  getSegments,
  TSegment,
  TSeller,
  unassignClientFromSegment,
} from "@/http";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { FormProvider, useForm } from "react-hook-form";
import useSWR from "swr";
import { SelectV2 } from "../shared/Input/SelectV2";
import { useAuth } from "@/store/auth";
import { AiOutlineEdit } from "react-icons/ai";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_CLIENT_SEGMENTATION } from "@/constants/features";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { hasPermission } from "@/utils/validations/permissions";

type Props = {
  client: TSeller;
  onDone?: () => void;
};
export const ClientSegmentButton = ({ client, onDone }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");
  const { user, isCommercial, role } = useAuth();
  const { hasFeature } = useSubscription();

  const canChange = hasPermission(isCommercial, role, [
    "action.clients.change-segment",
  ]);

  const sellerSupplier = client.suppliers?.find(
    s => s.supplierId === (user.supplierId ?? user.id)
  );

  const segment = sellerSupplier?.segments[0];

  return (
    <>
      <DisabledZone when={!hasFeature(FEAT_CLIENT_SEGMENTATION)}>
        <button
          onClick={() => {
            if (canChange) {
              setIsOpen(true);
            }
          }}
          className={"purpleOutlined"}
          title={t("EDIT")}
        >
          {segment?.name}
          {canChange && <AiOutlineEdit />}
        </button>
      </DisabledZone>

      {createPortal(
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <h6 className="bold">{t("SEGMENTS.SEGMENT")}</h6>
          <div className="divider" />
          <div className="spacer" />
          <ClientSegmentForm
            client={client}
            segment={segment}
            onDone={() => {
              setIsOpen(false);
              onDone?.();
            }}
          />
        </Modal>,
        document.body
      )}
    </>
  );
};

export const ClientSegmentForm = ({
  client,
  segment,
  onDone,
}: {
  client: TSeller;
  segment?: TSegment;
  onDone: () => void;
}) => {
  const [t] = useTranslation("global");
  const { data: segments } = useSWR("segments", getSegments);

  const defaultValues = segment || { id: "" };

  const methods = useForm({ defaultValues });
  const { isSubmitting } = methods.formState;

  const handleSubmit = async (values: typeof defaultValues) => {
    try {
      if (segment) {
        await unassignClientFromSegment(client.id, segment.id);
      }
      await assignClientToSegment(client.id, values.id);
      showToastSuccess(t("SAVED"));
      methods.reset();
      onDone();
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  const handleUnassign = async () => {
    try {
      if (segment) {
        await unassignClientFromSegment(client.id, segment.id);
      }
      showToastSuccess(t("SAVED"));
      onDone();
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SelectV2
          name="id"
          choices={(segments || []).map(s => ({ value: s.id, label: s.name }))}
          label={t("SEGMENTS.SEGMENT")}
          required
        />

        <div className="divider" />
        <div className="spacer" />
        <div className="contentInline">
          {segment && (
            <button
              type="button"
              className="redText"
              onClick={methods.handleSubmit(handleUnassign)}
              disabled={isSubmitting}
            >
              {t("DELETE")}
            </button>
          )}
          <button
            type="submit"
            className="primary"
            disabled={isSubmitting}
            style={{ marginLeft: "auto" }}
          >
            {t("SAVE")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
