import { isUSA } from "@/constants/core";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { deliveryStatusRendering, purchaseStatus } from "@/constants/purchases";
import { useUserPurchase } from "@/hooks/useUserPurchases";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { format } from "date-fns";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { hasPermission, isAdmin } from "@/utils/validations/permissions";

export const LastPurchasesUser = ({
  clientId,
  useSubpurchases = false,
  font = "md",
  customTitle,
}: {
  clientId: string;
  useSubpurchases?: boolean;
  font?: "sm" | "md";
  customTitle?: ReactNode;
}) => {
  const { user, isCommercial, commercial, role } = useAuth();
  const hasMultiplePermissions = hasPermission(isCommercial, role, [
    "action.purchase.show-all-purchases",
  ]);

  const data = useUserPurchase(
    useSubpurchases,
    clientId,
    user.id,
    !isAdmin(isCommercial, role),
    commercial?.id,
    hasMultiplePermissions || isAdmin(isCommercial, role)
  );
  const [t] = useTranslation("orders-page");
  const fontClass = font === "md" ? "mdFont" : "smFont";

  if (!data?.content?.length) {
    return null;
  }

  return (
    <>
      {customTitle ? customTitle : <p className="bold">{t("LAST_ORDERS")}</p>}
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={fontClass}>{t("ORDER")}</th>
            <th className={fontClass}>{t("DETAILS.TOTAL")}</th>
            <th className={fontClass}>{t("STATUS_NAME")}</th>
            <th className={fontClass}>{t("CREATED")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.content?.map((order: any) => (
            <tr key={order.id} className={`${styles.row} ${styles[fontClass]}`}>
              <td className={fontClass}>
                <Link
                  to={`/${useSubpurchases ? "orders" : "purchases"}/${
                    order.id
                  }${useSubpurchases ? "" : "/show"}`}
                  className="textUnderline"
                >
                  {useSubpurchases
                    ? order?.subPurchaseNumber
                    : order.purchaseNumber}
                </Link>
              </td>
              <td className={fontClass}>{formatCurrency(order?.total ?? 0)}</td>
              <td className={fontClass}>
                <span className="statusColorHelper yellow">
                  {useSubpurchases
                    ? deliveryStatusRendering.find(
                        s => s.code === order?.deliveryStatus
                      )?.name
                    : purchaseStatus.find(s => s.code === order?.status)?.name}
                </span>
              </td>
              <td className={fontClass}>
                {format(
                  new Date(order.createdAt),
                  isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
