import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { getProductSerials } from "@/http";
import useUrlState from "@ahooksjs/use-url-state";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { AttachmentsModal } from "./AttachmentsModal";
import { SerialDelete } from "./SerialDelete";
import { SerialModal } from "./SerialsModal";
import styles from "./styles.module.scss";

export const SerialsTable = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const [{ pageSerials }] = useUrlState({ pageSerials: "0" });

  const params: any = {
    size: 5,
    page: pageSerials,
    sort: "createdAt,desc",
  };

  const swrData = useSWR([id, params, "serials-list"], getProductSerials);
  const { data: serials, error, mutate } = swrData;

  return (
    <>
      <div className="contentInline">
        <span className="bold">{t("SERIALS")}</span>
        <SerialModal productId={id!} onDone={mutate} />
      </div>
      {serials && !error && (
        <PaginatedTable swrData={swrData} paginationKey="pageSerials">
          <table className={styles.table}>
            <thead>
              <tr className={styles.thead}>
                <th>{t("SERIAL")}</th>
                <th>{t("EVIDENCE")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {serials?.content?.map(s => (
                <tr key={s.id} className={styles.row}>
                  <td>{s.serialNumber ?? "-"}</td>
                  <td>
                    {s?.attachments?.length > 0 ? (
                      <AttachmentsModal serial={s} />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {s?.usedInPurchase ? (
                      <div className="flexColumn mdFont purpleText lh1">
                        <span>{t("USED_SERIAL")}</span>
                        <Link
                          to={`/purchases/${s.usedInPurchase}/show`}
                          className="textUnderline"
                        >
                          {t("SEE_PURCHASE")}
                        </Link>
                      </div>
                    ) : (
                      <>
                        <SerialModal
                          serial={s}
                          onDone={() => mutate()}
                          productId={id!}
                        />
                        <SerialDelete serial={s} onDone={() => mutate()} />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      )}
    </>
  );
};
