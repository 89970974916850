import { Spinner } from "@/components/shared/Spinner/Spinner";
import { getOfferById } from "@/http";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";
import { FormOffer } from "../_form/FormOffer";

export const DetailOffer = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const { data, mutate, isValidating } = useSWR(
    [id, "offerById"],
    getOfferById
  );

  useEffect(() => {
    mutate();
  }, [id]);

  if (isValidating) {
    return <Spinner />;
  }

  if (!data) {
    return <h6 className="bold">{t("ERROR_HAPPEN")}</h6>;
  }

  return (
    <>
      <FormOffer
        offer={data}
        onDone={() => {
          mutate();
        }}
      />
    </>
  );
};
