import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TSingleMenu = {
  label: ReactNode;
  options: { value: any; label: any }[];
  onSelect: (value: any) => void;
  hideOnSelect?: boolean;
  align?: "left" | "rigth";
  position?: "top" | "bottom";
  width?: number | string;
  className?: string;
};
export const SingleMenu = ({
  label,
  options,
  onSelect,
  position,
  hideOnSelect,
  align,
  width,
  className = "",
}: TSingleMenu) => {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.containerOptionsSugestion} ${className}`}
        ref={optionsRef}
      >
        <div
          className="pointer flexGap center"
          onClick={() => {
            setShowOptions(!showOptions);
          }}
        >
          {label}
        </div>
        {showOptions && (
          <div>
            <ul
              className={`${position === "top" ? styles.top : ""} ${
                align === "rigth" ? styles.rigth : ""
              }`}
              style={{ minWidth: width }}
            >
              {options.map((option, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    onSelect(option.value);
                    if (hideOnSelect) {
                      setShowOptions(false);
                    }
                  }}
                >
                  <div className={styles.description}>{option.label}</div>
                </li>
              ))}
              {!options?.length && <li>{t("NO_AVAILABLE_OPTIONS")}</li>}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
