import { TextArea } from "@/components/shared/Input/TextArea";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { addPurchaseComment, TSubPurchaseComment } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { validateTimezoneDate } from "@/utils/date";
import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styles from "./styles.module.scss";

export const PurchaseComments = () => {
  const [t] = useTranslation("global");
  const { getPurchase, purchase } = usePurchase();
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { user, isCommercial, commercial } = useAuth();
  const methods = useForm();

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await addPurchaseComment(purchase.id, {
        createdBy: isCommercial ? commercial?.id ?? "" : user.id,
        comment: values.comment,
        fullName: isCommercial
          ? commercial?.firstName + " " + commercial?.lastName
          : user.name,
      });
      setShowForm(false);
      methods.reset();
      getPurchase(purchase.id);
      showToastSuccess(t("COMMENT_ADDED"));
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <p className="bold contentInline">
        <span>{t("COMMENTS")}</span>
        {!showForm && (
          <span
            className="pointer purpleText mdFont"
            onClick={() => setShowForm(true)}
          >
            {"+ "}
            {t("ADD_COMMENT")}
          </span>
        )}
      </p>
      {showForm && (
        <>
          <FormProvider {...methods}>
            <TextArea
              name="comment"
              label={t("ADD_COMMENT")}
              rows={3}
              required
            />
          </FormProvider>
          <div className="rigthAlign">
            <button
              className="sm"
              disabled={isLoading}
              onClick={() => {
                setShowForm(false);
                methods.reset();
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary sm"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </div>
          <div className="spacer" />
        </>
      )}
      {purchase?.comments?.length > 0 ? (
        <>
          <ul className={styles.commentsList}>
            {purchase?.comments?.map((c: TSubPurchaseComment, i: number) => {
              return (
                <React.Fragment key={i}>
                  {(i === 0 || (i !== 0 && showAll)) && (
                    <li key={i} className="flexColumn">
                      <span>
                        {(c?.message || "-").split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index <
                              (c?.message || "-").split("\n").length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                      </span>
                      <span className="smFont bold">
                        {c.fullName}
                        {" - "}
                        {format(
                          new Date(validateTimezoneDate(c?.createdAt)),
                          isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                        )}
                      </span>
                    </li>
                  )}
                  {purchase?.comments?.length > 1 && i === 0 && (
                    <li className="rigthAlign">
                      {showAll ? (
                        <span
                          className="pointer"
                          onClick={() => setShowAll(!showAll)}
                        >
                          <span className="mdFont">{t("HIDE") + " "}</span>
                          <FaChevronUp size={"0.6rem"} />
                        </span>
                      ) : (
                        <span
                          className="pointer"
                          onClick={() => setShowAll(!showAll)}
                        >
                          <span className="mdFont">{t("SHOW_MORE") + " "}</span>
                          <FaChevronDown size={"0.6rem"} />
                        </span>
                      )}
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </>
      ) : (
        <p>{t("NO_COMMENTS")}</p>
      )}
    </>
  );
};
