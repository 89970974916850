import { TInvoice } from "../invoiceData";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./posStyles";

type Props = {
  invoice: TInvoice;
  translate: any;
};
export const PurchaseInfo = ({ translate, invoice }: Props) => {
  const showCommercialName =
    localStorage.getItem("showCommercialOnInvoice") === "true";
  const client = invoice.client;

  return (
    <View style={styles.section}>
      {invoice.createdAt && (
        <Text style={styles.infoRow}>
          <Text style={styles.bold}>
            {translate("DATE")}
            {": "}
          </Text>
          {invoice.createdAt.toLocaleDateString()}
        </Text>
      )}
      {invoice.commercialName && showCommercialName && (
        <Text style={styles.infoRow}>
          <Text style={styles.bold}>
            {translate("COMMERCIAL")}
            {": "}
          </Text>
          {invoice.commercialName}
        </Text>
      )}

      <Text style={styles.infoRow}>
        <Text style={styles.bold}>
          {translate("CLIENT")}
          {": "}
        </Text>
        {client?.legalName && (
          <Text>
            {client.legalName}
            {client?.firstName || client?.lastName ? " - " : ""}
          </Text>
        )}
        <Text>
          {client?.firstName ?? ""} {client?.lastName ?? ""}
        </Text>
      </Text>
      {client?.documentId && (
        <Text style={styles.infoRow}>
          <Text style={styles.bold}>
            {client.documentType}
            {": "}
          </Text>
          {client.documentId}
        </Text>
      )}

      <Text style={styles.infoRow}>
        <Text style={styles.bold}>
          {translate("PHONE")}
          {": "}
        </Text>
        {client?.phonePrefix ? `(${client?.phonePrefix}) ` : ""}{" "}
        {client?.phone ?? ""}
      </Text>

      <View style={styles.dividerDouble} />
    </View>
  );
};
