export const ADMIN_ROLE = "Super Admin";
export type TSupplierCommercial = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: string;
  supplierId: string;
  referralCode?: string;
  warehouseId?: string;
};

export type TSupplierPermission = {
  id?: string;
  name: string;
  description: string;
  key: string;
};

export type TSupplierRole = {
  id?: string;
  name: string;
  description: string;
  key: string;
  supplierId: string;
  permissions: string[];
};
