import { TInvoice } from "../invoiceData";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./posStyles";
import { formatCurrency } from "@/utils/currency";
import { isUSA } from "@/constants/core";

type Props = {
  invoice: TInvoice;
  translate: any;
};
export const TotalsInfo = ({ translate, invoice }: Props) => {
  return (
    <>
      <View style={styles.divider} />

      <View style={styles.contentLine}>
        <Text>{translate("TOTAL_TO_PAY")}: </Text>
        <Text>{formatCurrency(invoice?.total || 0)}</Text>
      </View>

      <View style={styles.divider} />

      <View style={styles.section}>
        <Text style={styles.bold}>{translate("TAXES_RESUME")}</Text>
        <View style={styles.contentLine}>
          <Text>{isUSA ? translate("TAX") : "IVA"}</Text>
          <Text>{formatCurrency(invoice?.totalTaxes)}</Text>
        </View>
      </View>

      <View style={styles.divider} />
      {invoice?.payments?.length > 0 && (
        <>
          <View style={styles.section}>
            <Text style={styles.bold}>{translate("PAYMENT_METHOD")}</Text>
            {invoice.payments.map((p: any, idx) => (
              <View style={styles.contentLine} key={idx}>
                <Text>
                  {translate("PAYMENT." + p.paymentMethod)}
                  {p.paymentProvider ? " - " + p.paymentProvider : ""}
                </Text>
                <Text>{formatCurrency(p?.amount || 0)}</Text>
              </View>
            ))}
          </View>

          <View style={styles.divider} />
        </>
      )}
    </>
  );
};
