import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import {
  TListedTicket,
  TRefundType,
  TTicketDetail,
  TTicketForm,
  TTicketProduct,
  TTicketReason,
  TTicketResponse,
  TTicketType,
} from "../../types/ticket";

const BASE_PATH = "/api/tickets";

export const getTickets = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TListedTicket>>(BASE_PATH, {
      params,
    })
    .then(res => res.data);
};

export const getTicketsMovements = async (
  ticketId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<any>>(`${BASE_PATH}/${ticketId}/movements`, {
      params,
    })
    .then(res => res.data);
};

export const getTicketsComments = async (
  ticketId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<any>>(`${BASE_PATH}/${ticketId}/comments`, {
      params,
    })
    .then(res => res.data);
};

export const getTicketsBySupplierId = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TListedTicket>>(BASE_PATH, {
      params,
    })
    .then(res => res.data);
};

export const getTicketsTypes = async () => {
  return getInstance()
    .get<TTicketType[]>(`${BASE_PATH}/ticketTypes`)
    .then(res => res.data);
};

export const createTicket = async (data: any) => {
  return getInstance()
    .post<any>(`${BASE_PATH}`, data)
    .then(res => res.data);
};

export const getTicketById = async (ticketId: string) => {
  return getInstance()
    .get<TTicketDetail>(`${BASE_PATH}/${ticketId}`)
    .then(res => res.data);
};

export const changeTicketResponsible = async (
  ticketId: string,
  commercialId: string
) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${ticketId}/commercial/${commercialId}`)
    .then(res => res.data);
};

export const closeTicket = async (ticketId: string, data: any) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${ticketId}/close`, data)
    .then(res => res.data);
};

export const reopenTicket = async (ticketId: string) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${ticketId}/open`)
    .then(res => res.data);
};

export const addTicketComment = async (ticketId: string, data: any) => {
  return getInstance()
    .post<any>(`${BASE_PATH}/${ticketId}/comments`, data)
    .then(res => res.data);
};

export const getProductsBySubpurchaseId = async (subpurchaseId: string) => {
  return getInstance()
    .get<TTicketProduct[]>(
      `${BASE_PATH}/available-products-quantities/${subpurchaseId}`
    )
    .then(res => res.data);
};

export const answerTicket = async (ticketId: string, data: TTicketResponse) => {
  return getInstance()
    .put<TTicketDetail>(`${BASE_PATH}/${ticketId}`, data)
    .then(res => res.data);
};

export const updateTicket = async (
  ticketId: string,
  data: Partial<TTicketDetail>
) => {
  return getInstance()
    .put<TTicketDetail>(`${BASE_PATH}/${ticketId}`, data)
    .then(res => res.data);
};

export const getTicketReasons = async (purchaseId: string) => {
  return getInstance()
    .get<TTicketReason[]>(`${BASE_PATH}/ticket-reasons/${purchaseId}`)
    .then(res => res.data);
};

export const getTicketFormByReason = async (
  reasonCode: string,
  purchaseId: string
) => {
  return getInstance()
    .get<TTicketForm>(
      `${BASE_PATH}/info-creation-by-reason/${reasonCode}/${purchaseId}`
    )
    .then(res => res.data);
};

export const getRefundTypes = (purchaseId: string) => {
  return getInstance()
    .get<TRefundType[]>(`${BASE_PATH}/refund-types/${purchaseId}`)
    .then(res => res.data);
};
